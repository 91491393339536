import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Hidden, withWidth, List, ListItem, ListItemText, SwipeableDrawer, Avatar } from "@material-ui/core";
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '../../../mui_modules/components/Button';
import IconButton from '../../../mui_modules/components/IconButton';

import alokitLogo from "../../../resources/images/alokit-logo-name.png";
import { removeAllSavedCookies } from '../../../util/firebaseAuth';

const styles = (theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1
	},
	logo: {
		height: '48px',
		width: '64px',
	},
	avatar: {
		flexShrink: 0,
		flexGrow: 0,
		height: '32px',
		width: '32px',
	},
	menuButton: {
		display: 'block',
		position: 'absolute',
		right: 10
	},
	offset: theme.mixins.toolbar,
	drawer: {
		flexShrink: 0,
	},
	drawerPaper: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.primary.main,
	},
	list: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(8),
		overflow: 'auto',
	},
	logoButton: {
		paddingRight: '30px'
	},
})

function LearnAppBar(props) {
	const { classes, pageName } = props;
	const user = JSON.parse(localStorage.getItem('UserDetails'));
	const userId = localStorage.getItem('UserId');
	const analyticsObj = {
		source: "LearnAppBar",
		page: pageName,
	};

	const [anchorEl, setAnchorEl] = React.useState(false);

	const toggleDrawer = (nav, open) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setAnchorEl(open);
		if (nav !== "." && (nav && nav.trim().length !== 0)) {
			// props.history.push(nav);
			window.location.href = nav;
		}
	};


	const logoutHandler = (event) => {
		removeAllSavedCookies(true);
		props.history.push('/login');
	};

	const menuOptions = [
		["Profile", "/learn/users/" + userId],
		["Program", "/program"],
		["Transactions", "/learn/users/" + userId + "/transactions"]
	]

	return (
		<div>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<IconButton href="/learn" color="inherit" analyticsObj={analyticsObj} className={classes.logoButton} >
						<img src={alokitLogo} alt="Alokit Innovations Private Limited" width="auto" height="50" />
					</IconButton>
					<Hidden smDown>
						{user.Roles.map((role) => (
							<Button
								color="inherit"
								href={`/learn/${role.toLowerCase()}`}
								key={role}
								analyticsObj={analyticsObj}
							>
								{role} View
							</Button>
						))}
					</Hidden>
					<IconButton
						variant="contained"
						color="secondary"
						aria-label="menu"
						aria-controls="simple-menu"
						aria-haspopup="true"
						className={classes.menuButton}
						onClick={toggleDrawer(".", true)}
						analyticsObj={analyticsObj}
					>
						<Avatar src={user.PhotoURL} className={classes.avatar} />
					</IconButton>
					<SwipeableDrawer
						anchor='right'
						open={anchorEl}
						onOpen={toggleDrawer(".", true)}
						onClose={toggleDrawer(".", false)}
						className={classes.drawer}
						classes={{
							paper: classes.drawerPaper,
						}}
					>
						<div
							className={classes.list}
							role="presentation"
						>
							<List>
								<Hidden mdUp>
									{user.Roles.map((role) => (
										<ListItem button
											color="inherit"
											key={role}
											onClick={toggleDrawer(`/learn/${role.toLowerCase()}`, false)}
										>
											<ListItemText primary={role + " View"} />
										</ListItem>
									))}
								</Hidden>
								{menuOptions.map((option) => (
									<ListItem button
										key={option[0]}
										color="inherit"
										onClick={toggleDrawer(option[1], false)}
									>
										<ListItemText primary={option[0]} />
									</ListItem>
								))}
								<ListItem button
									color="inherit"
									onClick={logoutHandler}
								>
									<ListItemText primary="Logout" />
								</ListItem>
							</List>
						</div>
					</SwipeableDrawer>
				</Toolbar>
			</AppBar>
			<div className={classes.offset} />
		</div>
	);
}

LearnAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withWidth()(LearnAppBar));
