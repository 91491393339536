import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Typography, CircularProgress } from '@material-ui/core';

const useStylesTimeProgress = makeStyles((theme) =>
	createStyles({
		root: {
			position: 'relative',
		},
		overall: {
			color: "#4caf50",
		},
		completed: {
			color: '#f44336',
			position: 'absolute',
			left: 0,
		},
		box: {
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			position: "absolute",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			width: "100%",
		}
	}),
);

const calcTimeProgress = (epic) => {
	const currentTimestamp = Math.floor(((new Date()).getTime()) / 1000);
	const launchDateTimestamp = epic.LaunchDate._seconds;
	const hoursPassed = Math.floor((currentTimestamp - launchDateTimestamp) / 3600);
	const totalHours = (epic.DurationInWeeks) * 7 * 24;
	let progressValue = 0;
	if (totalHours > 0) {
		progressValue = Math.floor((hoursPassed * 100) / totalHours);
	}
	if (progressValue > 100) {
		progressValue = 100;
	}
	let timeLeft = 0;
	let type;
	if ((totalHours - hoursPassed) < (24 * 7)) {
		timeLeft = Math.round((totalHours - hoursPassed) / 24);
		type = "d";
		// type = (timeLeft>1)?"day":"days";
	}
	else {
		timeLeft = Math.round((totalHours - hoursPassed) / (24 * 7));
		type = "W";
		// type = (timeLeft>1)?"week":"weeks";
	}
	return { progressValue: progressValue, type: type, timeLeft: timeLeft };
}

function TimeProgress(props) {
	const classes = useStylesTimeProgress();
	const { timeProgressList, ...otherProps } = props;

	return (
		<div className={classes.root}>
			<CircularProgress
				variant="determinate"
				className={classes.overall}
				thickness={props.thickness}
				size={props.size}
				value={100}
				{...otherProps}
			/>
			<CircularProgress
				variant="determinate"
				className={classes.completed}
				thickness={props.thickness}
				size={props.size}
				value={timeProgressList.progressValue}
				{...otherProps}
			/>
			{(timeProgressList.timeLeft <= 0) ? (
				<Box className={classes.box} >
					<Typography variant="body2" align="center">Times Up</Typography>
				</Box>
			) : (
				<Box className={classes.box} >
					<Typography variant={(props.width === "xs" || props.width === "sm") ? "h6" : "h5"} color="textSecondary" align="center">
						{timeProgressList.timeLeft}{timeProgressList.type}
					</Typography>

					<Typography variant="body2" color="textSecondary" align="center">
						Left
					</Typography>
				</Box>
			)}
		</div>
	);
}

function TimeProgressBar(props) {
	const timeProgressList = calcTimeProgress(props.epic);
	return (
		<TimeProgress timeProgressList={timeProgressList} size={props.size} thickness={props.thickness} width={props.width} />
	)
}

export default TimeProgressBar;