import React from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, Typography, Grid } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ReceiptViewer from '../receiptViewer';
import Button from '../../../mui_modules/components/Button';

const styles = (theme) => ({
    dialogStyle: {
        [theme.breakpoints.up('xs')]: {
            maxWidth: '80%'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40%'
        }
    },
    alignments: {
        [theme.breakpoints.down('xs')]: {
            align: "left"
        },
        [theme.breakpoints.up('sm')]: {
            align: "right"
        }
    },
    menuButton: {
        alignItems: 'flex-end',
        marginBottom: theme.spacing(1),
    },
    title: {
        marginTop: theme.spacing(2),
    },
    gridItem: {
        marginTop: theme.spacing(1),
    }
})

class ViewTransactionDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            viewReceipt: false,
        };
    }

    handleReceipt = (state) => {
        this.setState({ viewReceipt: state });
    }

    render() {
        const { classes, transaction } = this.props;
        const dateUtil = (new Date(this.props.transaction.CreatedAt));
        const transactionDetailsForReceipt = {
            txTime: transaction.TransactionTime,
            referenceId: transaction.ReferenceNo,
            orderAmount: transaction.Value.Amount,
            orderCurrency: transaction.Value.Currency,
        }
        return (
            <Dialog
                onClose={this.props.onClose}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                fullWidth
                classes={{ paperFullWidth: classes.dialogStyle }}
            >
                <DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
                    <Grid container>
                        <Grid item xs={8} className={classes.title}>
                            <Typography variant='h5'>
                                Transaction Details
                            </Typography>
                        </Grid>
                        <Grid item xs={4} align='right'>
                            <IconButton onClick={this.props.onClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container >
                        <Grid container className={classes.menuButton} >
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography> Transaction Date:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography>
                                    {dateUtil.toDateString()}
                                    {",   " + (dateUtil.toLocaleTimeString())}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography> Payment Amount:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography className={classes.alignments}>{transaction.Value.Currency} {transaction.Value.Amount}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography> Transaction Type:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography> {transaction.Type}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography> Payment Mode:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography> {transaction.Method}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography> Transaction ID:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography> {transaction.TransactionId}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography> Remarks:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography> {transaction.Message}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography> Project Name:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography className={classes.alignments}> {transaction.Details.Project.Name}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography> Epic Name:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Typography className={classes.alignments}> {transaction.Details.Epic.Name}</Typography>
                            </Grid>
                            {(transaction.Status === "SUCCESS") ? (
                                <Grid container item sm={12} xs={6} justify="center" className={classes.gridItem}>
                                    <Button variant="contained" onClick={() => this.handleReceipt(true)} color="secondary" className={classes.receiptButton} analyticsObj={this.props.analyticsObj} >
                                        Get Receipt
                                    </Button>
                                </Grid>
                            ) : null}

                        </Grid>
                    </Grid>
                    <div>
                        <ReceiptViewer transaction={transactionDetailsForReceipt} userDetails={this.props.userDetails} epicName={transaction.Details.Epic.Name} open={this.state.viewReceipt} handleReceipt={this.handleReceipt} />
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}

export default withStyles(styles)(ViewTransactionDialog);