import React from 'react';
import { withStyles, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Typography from '../../../mui_modules/components/Typography'
import Link from '../../../mui_modules/components/Link'
import githubIcon from "../../../resources/images/github.png";

const styles = (theme) => ({
	contents: {
		flexGrow: 1,
		flexShrink: 0,
		padding: theme.spacing(3),
	},
	avatar: {
		height: 'auto',
		[theme.breakpoints.down('sm')]:{
			width: '100%',
		},
		[theme.breakpoints.up('md')]:{
			width: 260,
		},
		flexShrink: 0,
		flexGrow: 0,
		marginTop: 20
	},
	name: {
		marginTop: theme.spacing(2),
	},
	githubLink: {
		display: 'flex',
		justifyContent: 'center',
		'& > *': {
			margin: theme.spacing(0.5),
		},
		marginBottom: theme.spacing(2),
	},
	githubIcon: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	toolbar: theme.mixins.toolbar,
})

class UserProfileDrawer extends React.Component {
	render() {
		const { classes } = this.props;
		const analyticsObj = {
			source: "UserProfileDrawer",
			page: this.props.pageName,
		};
		return (
			<Paper className={ classes.contents } >
				<center>
					<Avatar src={this.props.profilePicture} className={classes.avatar} />
					<Typography variant="h5" component="h2" className={ classes.name }>{this.props.name}</Typography>
					<Link
						href= {"https://github.com/"+ this.props.githubId }
						className={ classes.githubLink }
						analyticsObj={ analyticsObj }
					>
						<Avatar src={githubIcon} className={ classes.githubIcon } />
						<Typography variant="body1" color="textSecondary">{this.props.githubId}</Typography>
					</Link>
				</center>
				<Typography paragraph variant="body1" align="justify" >
					{this.props.description}
				</Typography>
				<Divider />
				
				<Typography paragraph variant="caption" align="justify" >
					Member Since: { this.props.joinedAt.toDateString() }
				</Typography>
			</Paper>
		)
	}
}

export default withStyles(styles)(UserProfileDrawer);