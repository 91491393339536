import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import backgroundImage from '../../resources/images/mentee_bg_img.jpg'

const styles = (theme) => ({
	background: {
		backgroundImage: `url(${backgroundImage})`,
		backgroundColor: '#141415', // Average color of the background image.
		backgroundPosition: 'center',
	},
	button: {
		minWidth: 200,
	},
	h5: {
		marginBottom: theme.spacing(4),
		marginTop: theme.spacing(4),
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(10),
		},
	},
	more: {
		marginTop: theme.spacing(2),
	},
});

function ProductHero(props) {
	const { classes, pageName } = props;
	const analyticsObj = {
		source: "ProductHero",
		page: pageName,
	};

	return (
		<ProductHeroLayout backgroundClassName={classes.background} sectionType="landing">
			{/* Increase the network loading priority of the background image. */}
			<img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
			<Typography color="inherit" align="center" variant="h2" marked="center">
				Don't just hire<br />build a <b>Team</b>
			</Typography>
			<Typography color="inherit" align="center" variant="h5" className={classes.h5}>
				<b>Test-drive</b> your contributors to build <b>long-lasting teams</b>
			</Typography>
			<Button
				color="secondary"
				variant="contained"
				size="large"
				className={classes.button}
				component="a"
				href="/login"
				paddedButton={true}
				analyticsObj={analyticsObj}
			>
				Register now
			</Button>
			<Typography variant="body2" color="inherit" className={classes.more}>
				Your <b>final step</b> for hiring <b>software developers</b>
			</Typography>
		</ProductHeroLayout >
	);
}

ProductHero.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);