import React from 'react';
import { PDFViewer } from "@react-pdf/renderer";
import { Dialog, IconButton, withStyles } from '@material-ui/core';
import Receipt from './views/receipt';
import CloseIcon from '@material-ui/icons/Close';

const styles = () => ({
	pdfViewer: {
		width: '100%',
		height: '100vh',
	},
	closeButton: {
		marginRight: 0,
		marginLeft: "auto",
	}
});

class ReceiptViewer extends React.Component {

	handleClose = () => {
		this.props.handleReceipt(false);
	}

	render(){
		const { classes, transaction, open, userDetails, epicName } = this.props;
		return(
			<Dialog fullScreen open={open}>
				<div className={classes.closeButton}>
					<IconButton color="inherit" onClick={this.handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</div>
				<PDFViewer className={classes.pdfViewer}>
					<Receipt transaction={transaction} userDetails={userDetails} epicName={epicName}/>
				</PDFViewer>
			</Dialog>
		);
	}
};

export default withStyles(styles)(ReceiptViewer);