import React from 'react';
import { Container, withStyles } from '@material-ui/core';
import Typography from "../components/Typography";
import Faq from "react-faq-component";

const styles = (theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.paper,
	},
	container: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(10),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
		},
		[theme.breakpoints.up('md')]: {
			width: theme.breakpoints.values.md,
		},
	},
	title: {
		marginBottom: theme.spacing(3),
	},
})

class FAQ extends React.Component {
	render() {
		const { classes } = this.props;

		const faqData = {
			title: "",
			rows: [
				{
					title: "What if I have shortlisted a few candidates for hiring?",
					content: "We encourage you to create an Audition Project on Alokit mentorship platform and add your candidates to the project.",
				},
				{
					title: "How should I decide the value of my Audition Project?",
					content: "We recommend taking into account the duration of the project and the average hourly income of the role you are hiring for.",
				},
				{
					title: "What happens if an auditioner leaves in the middle of an audition?",
					content: "We take a buy-in amount from each auditioner which they get back on the completion of the audition. If they leave, they lose the deposit.",
				},
				{
					title: "How long should an Audition Project last?",
					content: "We recommend an Audition Project lasting 2 - 6 weeks.",
				},
				{
					title: "What is the role of a mentor?",
					content: "Mentors are employees working for you who will be guiding the candidates. We recommend mentors to be a part of the team which is hiring, as they will be best suited to evaluate candidates, and candidates also get a sense of what it would be like to work with your team.",
				},
				{
					title: "How much time should I expect auditioners to put into the project?",
					content: "You can expect the candidates to devote part-time effort on the project (~15 hours a week) because they might have other commitments",
				},
				{
					title: "What kind of tasks should I select for the Audition Project?",
					content: "We recommend selecting tasks that will ultimately produce value for you. A good way to ensure that is to define a metric for value (e.g. total number of users) which improves when the project is completed. Ideally, the tasks should be scoped for clarity and they should be achievable by candidates (who would have limited knowledge of your tech stack) in the given timeframe.",
				},
				{
					title: "What happens if the project is not completed in the given duration?",
					content: "Auditioners will only be paid for the work they have completed. Rest of the value is returned to you.",
				},
			]
		}

		const styles = {
			bgColor: '#F6F8FAd0',
			// titleTextColor: "",
			rowTitleColor: "black",
			rowTitleTextSize: 'medium',
			rowContentColor: 'grey',
			rowContentTextSize: 'medium',
			rowContentPaddingTop: '10px',
			rowContentPaddingBottom: '10px',
			rowContentPaddingLeft: '50px',
			rowContentPaddingRight: '150px',
			arrowColor: "grey",
		};

		const config = {
			animate: true,
			// arrowIcon: "V",
		};

		return (
			<section className={classes.root}>
				<Container className={classes.container}>
					<Typography variant="h4" align="center" className={classes.title}>
						Frequently Asked Questions
					</Typography>
					<Faq data={faqData} styles={styles} config={config} />
				</Container>
			</section>
		)
	}
}

export default withStyles(styles)(FAQ);