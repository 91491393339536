import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import backgroundImage from '../../resources/images/contact_bg_img.jpg'
import { withStyles } from '@material-ui/core';
import { tradeMarkedAlokit } from "../../util/uiUtil";

const styles = (theme) => ({
	background: {
		backgroundImage: `url(${backgroundImage})`,
		backgroundColor: '#141415', // Average color of the background image.
		backgroundPosition: 'center',
		[theme.breakpoints.up('sm')]: {
			height: '40vh',
			minHeight: 100,
			maxHeight: 500,
		},
	},
	title: {
		marginBottom: theme.spacing(2)
	}
})

function MentorWelcomeHero(props) {
	const { classes } = props;
	return (
		<ProductHeroLayout backgroundClassName={classes.background} sectionType="section">
			<Typography color="inherit" align="center" variant="h2" marked="center" className={classes.title}>
				Become a Mentor
			</Typography>
			<Typography color="inherit" align="center" variant="h5" className={classes.h5}>
				Become a mentor with {tradeMarkedAlokit()} and see your projects bear fruit.
			</Typography>
		</ProductHeroLayout>
	)
}

MentorWelcomeHero.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MentorWelcomeHero);