import React from 'react';
import axios from 'axios';
import { authMiddleWare } from '../../../util/auth';

import { withStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import StoryList from './StoryList';
import BoardView from './BoardView';
import ViewStoryDialog from './ViewStoryDialog';
import AddStoryDialog from './AddStoryDialog';
import { EpicStates, isMentee, isMentor, isProjectOwner } from '../../../util/uiUtil';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Button, IconButton, AlertDialog } from '../../../mui_modules/components';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	addButton: {
		marginTop: theme.spacing(1),
	},
	deleteButton: {
		'&:hover': {
			backgroundColor: theme.palette.error.main,
		},
	},

})

const viewTypes = Object.freeze({
	LIST: "List",
	BOARD: "Board",
})

class StoryViews extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			anchorEl: null,
			viewOpen: false,
			viewStory: null,
			open: false,
			addStoryDialogProps: {
				open: false,
				buttonType: '',
				buttonDisabled: false,
				storyId: '',
				storyState: '',
				handleClose: (event) => {
					let addStoryDialogProps = this.state.addStoryDialogProps;
					addStoryDialogProps.open = false;
					addStoryDialogProps.buttonDisabled = false;
					this.setState({
						addStoryDialogProps: addStoryDialogProps
					});
				},
				handleSubmit: this.handleAddEditStorySubmit,
				pageName: this.props.pageName,
				errors: [],
				updateDialogProps: false,
				setUpdateDialogProps: (flag) => {
					let addStoryDialogProps = this.state.addStoryDialogProps;
					addStoryDialogProps.updateDialogProps = flag;
					this.setState({
						addStoryDialogProps: addStoryDialogProps
					});
				},
				allUsers: null,
			},
			deleteEpicDialogProps: {
				open: false,
				loading: false,
				handleClose: (event) => {
					let deleteEpicDialogProps = this.state.deleteEpicDialogProps;
					deleteEpicDialogProps.open = false;
					this.setState({
						deleteEpicDialogProps: deleteEpicDialogProps
					});
				},
				handleConfirm: () => {
					let deleteEpicDialogProps = this.state.deleteEpicDialogProps;
					deleteEpicDialogProps.loading = true;
					this.setState({
						deleteEpicDialogProps: deleteEpicDialogProps
					});
					authMiddleWare(this.props.history);
					const authToken = localStorage.getItem('AuthToken');
					axios.defaults.headers.common = { Authorization: `${authToken}` };
					axios
						.delete(this.props.api_url + `/projects/${this.props.epic.Project.Id}/epics/${this.props.epic.Id}`)
						.then(() => {
							this.props.history.goBack();
						})
						.catch((err) => {
							if (err.response != null && err.response.status === 403) {
								removeAllSavedCookies();
								this.props.history.push('/login');
							}
							let deleteEpicDialogProps = this.state.deleteEpicDialogProps;
							deleteEpicDialogProps.loading = false;
							deleteEpicDialogProps.errorMessage = err.response.data.error;
							this.setState({
								deleteEpicDialogProps: deleteEpicDialogProps
							});
						});
				},
			},
			uiLoading: true,
			allUsersLoading: true,
		};

		this.analyticsObj = {
			source: "ViewStories",
			page: this.props.pageName,
		};
	}

	handleAddStoryClick = (storyState) => (event) => {
		let addStoryDialogProps = this.state.addStoryDialogProps;
		addStoryDialogProps.open = true;
		addStoryDialogProps.buttonType = 'Add';
		addStoryDialogProps.updateDialogProps = true;
		addStoryDialogProps.storyState = storyState;
		this.setState({
			addStoryDialogProps: addStoryDialogProps
		});
	}

	handleViewOpen = (story) => {
		this.setState({
			viewOpen: true,
			viewStory: story,
		});
	}

	handleViewClose = () => {
		this.setState({ viewOpen: false });
	};

	deleteStoryHandler() {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.delete(this.props.api_url + `/projects/${this.props.epic.Project.Id}/epics/${this.props.epic.Id}/stories/${this.state.viewStory.StoryId}`)
			.then(() => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleEditClickOpen = () => {
		let addStoryDialogProps = this.state.addStoryDialogProps;
		addStoryDialogProps.open = true;
		addStoryDialogProps.buttonType = 'Edit';
		addStoryDialogProps.story = this.state.viewStory;
		addStoryDialogProps.storyId = this.state.viewStory.StoryId;
		addStoryDialogProps.updateDialogProps = true;
		this.setState({
			addStoryDialogProps: addStoryDialogProps,
		});
	}

	handleAddEditStorySubmit = (userStory) => {
		authMiddleWare(this.props.history);
		this.setState({
			addStoryDialogProps: { ...this.state.addStoryDialogProps, buttonDisabled: true }
		});
		let options = {};
		if (this.state.addStoryDialogProps.buttonType === 'Edit') {
			options = {
				url: this.props.api_url + `/projects/${this.props.epic.Project.Id}/epics/${this.props.epic.Id}/stories/${this.state.addStoryDialogProps.storyId}`,
				method: 'put',
				data: userStory
			};
		} else {
			options = {
				url: this.props.api_url + `/projects/${this.props.epic.Project.Id}/epics/${this.props.epic.Id}/stories`,
				method: 'post',
				data: userStory
			};
		}
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios(options)
			.then(() => {
				this.state.addStoryDialogProps.handleClose();
				window.location.reload();
			})
			.catch((error) => {
				let addStoryDialogProps = this.state.addStoryDialogProps;
				addStoryDialogProps.open = true;
				addStoryDialogProps.buttonDisabled = false;
				addStoryDialogProps.errors = error.response.data;
				console.error(error);
				this.setState({
					addStoryDialogProps: addStoryDialogProps
				});

				// handling the error
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login')
				}
				if (error.response != null && error.response.data != null && error.response.data.message != null) {
					for (let msg of error.response.data.message) {
						this.props.enqueueSnackbar(msg, { variant: "error" })
					}
				} else {
					this.props.enqueueSnackbar(`Failed to ${this.state.addStoryDialogProps.buttonType} the story`, { variant: "error" });
				}
			});
	}

	deleteEpicClick = (event) => {
		let deleteEpicDialogProps = this.state.deleteEpicDialogProps;
		deleteEpicDialogProps.open = true;
		deleteEpicDialogProps.errorMessage = undefined;
		this.setState({
			deleteEpicDialogProps: deleteEpicDialogProps
		});
	}

	handleMenuOpen = (story) => (event) => {
		this.setState({
			anchorEl: event.currentTarget,
			viewStory: story,
		});
	};

	handleMenuClose = () => {
		this.setState({ anchorEl: null });
	};

	isStoryDeletable = () => {
		if (this.state.viewStory !== null) {
			return (this.state.viewStory.State === 'Backlog' || this.state.viewStory.State === 'In Progress') ? true : false;
		} else {
			return false;
		}
	};

	handleCollapseOnClick = () => {
		this.setState({
			open: !this.state.open
		})
	};

	componentDidMount() {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get(this.props.api_url + `/users`)
			.then((response) => {
				console.log(response.data);
				let addStoryDialogProps = this.state.addStoryDialogProps;
				addStoryDialogProps.allUsers = response.data;
				addStoryDialogProps.updateDialogProps = true;
				this.setState({
					addStoryDialogProps: addStoryDialogProps,
					allUsersLoading: false
				});
			})
			.catch((error) => {
				console.error(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				this.setState({ errorMsg: 'Error in retrieving the data' });
			})

	};

	render() {
		const { classes, ...otherProps } = this.props;
		const { anchorEl } = this.state;
		return (
			<div>
				{(this.props.viewType === viewTypes.LIST) ?
					<StoryList
						open={this.state.open}
						allUsers={this.state.addStoryDialogProps.allUsers}
						handleAddEditStorySubmit={this.handleAddEditStorySubmit}
						handleViewOpen={this.handleViewOpen}
						handleViewClose={this.handleViewClose}
						handleEditClickOpen={this.handleEditClickOpen}
						deleteEpicClick={this.deleteEpicClick}
						handleMenuOpen={this.handleMenuOpen}
						handleMenuClose={this.handleMenuClose}
						handleCollapseOnClick={this.handleCollapseOnClick}
						{...otherProps}
					/>
					: (this.props.viewType === viewTypes.BOARD) ?
						<BoardView
							epic={this.props.epic}
							allUsersLoading={this.state.allUsersLoading}
							handleAddStoryClick={this.handleAddStoryClick}
							handleAddEditStorySubmit={this.handleAddEditStorySubmit}
							handleViewOpen={this.handleViewOpen}
							handleViewClose={this.handleViewClose}
							handleEditClickOpen={this.handleEditClickOpen}
							deleteEpicClick={this.deleteEpicClick}
							handleMenuOpen={this.handleMenuOpen}
							handleMenuClose={this.handleMenuClose}
							{...otherProps}
						/>
						: null}
				{(((isProjectOwner(this.props.epic.Project)) || (isMentor(this.props.epic))) && (this.props.viewType === viewTypes.LIST)) ?
					<span>
						<Button
							size="small"
							variant="text"
							color="inherit"
							className={classes.addButton}
							onClick={this.handleAddStoryClick()}
							analyticsObj={this.analyticsObj}
							disabled={this.state.allUsersLoading || this.props.epic.State === EpicStates.FINISHED}
							startIcon={<AddIcon />}
						>
							Add Story
						</Button>
					</span>
					: null}
				<AddStoryDialog key={this.state.addStoryDialogProps.storyId} epic={this.props.epic} pageName={this.props.pageName} {...this.state.addStoryDialogProps} />
				{(isProjectOwner(this.props.epic.Project)) ?
					<span style={{ float: "right" }}>
						<Tooltip title="Delete Epic" placement="bottom">
							<span>
								<IconButton
									color="primary"
									className={classes.deleteButton}
									onClick={this.deleteEpicClick}
									disabled={(this.stories != null) && (Object.getOwnPropertyNames(this.stories).length > 0)}
									analyticsObj={this.analyticsObj}
								>
									<DeleteIcon />
								</IconButton>
							</span>
						</Tooltip>
						<AlertDialog pageName={this.props.pageName} {...this.state.deleteEpicDialogProps}
							dialogTitle="Are you sure?"
							dialogContent="Once you delete this epic, you will not be able to recover it. All its history will be deleted as well."
							cancelText="Cancel"
							confirmText="Delete Epic"
						/>
					</span>
					: null}

				{(this.state.viewOpen) ?
					<ViewStoryDialog open={this.state.viewOpen} onClose={this.handleViewClose} {...this.state.viewStory} />
					: null}

				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					getContentAnchorEl={null}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					transformOrigin={{ vertical: "top", horizontal: "center" }}
					onClose={this.handleMenuClose}
				>
					{((isProjectOwner(this.props.epic.Project) || isMentor(this.props.epic)) && (this.isStoryDeletable())) ?
						<MenuItem onClick={() => this.deleteStoryHandler()} value="Delete Story">Delete Story</MenuItem>
						: null}
					{(isMentee(this.props.epic) || isMentor(this.props.epic) || isProjectOwner(this.props.epic.Project)) ?
						<MenuItem onClick={() => this.handleEditClickOpen()} value="Edit story">Edit Story</MenuItem>
						: null}
				</Menu>
			</div>
		)
	}

}

export default withStyles(styles)(withSnackbar(StoryViews));