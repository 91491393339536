import React from 'react';
import PropTypes from 'prop-types';
import { Container, withStyles, List, ListItem, ListItemText } from "@material-ui/core";
import Typography from '../components/Typography';

const style = (theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.default,
		overflow: 'hidden',
	},
	container: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(10),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
		},
		[theme.breakpoints.up('md')]: {
			width: theme.breakpoints.values.md,
		},
	},
	title: {
		marginBottom: theme.spacing(3),
	}
});

function ProductPhilosophy(props) {
	const { classes } = props;

	return (
		<section className={classes.root}>
			<Container className={classes.container}>
				<Typography variant="h4" marked="center" className={classes.title} component="h2">
					What we believe
				</Typography>
				<div>
					<List>
						<ListItem>
							<ListItemText
								primary="Working with candidates is the best way to evaluate them"
								secondary="There are no bad candidates, only a bad fit. Working with candidates on actual software
								development is the optimal way of judging if they are a good fit for your team."
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Developers should be developing"
								secondary="Spending countless hours reviewing dummy assignents and taking interviews distracts engineers
								from developing amazing software. Team building should not come at the cost of reducing velocity."
							/>
						</ListItem>
					</List>
				</div>
			</Container>
		</section>
	)
}

ProductPhilosophy.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ProductPhilosophy);