import React from 'react';
import { withStyles, AppBar, Toolbar, IconButton, Grid, TextField, Select, ListItemIcon, ListItemText, Icon, OutlinedInput, Hidden, ListItem } from '@material-ui/core';
import { isMentor, isProjectOwner, storyTypeToIcon } from '../../../util/uiUtil';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../../mui_modules/components/Button';
import Typography from '../../../mui_modules/components/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = ((theme) => ({
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(3),
		width: '100%',
	},
	iconSelectInput: {
		padding: "13.5px 0px",
		[theme.breakpoints.down('xs')]: {
			padding: "4px 0px",
		},
	},
	iconSelect: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: "10px",
			paddingLeft: "20px",
		},
	}
}))

class AddStoryDialog extends React.Component {
	constructor(props) {
		super(props);
		this.contributorsOptionsList = [];
		this.state = {
			Name: "",
			Description: "",
			Credits: "",
			State: "Backlog",
			Contributors: [],
			Type: "Feature",
		}

		this.transition = React.forwardRef(function Transition(props, ref) {
			return <Slide direction="up" ref={ref} {...props} />;
		});

		this.analyticsObj = {
			source: "AddStoryDialog",
			page: this.props.pageName,
		};
	}

	updateProps = () => {
		if (this.props.buttonType === 'Edit') {
			this.setState({
				Name: this.props.story.Name,
				Description: this.props.story.Description,
				Credits: this.props.story.Credits,
				State: this.props.story.State,
				Contributors: this.props.story.Contributors,
				Type: this.props.story.Type,
			});
		} else if (this.props.buttonType === 'Add') {
			this.setState({
				Name: "",
				Description: "",
				Credits: null,
				State: (this.props.storyState) ? this.props.storyState : "Backlog",
				Contributors: [],
				Type: "Feature",
			});
		}

		this.props.setUpdateDialogProps(false);
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleContributorListChange = (event, value, reason) => {
		this.setState({
			Contributors: value
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();
		const newStory = {
			Name: this.state.Name,
			Description: this.state.Description,
			Credits: parseInt(this.state.Credits),
			State: this.state.State,
			Contributors: this.state.Contributors,
			Type: this.state.Type,
		};
		console.debug(newStory);
		this.props.handleSubmit(newStory);
	};

	hideTypeAsScreenWidth = (storyType, classes) => {
		return (
			<div>
				<Hidden xsDown>
					<Icon className={classes.iconSelect}>
						{storyTypeToIcon(storyType)}
					</Icon>
				</Hidden>
				<Hidden smUp>
					<ListItem>
						<ListItemIcon>
							{storyTypeToIcon(storyType)}
						</ListItemIcon>
						<ListItemText primary={storyType} />
					</ListItem>
				</Hidden>
			</div>
		)
	}

	componentDidMount() {
		// TODO: updateDialogProps is not really required. Restructure the code to get rid of the extra complexity
		this.componentDidUpdate();
	}

	componentDidUpdate() {
		if (this.props.updateDialogProps) {
			this.updateProps();
		}
	}

	setContributorsOptionList = () => {
		const contributorsInStory = {};
		if (this.props.allUsers) {
			const contributors = this.props.epic.Mentees.Curr.concat(this.props.epic.Mentors);
			for (const data of contributors) {
				if (data.Id in contributorsInStory) {
					continue;
				}
				const contributor = this.props.allUsers.find(x => x.Id === data.Id);
				if (contributor) {
					contributorsInStory[contributor.Id] = contributor;
				}
			}
		}
		return Object.values(contributorsInStory);
	}

	render() {
		const { classes } = this.props;
		this.contributorsOptionsList = this.setContributorsOptionList();
		const storyTypes = ["Feature", "Enhancement", "Bug", "Learning"];
		return (
			<Dialog fullWidth open={this.props.open} onClose={this.props.handleClose} TransitionComponent={this.transition}>
				<AppBar position="relative" className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close">
							<CloseIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{this.props.buttonType === 'Edit' ? 'Edit Story' : 'Create a new Story'}
						</Typography>
					</Toolbar>
				</AppBar>

				<form className={classes.form} noValidate>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={2}>
							<Select
								label="Type"
								labelWidth={4}
								fullWidth
								required
								id="type"
								name="Type"
								value={this.state.Type}
								onChange={this.handleChange}
								renderValue={(storyType) => (
									this.hideTypeAsScreenWidth(storyType, classes)
								)}
								input={<OutlinedInput classes={{ input: classes.iconSelectInput }} />}

							>
								{storyTypes.map((storyType) => (
									<MenuItem key={storyType} value={storyType}>
										<ListItemIcon>
											{storyTypeToIcon(storyType)}
										</ListItemIcon>
										<ListItemText primary={storyType} />
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} sm={10}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="storyName"
								label="Story Name"
								name="Name"
								autoComplete="storyName"
								helperText={this.props.errors.Name}
								value={this.state.Name}
								error={this.props.errors.Name ? true : false}
								onChange={this.handleChange}
								disabled={!(isProjectOwner(this.props.epic.Project) || isMentor(this.props.epic))}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="storyDescription"
								label="Story Description"
								name="Description"
								autoComplete="storyDescription"
								multiline
								rows={3}
								rowsMax={10}
								helperText={this.props.errors.Description}
								error={this.props.errors.Description ? true : false}
								onChange={this.handleChange}
								value={this.state.Description}
								disabled={!(isProjectOwner(this.props.epic.Project) || isMentor(this.props.epic))}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="credits"
								label="Credits"
								name="Credits"
								type="number"
								autoComplete="credits"
								helperText={this.props.errors.Credits}
								value={this.state.Credits}
								error={this.props.errors.Credits ? true : false}
								onChange={this.handleChange}
								disabled={!(isProjectOwner(this.props.epic.Project) || isMentor(this.props.epic)) || (this.props.buttonType === 'Edit' && this.props.story.State === 'Done')}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Select
								label="State"
								labelWidth={12}
								fullWidth
								variant="outlined"
								required
								id="state"
								name="State"
								value={this.state.State}
								onChange={this.handleChange}
							>
								<MenuItem value={"Backlog"}>Backlog</MenuItem>
								<MenuItem value={"In Progress"}>In Progress</MenuItem>
								<MenuItem value={"In Review"}>In Review</MenuItem>
								<MenuItem value={"Done"}>Done</MenuItem>
							</Select>
						</Grid>
						{/* TODO: It might be better to implement this with ChipInput library */}
						<Autocomplete
							multiple
							fullWidth
							id="contributors"
							options={this.contributorsOptionsList}
							getOptionLabel={(option) => option.Name + " (" + option.GitHubId + ")"}
							value={this.state.Contributors}
							filterSelectedOptions
							onChange={this.handleContributorListChange}
							disabled={(this.props.buttonType === 'Edit' && this.props.story.State === 'Done')}
							getOptionSelected={(option, value) => {
								if (option.Id === value.Id) {
									return true;
								} else {
									return false;
								}
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label="Contributors"
									placeholder="John Doe"
								/>
							)}
						/>

						{/* TODO: Add Timeline field */}

						<Grid item xs={12}>
							<Button
								fullWidth
								color="primary"
								size="large"
								variant="contained"
								disabled={this.props.buttonDisabled}
								onClick={this.handleSubmit}
								analyticsObj={this.analyticsObj}
							>
								{this.props.buttonType === 'Edit' ? 'Save' : 'Submit'}
							</Button>
						</Grid>
					</Grid>
				</form>
			</Dialog>
		)
	}
}

export default withStyles(styles)(AddStoryDialog);