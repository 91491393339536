import React from 'react';
import { CssBaseline } from '@material-ui/core';
import LearnAppBar from './views/LearnAppBar';
import AppFooter from '../../mui_modules/views/AppFooter';
import MentorEpics from './views/MentorEpics';

class MentorView extends React.Component {
    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <LearnAppBar pageName="MentorView" history={ this.props.history } />
                <MentorEpics api_url={ this.props.api_url } history={ this.props.history } pageName="MentorView" />
                <AppFooter pageName="MentorView" />
            </React.Fragment>
        )
    }
}

export default MentorView;