import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '../components/Button';
import TextField from '@material-ui/core/TextField';
import Link from '../components/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '../components/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, Container, Divider, Paper, fade } from '@material-ui/core';

import axios from 'axios';

import { gitHubAuth, saveAuthToken, saveUserId } from "../../util/firebaseAuth";
import { tradeMarkedAlokit } from "../../util/uiUtil";
import githubIcon from "../../resources/images/github-white.png";
import vibinexVerticalLogo from "../../resources/images/vibinex-black-vertical.svg";

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: '60vh',
		padding: theme.spacing(4),
		backgroundColor: fade(theme.palette.background.default, 0.85),
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(8),
			marginBottom: theme.spacing(8),
		}
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	avatar: {
		margin: theme.spacing(1),
		height: '80px',
		width: '128px',
	},
	loginDivision: {
		marginTop: 'auto',
		marginBottom: 'auto',
		width: '100%',
	},
	gitHubLogin: {
		margin: theme.spacing(3, 0, 2),
	},
	motto: {
		whiteSpace: 'pre-wrap',
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	progess: {
		position: 'absolute'
	},
	divider: {
		marginTop: 'auto',
		marginBottom: 'auto',
	},
});

class LoginCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			errors: [],
			loading: false,
		};

		this.analyticsObj = {
			source: "LoginCard",
			page: this.props.pageName,
		};
	}

	static getDerivedStateFromProps(nextProps) {
		if (nextProps.UI) {
			if (nextProps.UI.errors) {
				return { errors: nextProps.UI.errors };
			}
			else return null;
		}
		else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.UI) {
			if (this.props.UI.errors) {
				//Perform some operation here
				this.setState({ errors: this.props.UI.errors });
				this.classMethod();
			}
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ loading: true });
		const anonymousId = localStorage.getItem('AnonymousId')
		const userData = {
			email: this.state.email,
			password: this.state.password,
			anonymousId: anonymousId
		};
		axios
			.post(this.props.api_url + '/login', userData)
			.then((response) => {
				if (response.data.token) {
					saveAuthToken(response.data.token);
					saveUserId(response.data.userId);
					this.setState({
						loading: false,
					});
					this.props.history.push('/learn');
				}
				else {
					alert("ERROR: Something went wrong");
					window.location.reload(false);
				}
			})
			.catch((error) => {
				console.error(error);
				this.setState({
					errors: (error.response) ? error.response.data :
						(error.message) ? { general: error.message } : { general: 'Something went wrong.' },
					loading: false
				});
			});
	};

	gitHubAuthCaller = () => {
		this.setState({ loading: true });
		gitHubAuth()
	}

	render() {
		const { classes } = this.props;
		const { errors, loading } = this.state;
		return (
			<Container component="main" maxWidth="xs">
				<Paper className={classes.root}>
					<img alt="Alokit Innovations Private Limited" className={classes.avatar} src={vibinexVerticalLogo} />
					<Typography variant='h5'>
						{tradeMarkedAlokit()}
					</Typography>

					<Typography variant="body1" align="center" className={classes.motto}>
						An audition platform for building amazing teams.
					</Typography>

					<div className={classes.loginDivision}>
						<Button
							startIcon={<Avatar src={githubIcon} />}
							fullWidth
							variant="contained"
							color="primary"
							size="medium"
							className={classes.gitHubLogin}
							onClick={this.gitHubAuthCaller}
							analyticsObj={this.analyticsObj}
							paddedButton={false}
							disabled={loading}
						>
							Sign in with Github
							{loading && <CircularProgress size={30} className={classes.progess} />}
						</Button>

						{(this.props.show_email_login) ?
							<div>
								<Grid container>
									<Grid item xs={5} className={classes.divider}>
										<Divider />
									</Grid>
									<Grid item xs={2}>
										<Typography variant="body1" align="center">
											or
										</Typography>
									</Grid>
									<Grid item xs={5} className={classes.divider}>
										<Divider />
									</Grid>
								</Grid>

								<form className={classes.form} noValidate>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										autoComplete="email"
										autoFocus
										helperText={errors.email}
										error={errors.email ? true : false}
										onChange={this.handleChange}
									/>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										name="password"
										label="Password"
										type="password"
										id="password"
										autoComplete="current-password"
										helperText={errors.password}
										error={errors.password ? true : false}
										onChange={this.handleChange}
									/>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										className={classes.submit}
										onClick={this.handleSubmit}
										analyticsObj={this.analyticsObj}
										paddedButton={false}
										disabled={loading || !this.state.email || !this.state.password}
									>
										Sign In
										{loading && <CircularProgress size={30} className={classes.progess} />}
									</Button>
									<Grid container>
										<Grid item>
											<Link href="signup" variant="body2" analyticsObj={this.analyticsObj}>
												{"Don't have an account? Sign Up"}
											</Link>
										</Grid>
									</Grid>
									{errors.general && (
										<Typography variant="body2" className={classes.customError}>
											{errors.general}
										</Typography>
									)}
								</form>
							</div> : null}
					</div>
				</Paper>
			</Container>
		);
	}
}

export default withStyles(styles)(LoginCard);