import React from 'react';
import dayjs from 'dayjs';

import { Accordion, AccordionDetails, AccordionSummary, Card, CardActions, CardContent, Grid, withStyles } from '@material-ui/core';
import { Button, EpicStateIndicator, Markdown, Typography } from '../../../mui_modules/components';
import { isMentee, isMentor, isProjectOwner, userChipRender } from '../../../util/uiUtil';
import ProfileMetrics from './ProfileMetrics';
import EpicTimelineDisplay from './EpicTimelineDisplay';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const styles = (theme) => ({
	epicNameGridItems: {
		display: 'flex',
		alignContent: 'center',
		flexDirection: 'row'
	},
	card: {
		marginLeft: 'auto',
		marginRight: 'auto',
		padding: 0,
		width: '100%',
	},
	pos: {
		marginBottom: 12
	},
	cardAction: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	accordionDetails: {
		padding: 24,
		paddingTop: 0,
	},
	gridMargin: {
		padding: 0
	},
})

class EpicCard extends React.Component {
	render() {
		const { classes, epic } = this.props;

		return (
			<Card variant="outlined" className={classes.card}>
				<CardContent>
					<Grid container >
						<Grid item xs={12} className={classes.epicNameGridItems}>
							<EpicStateIndicator
								epicState={epic.State}
								epicId={epic.EpicId}
								api_url={this.props.api_url}
								history={this.props.history}
							/>
							<Typography variant="h5" component="h2">
								{epic.Name}
							</Typography>
						</Grid>
						<Grid item xs={7} sm={5}>
							<Typography className={classes.pos} color="textSecondary">
								{(this.props.analyticsObj.page === "Home") ?
									epic.Project.Name :
									dayjs.unix(epic.CreatedAt._seconds).fromNow()
								}
							</Typography>
							<Typography variant="body2" component="p" align="justify" color="textSecondary">
								Duration: {epic.DurationInWeeks} Weeks
							</Typography>
							<Typography paragraph variant="body2" align="justify" color="textSecondary" >
								Capacity: {epic.Mentees.Min} {(epic.Mentees.Min !== epic.Mentees.Max) ? `- ${epic.Mentees.Max}` : null}
							</Typography>
							{(this.props.analyticsObj.page === "Home") ?
								(<CardActions className={classes.cardAction}>
									<Button size="small" variant="contained" color="secondary" href={`/learn/projects/${this.props.project.Id}/epics/${epic.EpicId}`} analyticsObj={this.props.analyticsObj}>
										{' '}
										View{' '}
									</Button>
								</CardActions>)
								: (<CardActions className={classes.cardAction}>
									{(isMentor(epic) || isMentee(epic) || isProjectOwner(this.props.project)) ?
										<Button size="small" variant="contained" color="secondary" href={`/learn/projects/${this.props.project.Id}/epics/${epic.EpicId}`} analyticsObj={this.props.analyticsObj}>
											{' '}
											View{' '}
										</Button>
										: null}
									{(isMentor(epic) || isProjectOwner(this.props.project)) ?
										<Button size="small" variant="outlined" color="secondary" onClick={() => this.props.handleEditClickOpen({ epic })} analyticsObj={this.props.analyticsObj}>
											Edit
										</Button>
										: null}
								</CardActions>)}

						</Grid>
						<Grid item xs={5} sm={7}>
							<ProfileMetrics pageName={"EpicList"}
								left={{
									displayType: "money",
									metricValue: epic.Value,
									metricColor: '#00dd11',
									text: "Epic Value",
								}}
								right={{
									displayType: "money",
									metricValue: epic.BuyIn,
									metricColor: '#dd0011',
									text: "Buy-In",
								}}
							/>
						</Grid>
					</Grid>
				</CardContent>

				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography variant="body1">Details</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.accordionDetails}>
						<Grid container spacing={2} className={classes.gridMargin}>
							<Grid item xs={12} sm={8}>
								<Grid container spacing={2}>
									<Typography variant="body1" component="p">
										Mentors:
									</Typography>
									<Grid item xs={12} container spacing={1}>
										{(epic.Mentors) ? epic.Mentors.map((mentor) => (
											<Grid item key={mentor.Id}>
												{userChipRender(mentor, this.props.analyticsObj)}
											</Grid>
										)) : null}
									</Grid>

									<Typography variant="body1" component="p">
										Mentees:
									</Typography>

									<Grid item xs={12} container spacing={1}>
										{(epic.Mentees) && (epic.Mentees.Curr) ? epic.Mentees.Curr.map((mentee) => (
											<Grid item key={mentee.Id}>
												{userChipRender(mentee, this.props.analyticsObj)}
											</Grid>
										)) : null}
									</Grid>

									<Typography variant="body1" component="p">
										Description:
									</Typography>
									<Grid item xs={12} container spacing={1}>
										<Markdown variant="body2">
											{`${epic.Description.substring(0, 300)}`}
										</Markdown>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={4}>
								<EpicTimelineDisplay epic={epic} projectId={this.props.project.Id} api_url={this.props.api_url} history={this.props.history} />
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</Card>
		)
	}
}

export default withStyles(styles)(EpicCard);