// Material UI components
import React, { Component } from 'react';
import withRoot from '../mui_modules/withRoot';
import { withStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import AppAppBar from '../mui_modules/views/AppAppBar';
import AppFooter from '../mui_modules/views/AppFooter';
import LoginHero from '../mui_modules/views/LoginHero';
import ProductValues from '../mui_modules/views/ProductValues';

import { CircularProgress, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { gAnalytics } from "../util/analytics";
import { gitHubAuthRedirect } from "../util/firebaseAuth";

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		top: '35%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
});

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			uiLoading: true,
			snackbar: false,
			errors: []
		};

		gAnalytics.logEvent('screen_view', {
			firebase_screen: 'login',
		});
	}

	componentDidMount = () => {
		gitHubAuthRedirect(this.props.api_url, this);
	}

	handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({ snackbar: false });
	}

	render() {
		const { classes, ...otherProps } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<div className={classes.root}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</div>
			);
		} else {
			return (
				<React.Fragment>
					<CssBaseline />
					<AppAppBar pageName={"Login"} applyButton="false" />
					<LoginHero
						{...otherProps}
						api_url={this.props.api_url}
						show_email_login={this.props.show_email_login}
						pageName={"Login"}
					/>
					<Snackbar open={this.state.snackbar} autoHideDuration={6000} onClose={this.handleSnackbarClose}>
						<Alert onClose={this.handleSnackbarClose} severity="error">
							{this.state.errors.general}
						</Alert>
					</Snackbar>
					<ProductValues pageName={"Login"} />
					<AppFooter pageName={"Login"} />
				</React.Fragment>
			)
		}
	}
}

export default withRoot(withStyles(styles)(Login));
