import React from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, Icon, Typography, TextField } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { storyChipRender, storyTypeToIcon } from "../../../util/uiUtil";
import { MarkdownTextFieldDisplay } from '../../../mui_modules/components';

const styles = (theme) => ({
	dialogStyle: {
		maxWidth: '60%',
		display: 'block',
	},
	dialogTitle: {
		display: "flex",
		alignItems: "center",
		'& > *': {
			margin: theme.spacing(1),
		},
	},
})

class ViewStoryDialog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			story: {
				Name: "",
				Description: "",
				Contributors: []
			}
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<Dialog
				onClose={this.props.onClose}
				aria-labelledby="customized-dialog-title"
				open={this.props.open}
				fullWidth
				classes={{ paperFullWidth: classes.dialogStyle }}
			>
				<DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
					<div className={classes.dialogTitle}>
						<Icon>
							{storyTypeToIcon(this.props.story.Type)}
						</Icon>
						<Typography variant="h6">
							{this.props.story.Name}
						</Typography>
					</div>
				</DialogTitle>
				<DialogContent dividers>

					<MarkdownTextFieldDisplay
						id='storyDescription'
						fullWidth
						label="Description"
						rows={3}
						rowsMax={25}
						value={this.props.story.Description}
					/>

					<TextField
						fullWidth
						id="credits"
						name="Credits"
						label="Credits"
						readOnly
						value={this.props.story.Credits}
						InputProps={{
							disableUnderline: true
						}}
					/>

					<TextField
						fullWidth
						id="state"
						name="State"
						label="State"
						readOnly
						value={this.props.story.State}
						InputProps={{
							disableUnderline: true
						}}
					/>

					<ChipInput
						fullWidth
						readOnly
						disableUnderline
						id="contributors"
						label='Contributors'
						name="Contributors"
						chipRenderer={storyChipRender}
						value={this.props.story.Contributors}
					/>
				</DialogContent>
			</Dialog>
		)
	}
}

export default withStyles(styles)(ViewStoryDialog);