import React from 'react';
import { Icon, Tooltip, withStyles } from '@material-ui/core';
import { EpicStates } from '../../util/uiUtil';
import Dot from './Dot';
import PropTypes from 'prop-types';
import { Help } from '@material-ui/icons';

const cyan = '#4dd0e1';
const yellow = '#fdd835';
const green = '#00dd11';
const red = '#dd0011';
const grey = '#9e9e9e';
const help = '#404040';

const styles = (theme) => ({
    innerDiv: {
        display: 'inline-block',
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        marginRight: theme.spacing(1),
        alignContent: 'center',
        verticalAlign: 'middle'
    },
    pendingDot: {
        backgroundColor: cyan, 
    },
    openDot: {
        backgroundColor: yellow,
    },
    ongoingDot: {
        backgroundColor: green,
    },
    finishedDot: {
        backgroundColor: grey,
    },
    dormantDot: {
        backgroundColor: red,
    },
    defaultDot: {
        color: help,
        fontSize: 18
    }
})

class EpicStateIndicator extends React.Component {
    constructor(props) {
        super(props);

        this.analyticsObj = {
            source: this.props.source,
            page: this.props.pageName,
        };
    }

    epicStates = (state) => {
        const { classes } = this.props;
        switch (state) {
            case EpicStates.PENDING:
                return (this.circleIconForEpicStates(classes.pendingDot, state));
            case EpicStates.OPEN:
                return (this.circleIconForEpicStates(classes.openDot, state));
            case EpicStates.ONGOING:
                return (this.circleIconForEpicStates(classes.ongoingDot, state));
            case EpicStates.FINISHED:
                return (this.circleIconForEpicStates(classes.finishedDot, state));
            case EpicStates.DORMANT:
                return (this.circleIconForEpicStates(classes.dormantDot, state));
            default: return (this.circleIconForEpicStates(classes.defaultDot))
        }
    }

    circleIconForEpicStates = (dotClasses, state) => {
        const { classes } = this.props;
        return (
            <Tooltip title={(state) ? state : "No Epic State"} arrow placement="bottom">
                <div className={classes.innerDiv}>
                    {(state) ? 
                        (<Dot className={dotClasses}/>)
                        :
                        (<Icon style={{lineHeight: 0.5}}>
                            <Help className={dotClasses}/>   
                        </Icon>)
                    }
                </div>
            </Tooltip>
        )
    }

    render() {
        const { epicState, className} = this.props;
        return (
            <div className={className} >
                {this.epicStates(epicState)}
            </div>
        )
    }
}

EpicStateIndicator.propTypes = {
	classes: PropTypes.object,
	epicState: PropTypes.string.isRequired,
};

export default withStyles(styles)(EpicStateIndicator);
