import React from 'react';
import PropTypes from 'prop-types';
import Typography from './Typography';
import { withStyles } from '@material-ui/core';
import { getCurrencySymbol } from '../../util/uiUtil';

const styles = (theme) => ({
	root: {
		padding: theme.spacing(1),
	},
})

function Metric(type, value, color) {
	switch (type) {
		case "money":
			return (
				<section style={{ color: color }}>
					<Typography variant="h2" color="inherit" align="center">
						{getCurrencySymbol(value.Currency) + value.Amount}
					</Typography>
				</section>
			)
		case "percent":
			return (
				<section style={{ color: color }}>
					<Typography variant="h2" color="inherit" align="center">
						{value}%
					</Typography>
				</section>
			)
		default:
			return (
				value
			)
	}
}

function MetricDisplay(props) {
	const { classes, displayType, metricValue, text, metricColor } = props;
	if (props.textColor === "") {
		props.textColor = "textSecondary";
	}
	return (
		<section className={classes.root}>
			{Metric(displayType, metricValue, metricColor)}
			<Typography variant="body1" color={props.textColor} align="center">
				{text}
			</Typography>
		</section>
	)
}

MetricDisplay.propTypes = {
	classes: PropTypes.object.isRequired,
	displayType: PropTypes.string.isRequired,
	metricValue: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.shape({
			Currency: PropTypes.string,
			Amount: PropTypes.number,
		})
	]).isRequired,
	text: PropTypes.string,
	metricColor: PropTypes.string,
	textColor: PropTypes.string,
};

export default withStyles(styles)(MetricDisplay);