import * as React from 'react';
import { Box, Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
	sliderTrack: {
		height: 3,
		borderRadius: 240,
	},
	rail: {
		height: 3,
		borderRadius: 240
	},
})

const marks = (maxVal, minVal, marksVal) => {
	let marksArray = [
		{
			value: (100 / maxVal) * 0,
			label: '0',
		},
		{
			value: (100 / maxVal) * minVal,
			label: minVal + '(min)',
		},
		{
			value: (100 / maxVal) * maxVal,
			label: maxVal +'(max)',
		},
	]

	if(marksVal === maxVal || marksVal === minVal){
		return marksArray;
	} else {
		marksArray.push({ value: (100 / maxVal) * marksVal, lable: marksVal });
		return marksArray;
	}
};

function valuetext(value) {
	return `${value}°C`;
}

function DiscreteSliderMarks(props) {
	const { classes, maxVal, progressVal, minVal, marksVal } = props;
  	const r = Math.floor(255 * (1 - progressVal / maxVal));
  	const g = Math.floor(255 * (progressVal / maxVal));
  	const barColor =
	'#' +
	r.toString(16).padStart(2, '0') +
	g.toString(16).padStart(2, '0') +
	'00';

	return (
		<Box sx={{ width: 300 }}>
		<Slider
			aria-label="Custom marks"
			defaultValue={(100 / maxVal) * progressVal}
			getAriaValueText={valuetext}
			valueLabelDisplay="auto"
			marks={marks(maxVal, minVal, marksVal)}
			disabled
			style={{ color: barColor }}
			classes={{ track: classes.sliderTrack, rail: classes.rail }}
		/>
		</Box>
	);
}

export default withStyles(styles)(DiscreteSliderMarks);