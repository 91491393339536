// import { firebaseConfig } from "./config";
import firebase from "firebase/app";
import "firebase/auth";
import axios from 'axios';

// firebase.initializeApp(firebaseConfig);

export const saveGitHubToken = (token) => {
	localStorage.setItem('GitHubToken', `Bearer ${token}`);
}

export const saveAuthToken = (token) => {
	localStorage.setItem('AuthToken', `Bearer ${token}`);
}

export const saveUserId = (userId) => {
	localStorage.setItem('UserId', userId);
}

export const saveUserDetails = (name, profilePicture, roles, email, phoneNumber) => {
	var userDetails = {
		Name: name,
		PhotoURL: profilePicture,
		Roles: roles,
		Email: email,
		PhoneNumber: phoneNumber
	};
	localStorage.setItem('UserDetails', JSON.stringify(userDetails));
}

export const removeAllSavedCookies = (intentional = false) => {
	localStorage.removeItem('AuthToken');
	localStorage.removeItem('GitHubToken');
	localStorage.removeItem('UserId');
	localStorage.removeItem('UserDetails');
	if (!intentional) {
		alert("Session expired! You have been logged out.");
	}
}

export const gitHubAuth = () => {
	var provider = new firebase.auth.GithubAuthProvider();
	// provider.addScope('repo');
	// provider.setCustomParameters({
	// 	'allow_signup': 'false'
	// });

	firebase
		.auth()
		.signInWithRedirect(provider);
}

export const gitHubAuthRedirect = (api_url, obj) => {
	firebase
		.auth()
		.getRedirectResult()
		.then((result) => {
			if (result.credential) {
				// This gives you a GitHub Access Token. You can use it to access the GitHub API.
				const token = result.credential.accessToken;
				saveGitHubToken(token);
			}
			else {
				console.log("[gitHubAuthRedirect] no credentials found");
				obj.setState({ uiLoading: false });
				return false;
			}
			console.log(result);

			// The signed-in user info.
			const user = result.user;
			const additionalUserInfo = result.additionalUserInfo;
			const anonymousId = localStorage.getItem('AnonymousId');
			const newUserData = {
				userId: user.uid,
				isNewUser: additionalUserInfo.isNewUser,
				name: additionalUserInfo.profile.name,
				email: user.email,
				imageUrl: user.photoURL,
				gitHubUsername: additionalUserInfo.username,
				bio: additionalUserInfo.profile.bio,
				phoneNumber: user.phoneNumber,
				anonymousId: anonymousId
			};
			saveUserId(user.uid);
			axios
				.post(api_url + '/signupGitHub', newUserData)
				.then((response) => {
					console.log(response.data);
					obj.setState({ uiLoading: false });
					obj.props.history.push('/learn');
				})
				.catch((error) => {
					console.error("[gitHubAuthRedirect] " + error);
					obj.setState({
						uiLoading: false,
						snackbar: true,
						errors: error.response.data,
					});
					return false;
				})
			return result.user.getIdToken();
		})
		.then((token) => {
			saveAuthToken(token);
		})
		.catch((error) => {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;
			// The firebase.auth.AuthCredential type that was used.
			var credential = error.credential;
			// ...
			console.error("[" + errorCode + "] " + errorMessage + " (credential: " + credential + ")");
			console.error(error);
			obj.setState({
				uiLoading: false,
				snackbar: true,
				errors: {
					"general": errorMessage
				},
			});
			return false;
		});
}
