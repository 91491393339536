import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './pages/login';
import Signup from './pages/signup'
import Home from './pages/learn/home';
import Landing from './pages/landing';
import Apply from './pages/apply';
import Terms from './pages/terms';
import Returns from './pages/returns';
import Privacy from './pages/privacy';
import About from './pages/about';
import Program from './pages/program';
import People from './pages/people';
import ContactUs from './pages/contact';
import MentorApply from './pages/mentorApply';
import { ThemeProvider as MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import theme from './mui_modules/theme';
import ProfilePage from './pages/learn/profile';
import ProjectPage from './pages/learn/project';
import TransactionPage from './pages/learn/transactions';
import EpicPage from './pages/learn/epic';
import MenteeView from './pages/learn/mentee';
import MentorView from './pages/learn/mentor';
import { SnackbarProvider } from 'notistack';
import TransactionSummary from './pages/learn/transactionSummary';
import AdminView from './pages/learn/admin';
import { v4 as uuidv4 } from 'uuid';

const App = () => {

	let api_url;			// The target URL for all backend calls. Empty for dev mode because we will use proxy
	let show_email_login;	// Boolean; we do not show email login for prod mode
	let anonymousId = uuidv4();
	localStorage.setItem('AnonymousId', anonymousId);

	if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
		// dev code
		api_url = "/api"; // uncomment this to access backend from proxy instead of public URL
		// api_url = "https://d200-2401-4900-1cb8-dd2d-34c2-d459-32e7-501b.ngrok.io/alokit-dev/us-central1/api"; // uncomment (and update the URL) to test payment gateway (after exposing backend on public URL)
		show_email_login = true;
	} else {
		// production code
		api_url = "https://api.alokit.in";
		show_email_login = false;
	}
	return (
		<MuiThemeProvider theme={responsiveFontSizes(theme)}>
			<SnackbarProvider maxSnack={3}>
				<Router>
					<div>
						<Switch>
							<Route strict path="/learn/admin" render={(props) => <AdminView {...props} api_url={api_url} />} />
							<Route strict path="/learn/mentor" render={(props) => <MentorView {...props} api_url={api_url} />} />
							<Route strict path="/learn/mentee" render={(props) => <MenteeView {...props} api_url={api_url} />} />
							<Route strict path="/learn/projects/:projectId/epics/:epicId" render={(props) => <EpicPage {...props} api_url={api_url} />} />
							<Route strict path="/learn/projects/:projectId" render={(props) => <ProjectPage {...props} api_url={api_url} />} />
							<Route strict path="/learn/users/:userId/projects/:projectId/epics/:epicId/transactions/:transactionId" render={(props) => <TransactionSummary {...props} api_url={api_url} />} />
							<Route strict path="/learn/users/:userId/transactions" render={(props) => <TransactionPage {...props} api_url={api_url} />} />
							<Route strict path="/learn/users/:userId" render={(props) => <ProfilePage {...props} api_url={api_url} />} />
							<Route strict path="/learn" render={(props) => <Home {...props} api_url={api_url} />} />
							<Route exact path="/login" render={(props) => <Login {...props} api_url={api_url} show_email_login={show_email_login} />} />
							{(show_email_login) ? <Route exact path="/signup" render={(props) => <Signup {...props} api_url={api_url} />} /> : null}
							<Route exact path="/apply" component={Apply} />
							<Route exact path="/mentorApply" component={MentorApply} />
							<Route exact path="/terms" component={Terms} />
							<Route exact path="/returns" component={Returns} />
							<Route exact path="/privacy" component={Privacy} />
							<Route exact path="/about" component={About} />
							<Route exact path="/program" component={Program} />
							<Route exact path="/people" component={People} />
							<Route exact path="/contactUs" render={(props) => <ContactUs {...props} api_url={api_url} />} />
							<Route path="/" component={Landing} />
						</Switch >
					</div >
				</Router >
			</SnackbarProvider >
		</MuiThemeProvider >
	);
}
export default App;