import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Hidden, withWidth, IconButton, List, ListItem, ListItemText, SwipeableDrawer, fade, Grid } from "@material-ui/core";
import withStyles from '@material-ui/core/styles/withStyles';
import MenuIcon from '@material-ui/icons/Menu';
import Countdown from 'react-countdown';
import Link from '../components/Link';
import Button from '../components/Button';
import alokitLogo from '../../resources/images/alokit-logo-name.png';

const styles = (theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1
	},
	loginButton: {
		display: 'block',
		textAlign: 'center',
		position: 'absolute',
		top: 14,
		right: 10
	},
	offset: theme.mixins.toolbar,
	drawer: {
		flexShrink: 0,
	},
	drawerPaper: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.primary.main,
	},
	list: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(8),
		overflow: 'auto',
	},
	notificationBar: {
		backgroundColor: fade('#f00', 0.7),
	},
	notificationText: {
		textAlign: 'left',
		paddingLeft: theme.spacing(1),
	},
	notificationCTA: {
		textAlign: 'right',
		paddingRight: theme.spacing(1),
		whiteSpace: 'pre-wrap',
	},
	logoButton: {
		paddingRight: '30px'
	},
})

function AppAppBar(props) {
	const { classes, pageName } = props;
	const analyticsObj = {
		source: "AppAppBar",
		page: pageName,
	};

	const applicationDeadline = new Date(2020, 6, 4, 23, 59, 59);
	const gracePeriodInDays = 2;

	const [anchorEl, setAnchorEl] = React.useState(false);

	const toggleDrawer = (nav, open) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setAnchorEl(open);
		if (nav !== "." && (nav && nav.trim().length !== 0)) {
			// props.history.push(nav);
			window.location.href = nav;
		}
	};

	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			return (
				<React.Fragment>
					The application deadline is over, but you might still be considered.
				</React.Fragment>
			);
		} else {
			// Render a countdown
			return (
				<React.Fragment>
					Application closes in {(days > 0) ? days + " days, " : null}
					{(hours > 0) ? hours + " hours, " : null}
					{(minutes > 0) ? minutes + " minutes and " : null}
					{seconds} seconds
				</React.Fragment>
			);
		}
	};

	const menuOptions = [
		["About Us", "/about"],
		["Contact Us", "/contactUs"],
	]

	return (
		<div>
			<AppBar position="fixed" className={classes.appBar}>
				{((Date.now() - applicationDeadline) / (1000 * 3600 * 24) < gracePeriodInDays) ?
					<AppBar position="static" className={classes.notificationBar}>
						<Grid container>
							<Grid item xs={9} className={classes.notificationText}>
								<Countdown
									// date={Date.now() + 5000}
									date={applicationDeadline}
									renderer={renderer}
								/>
							</Grid>
							{(props.loginButton !== "false") ? (
								<Grid item xs={3} className={classes.notificationCTA}>
									<Link href="/login" color="inherit" component="button" analyticsObj={analyticsObj} >Apply Now!!</Link>
								</Grid>
							) : null}
						</Grid>
					</AppBar>
					: null
				}
				<Toolbar>
					<Hidden mdUp>
						<IconButton
							edge="start"
							className={classes.menuButton}
							color="inherit"
							aria-label="menu"
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={toggleDrawer(".", true)}
						>
							<MenuIcon />
						</IconButton>
						<SwipeableDrawer
							anchor='left'
							open={anchorEl}
							onOpen={toggleDrawer(".", true)}
							onClose={toggleDrawer(".", false)}
							className={classes.drawer}
							classes={{
								paper: classes.drawerPaper,
							}}
						>
							<div
								className={classes.list}
								role="presentation"
							>
								<List>
									<ListItem button
										color="inherit"
										onClick={toggleDrawer("/", false)}
									>
										<ListItemText primary="Home" />
									</ListItem>
									{menuOptions.map((option) => (
										<ListItem button
											key={option[0]}
											color="inherit"
											onClick={toggleDrawer(option[1], false)}
										>
											<ListItemText primary={option[0]} />
										</ListItem>
									))}
								</List>
							</div>
						</SwipeableDrawer>
					</Hidden>

					<Button href="/" color="inherit" analyticsObj={analyticsObj} className={classes.logoButton}>
						<img src={alokitLogo} alt="Alokit Innovations Private Limited" width="auto" height="50" />
					</Button>
					<Hidden smDown>
						{menuOptions.map((option) => (
							<Button color="inherit" href={option[1]} key={option[0]} analyticsObj={analyticsObj} >{option[0]}</Button>
						))}
					</Hidden>
					{(props.loginButton !== "false") ? (
						<Hidden xsDown>
							<Button
								variant="contained"
								color="secondary"
								href="/login"
								className={classes.loginButton}
								analyticsObj={analyticsObj}
							>
								Login
							</Button>
						</Hidden>
					) : null}
				</Toolbar>
			</AppBar>
			<div className={classes.offset} />
		</div>
	);
}

AppAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withWidth()(AppAppBar));
