import React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { withStyles, CircularProgress } from '@material-ui/core';
import { authMiddleWare } from '../../../util/auth';
import AddEpicDialog from './AddEpicDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import { isProjectOwner } from '../../../util/uiUtil';

import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import { withSnackbar } from 'notistack';
import { Button, AlertDialog } from '../../../mui_modules/components';
import EpicCard from './EpicCard';

const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	root: {
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(4),
		},
		marginTop: theme.spacing(2),
		'& > *': {
			marginTop: theme.spacing(2),
		},
	},
	section: {
		paddingLeft: theme.spacing(4),
	},
	nested: {
		paddingLeft: theme.spacing(3),
	},
	deleteButton: {
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
})

class EpicList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			addEpicDialogProps: {
				open: false,
				buttonType: '',
				epicId: '',
				handleClose: (event) => {
					let addEpicDialogProps = this.state.addEpicDialogProps;
					addEpicDialogProps.open = false;
					this.setState({
						addEpicDialogProps: addEpicDialogProps
					});
				},
				handleSubmit: this.handleAddEditEpicSubmit,
				pageName: this.props.pageName,
				errors: [],
				updateDialogProps: false,
				setUpdateDialogProps: (flag) => {
					let addEpicDialogProps = this.state.addEpicDialogProps;
					addEpicDialogProps.updateDialogProps = flag;
					this.setState({
						addEpicDialogProps: addEpicDialogProps
					});
				},
			},
			deleteProjectDialogProps: {
				open: false,
				loading: false,
				handleClose: (event) => {
					let deleteProjectDialogProps = this.state.deleteProjectDialogProps;
					deleteProjectDialogProps.open = false;
					this.setState({
						deleteProjectDialogProps: deleteProjectDialogProps
					});
				},
				handleConfirm: () => {
					let deleteProjectDialogProps = this.state.deleteProjectDialogProps;
					deleteProjectDialogProps.loading = true;
					this.setState({
						deleteProjectDialogProps: deleteProjectDialogProps
					});
					authMiddleWare(this.props.history);
					const authToken = localStorage.getItem('AuthToken');
					axios.defaults.headers.common = { Authorization: `${authToken}` };
					axios
						.delete(this.props.api_url + `/project/${this.props.project.Id}`)
						.then(() => {
							this.props.history.goBack();
						})
						.catch((err) => {
							if (err.response != null && err.response.status === 403) {
								removeAllSavedCookies();
								this.props.history.push('/login');
							}
							let deleteProjectDialogProps = this.state.deleteProjectDialogProps;
							deleteProjectDialogProps.loading = false;
							deleteProjectDialogProps.errorMessage = err.response.data.error;
							this.setState({
								deleteProjectDialogProps: deleteProjectDialogProps
							});
						});
				},
			},
			uiLoading: true,
		}

		this.analyticsObj = {
			source: "EpicList",
			page: this.props.pageName,
		};
	}

	handleAddEpicClick = (event) => {
		let addEpicDialogProps = this.state.addEpicDialogProps;
		addEpicDialogProps.open = true;
		addEpicDialogProps.buttonType = 'Add';
		addEpicDialogProps.updateDialogProps = true;
		this.setState({
			addEpicDialogProps: addEpicDialogProps
		});
	}

	handleEditClickOpen = (data) => {
		let addEpicDialogProps = this.state.addEpicDialogProps;
		addEpicDialogProps.open = true;
		addEpicDialogProps.buttonType = 'Edit';
		addEpicDialogProps.epic = data.epic;
		addEpicDialogProps.epicId = data.epic.EpicId;
		addEpicDialogProps.updateDialogProps = true;
		this.setState({
			addEpicDialogProps: addEpicDialogProps,
		});
	}

	handleAddEditEpicSubmit = async (userEpic) => {
		authMiddleWare(this.props.history);
		let options = {};
		if (this.state.addEpicDialogProps.buttonType === 'Edit') {
			options = {
				url: this.props.api_url + `/projects/${this.props.project.Id}/epics/${this.state.addEpicDialogProps.epicId}`,
				method: 'put',
				data: userEpic
			};
		} else {
			options = {
				url: this.props.api_url + `/projects/${this.props.project.Id}/epics`,
				method: 'post',
				data: userEpic
			};
		}
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		return axios(options)
			.then(() => {
				this.state.addEpicDialogProps.handleClose();
				window.location.reload();
			})
			.catch((error) => {
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				if (error.response != null && error.response.data != null & error.response.data.message != null) {
					if (typeof error.response.data.message === 'string') {
						this.props.enqueueSnackbar(error.response.data.message, { variant: "error" })
					} else {
						for (let msg of error.response.data.message) {
							this.props.enqueueSnackbar(msg, { variant: "error" })
						}
					}
				}
				let addEpicDialogProps = this.state.addEpicDialogProps;
				addEpicDialogProps.open = true;
				addEpicDialogProps.errors = error.response.data;
				console.error(error);
				this.setState({
					addEpicDialogProps: addEpicDialogProps
				});
			});
	}

	deleteProjectClick = (event) => {
		let deleteProjectDialogProps = this.state.deleteProjectDialogProps;
		deleteProjectDialogProps.open = true;
		deleteProjectDialogProps.errorMessage = undefined;
		this.setState({
			deleteProjectDialogProps: deleteProjectDialogProps
		});
	}

	componentDidMount() {
		dayjs.extend(relativeTime);
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get(this.props.api_url + `/projects/${this.props.project.Id}/epics`)
			.then((response) => {
				console.log(response.data);
				this.epics = response.data;
				this.setState({ uiLoading: false });
			})
			.catch((error) => {
				console.log(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				if (error.response != null) {
					if (error.response.data != null & error.response.data.message != null) {
						for (let msg of error.response.data.message) {
							this.props.enqueueSnackbar(msg, { variant: "error" })
						}
					}
				} else {
					this.props.enqueueSnackbar('Error in retrieving the data', { variant: "error" });
				}
			});
	}

	render() {
		const { classes } = this.props;
		if (this.state.uiLoading) {
			return (
				<div className={classes.loadingRoot}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</div>
			)
		} else {
			return (
				<section className={classes.root}>
					{(this.epics) ? this.epics.map((epic) => (
						<EpicCard
							key={epic.EpicId}
							epic={epic}
							api_url={this.props.api_url}
							history={this.props.history}
							project={this.props.project}
							analyticsObj={this.analyticsObj}
							handleEditClickOpen={this.handleEditClickOpen}
						/>
					)) : null}

					{(isProjectOwner(this.props.project)) ? (
						<>
							<Button
								fullWidth
								variant="outlined"
								color="secondary"
								onClick={this.handleAddEpicClick}
								analyticsObj={this.analyticsObj}
							>
								Add Epic
							</Button>

							<Button
								fullWidth
								variant="contained"
								color="secondary"
								startIcon={<DeleteIcon />}
								onClick={this.deleteProjectClick}
								disabled={(this.epics != null) && (this.epics.length > 0)}
								className={classes.deleteButton}
								analyticsObj={this.analyticsObj}
							>
								Delete Project
							</Button>
							<AlertDialog pageName={this.props.pageName} {...this.state.deleteProjectDialogProps}
								dialogTitle="Are you sure?"
								dialogContent="Once you delete this project, you will not be able to recover it. All its history will be deleted as well."
								cancelText="Cancel"
								confirmText="Delete Project"
							/>
						</>)
						: null
					}
					<AddEpicDialog pageName={this.props.pageName} {...this.state.addEpicDialogProps} project={this.props.project} />
				</section>
			)
		}
	}
}

export default withStyles(styles)(withSnackbar(EpicList));