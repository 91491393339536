import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';

/**
 * Displays the difference as text
 * @param {Date} startTime The smaller of the two Date objects
 * @param {Date} endTime The larger of the two Date objects
 * @returns {string} The text that can be used to display the diff
 */
export const getTimeDifferenceText = (startTime, endTime) => {
	dayjs.extend(relativeTime);
	return dayjs(endTime).from(startTime, true)
}

export const getCalendarTextForTime = (eventTime) => {
	dayjs.extend(calendar);
	return dayjs(eventTime).calendar();
}