import React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Grid, Typography, withStyles } from '@material-ui/core';
import Button from '../../../mui_modules/components/Button';
import ReceiptViewer from '../receiptViewer';
import { getCurrencySymbol } from '../../../util/uiUtil';
import ImageBlackWithCredits from '../../../mui_modules/components/ImageBlackWithCredits';
import launchIcon from '../../../resources/images/startup_blue.png';

const styles = (theme) => ({
	textField: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	largeIcon: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(4),
		height: 250,
		width: 250,
	},
	successIcon: {
		color: "#4caf50",
	},
	grid: {
		marginBottom: theme.spacing(8),
	}

})

class PaymentInitiated extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			viewReceipt: false,
			userDetails: null,
			epicName: null,
			dataLoading: true,
		};
	}

	componentDidMount() {
		dayjs.extend(relativeTime);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		const userId = localStorage.getItem('UserId');
		Promise.all([
			axios.get(this.props.api_url + `/user/${userId}`),
			axios.get(this.props.api_url + `/users/${userId}/user_epics/${this.props.epicId}`)
		]).then(([response1, response2]) => {
			this.setState({
				userDetails: response1.data.userCredentials,
				epicName: response2.data.Epic.Name,
				dataLoading: false
			});
		}).catch((error) => {
			console.error(error);
			if (error.response != null && error.response.status === 403) {
				this.props.history.push('/login')
			}
			this.setState({ errorMsg: 'Error in retrieving the data' });
		});
	}

	handleReceipt = (state) => {
		this.setState({ viewReceipt: state });
	}

	render() {
		const { classes, transaction, analyticsObj, projectId, epicId } = this.props;
		const userId = localStorage.getItem('UserId');
		return (
			<div>
				<Grid container spacing={2} className={classes.grid}>
					<Grid container item xs={12} justify="center">
						{/* <SvgIcon className={classes.largeIcon}> */}
						<ImageBlackWithCredits
							icon={launchIcon}
							altText="launch"
							authorName="Freepik"
							authorLink="https://www.flaticon.com/free-icons/rocket"
							sourceName="Flaticon"
							sourceLink="https://www.flaticon.com/"
							className={classes.largeIcon}
						/>
						{/* </SvgIcon> */}
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h4" align="center">
							Transfer Initiated!
						</Typography>
						<Typography variant="h2" align="center" style={{ color: "#0a4796" }}>
							{getCurrencySymbol(transaction.orderCurrency)}{transaction.orderAmount.toString().replace(/\.00$/, '')}
						</Typography>
					</Grid>
					<Grid item xs={12} style={{ marginTop: 75 }}>
						<Typography variant="h5" align="center">
							Any changes in transfer status will be reflected on the Transactions page.
						</Typography>
					</Grid>
					<Grid item xs={12} style={{ marginTop: 75 }}>
						<Typography variant="h5" align="center">
							Cashfree transferId: {transaction.transferId} <br />
							{transaction.txTime.toLocaleString()}
						</Typography>
					</Grid>
					<Grid container item xs={6} justify="flex-end">
						<Button disabled={this.state.dataLoading} variant="contained" href={`/learn/users/${userId}/transactions`} color="secondary" analyticsObj={analyticsObj}>
							Go to Transactions
						</Button>
					</Grid>
					<Grid container item xs={6} justify="flex-start">
						<Button disabled={this.state.dataLoading} variant="contained" href={`/learn/projects/${projectId}/epics/${epicId}`} color="secondary" analyticsObj={analyticsObj}>
							Go to Epic
						</Button>
					</Grid>
				</Grid>
				<ReceiptViewer transaction={transaction} userDetails={this.state.userDetails} epicName={this.state.epicName} open={this.state.viewReceipt} handleReceipt={this.handleReceipt} />
			</div>
		);
	}
}

export default withStyles(styles)(PaymentInitiated);
