import { Avatar, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, withStyles } from "@material-ui/core";
import React from "react";
import IconButton from "../../../mui_modules/components/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import axios from "axios";

const styles = (theme) => ({
	rootList: {
		backgroundColor: theme.palette.background.paper,
	},
});

class UserRoles extends React.Component {
	constructor(props) {
		super(props)

		this.allRoles = ["Mentor", "Mentee", "Admin"];

		this.allUsersObj = {};
		for (var currentUser of this.props.all_users) {
			currentUser.editable = false;
			currentUser.processing = false;
			this.allUsersObj[currentUser.Id] = currentUser;
		}
		this.state = {
			users: this.allUsersObj,
		}

		this.analyticsObj = {
			source: "UserRoles",
			page: this.props.pageName,
		}
	}

	handleChange = (userId) => (event) => {
		var allUsersObj = this.state.users;
		allUsersObj[userId][event.target.name] = event.target.checked
		this.setState({ users: allUsersObj });
	}

	handleEditButton = (userId) => () => {
		if (this.state.users[userId].processing) {
			return;
		}
		var allUsersObj = this.state.users;
		if (this.state.users[userId].editable) {
			// set the user role
			const formRequest = {
				IsAdmin: allUsersObj[userId].IsAdmin,
				IsMentor: allUsersObj[userId].IsMentor,
				IsMentee: allUsersObj[userId].IsMentee,
			};
			axios
				.post(this.props.api_url + '/user/' + userId, formRequest)
				.then((response) => {
					allUsersObj[userId].processing = false;
					this.allUsersObj = allUsersObj;
					this.setState({ users: allUsersObj });
				})
				.catch((error) => {
					if (error.response.data.code === "auth/id-token-expired") {
						removeAllSavedCookies();
						this.props.history.push('/login');
					}
					if (error.response.status === 403) {
						// Tried to change someone else's details without privileges
						console.error(error.response.data.message);
					}
					console.error(error);
					alert("Could not update user role.\nReason: " + error.response.statusText + "\n" + error.response.data.message);
					window.location.reload();
				});

			// mark as non-editable
			allUsersObj[userId].editable = false;
			allUsersObj[userId].processing = true;
		} else {
			allUsersObj[userId].editable = true;
		}
		this.setState({ users: allUsersObj });
	}

	render() {
		const { classes } = this.props;
		return (
			<List dense disablePadding className={classes.rootList}>
				{Object.values(this.state.users).map((user) => (
					<ListItem key={user.Id} divider>
						<ListItemAvatar>
							<Avatar alt={user.Name} src={user.PhotoURL} />
						</ListItemAvatar>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<ListItemText primary={user.Name + "(" + user.GitHubId + ")"} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormGroup row>
									{this.allRoles.map((role) => (
										<FormControlLabel
											control={<Checkbox
												checked={user["Is" + role]}
												onChange={this.handleChange(user.Id)}
												disabled={!user.editable}
												name={"Is" + role}
											/>}
											label={role}
											key={role}
										/>
									))}
								</FormGroup>
							</Grid>
						</Grid>
						<ListItemSecondaryAction>
							<IconButton
								edge="end"
								aria-label="edit"
								analyticsObj={this.analyticsObj}
								onClick={this.handleEditButton(user.Id)}
							>
								{(user.editable) ? <SendIcon /> : (user.processing) ? <CircularProgress /> : <EditIcon />}
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		)
	}
}

export default withStyles(styles)(UserRoles);