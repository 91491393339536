import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import hrefAnalytics from "../../util/hrefAnalytics";

const styles = (theme) => ({
	root: {
		fontWeight: theme.typography.fontWeightMedium,
		fontFamily: theme.typography.fontFamilySecondary,
		padding: props => (props.paddedIconButton) ? (theme.spacing(2, 4)) : null,
		fontSize: theme.typography.pxToRem(14),
		boxShadow: 'none',
		'&:active, &:focus': {
			boxShadow: 'none',
		},
	},
	sizeSmall: props => (props.paddedIconButton) ? ({
		padding: theme.spacing(1, 3),
		fontSize: theme.typography.pxToRem(13),
	}) : null,
});

function IconButton(props) {
	const { children, href, target, onClick, analyticsObj, paddedIconButton, ...other } = props;
	// paddedIconButton is extracted only to remove it from the ...other props

	analyticsObj.method = "IconButton";
	const newOnClick = hrefAnalytics(href, onClick, analyticsObj, target);

	return (
		<MuiIconButton onClick={newOnClick} {...other} >
			{children}
		</MuiIconButton>
	);
}

IconButton.propTypes = {
	paddedIconButton: PropTypes.bool,
	children: PropTypes.node,
	classes: PropTypes.object,
	href: PropTypes.string,
	target: PropTypes.string,
	onClick: PropTypes.func,
	analyticsObj: PropTypes.object,
};

export default withStyles(styles)(IconButton);