import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Button from '../components/Button';
import ImageBlackWithCredits from '../components/ImageBlackWithCredits';

import projectIcon from '../../resources/images/project.png';
import withdrawIcon from '../../resources/images/withdraw.svg';
import friendsIcon from '../../resources/images/friends.svg';
import launchIcon from '../../resources/images/startup.png';

const styles = (theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.default,
		overflow: 'hidden',
	},
	container: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(10),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	title: {
		[theme.breakpoints.up('md')]: {
			marginBottom: theme.spacing(10),
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(5),
		},
	},
	item: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(0, 5),
	},
	iconStoryContainer: {
		marginBottom: theme.spacing(5)
	},
	number: {
		fontSize: 24,
		fontFamily: theme.typography.fontFamily,
		color: theme.palette.secondary.main,
		fontWeight: theme.typography.fontWeightMedium,
	},
	image: {
		height: 75,
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
});

function ProductHowItWorks(props) {
	const { classes, pageName } = props;
	const analyticsObj = {
		source: "ProductHowItWorks",
		page: pageName,
	};

	return (
		<section className={classes.root}>
			<Container className={classes.container}>
				{(props.titleText.trim() && props.titleText.trim().length !== 0) ?
					<Typography variant="h4" marked="center" className={classes.title} component="h2">
						{props.titleText}
					</Typography>
					: null
				}
				<div>
					<Grid container spacing={5} className={classes.iconStoryContainer} >
						<Grid item xs={12} md={3}>
							<div className={classes.item}>
								<div className={classes.number}>1.</div>
								<ImageBlackWithCredits
									icon={projectIcon}
									altText="project"
									authorName="dDara"
									authorLink="https://www.flaticon.com/free-icons/project"
									sourceName="Flaticon"
									sourceLink="https://www.flaticon.com/"
									className={classes.image}
								/>
								<Typography variant="h5" align="center">
									Scope the audition project and deposit its value
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} md={3}>
							<div className={classes.item}>
								<div className={classes.number}>2.</div>
								<ImageBlackWithCredits
									icon={launchIcon}
									altText="launch"
									authorName="Freepik"
									authorLink="https://www.flaticon.com/free-icons/rocket"
									sourceName="Flaticon"
									sourceLink="https://www.flaticon.com/"
									className={classes.image}
								/>
								<Typography variant="h5" align="center">
									Invite mentors & contributors and launch the project
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} md={3}>
							<div className={classes.item}>
								<div className={classes.number}>3.</div>
								<ImageBlackWithCredits
									icon={friendsIcon}
									altText="friends"
									authorName="Freepik"
									authorLink="https://www.flaticon.com/authors/freepik"
									sourceName="Flaticon"
									sourceLink="https://www.flaticon.com/"
									className={classes.image}
								/>
								<Typography variant="h5" align="center">
									Work with the contributors and evaluate if they are a good fit for your team

								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} md={3}>
							<div className={classes.item}>
								<div className={classes.number}>4.</div>
								<ImageBlackWithCredits
									icon={withdrawIcon}
									altText="withdraw"
									authorName="ultimatearm"
									authorLink="https://www.flaticon.com/free-icon/withdraw_2146185?term=withdraw%20money&page=1&position=3"
									sourceName="Flaticon"
									sourceLink="https://www.flaticon.com/"
									className={classes.image}
								/>
								<Typography variant="h5" align="center">
									End the project, offer a job to your favourite candidates and return with actual increments on your codebase.
								</Typography>
							</div>
						</Grid>
					</Grid>
				</div>
				{
					(props.programButton) ?
						<Button
							color="secondary"
							variant="contained"
							size="large"
							component="a"
							href="/program"
							analyticsObj={analyticsObj}
						>
							Learn more
					</Button>
						: null
				}
			</Container>
		</section>
	);
}

ProductHowItWorks.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHowItWorks);