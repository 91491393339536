import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const styles = (theme) => ({
root: {
	color: theme.palette.common.white,
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	zIndex: 0,
},
landingPage: {
	[theme.breakpoints.up('sm')]: {
		height: '80vh',
		minHeight: 500,
		maxHeight: 1300,
	},
},
sectionPage: {
	[theme.breakpoints.up('sm')]: {
		height: '40vh',
		minHeight: 200,
		maxHeight: 600,
	},
},
container: {
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(14),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
},
backdrop: {
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundColor: theme.palette.common.black,
	opacity: 0.5,
	zIndex: -1,
},
background: {
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	zIndex: -2,
},
arrowDown: {
	position: 'absolute',
	bottom: theme.spacing(4),
},
});

function ProductHeroLayout(props) {
	const { backgroundClassName, sectionType, children, classes } = props;
	let sizeClassName = (sectionType === "section") ? classes.sectionPage : classes.landingPage;
	
	return (
		<section className={clsx(classes.root, sizeClassName)}>
			<Container className={classes.container}>
				<div style={{height:80}} />
				{children}
				<div className={classes.backdrop} />
				<div className={clsx(classes.background, backgroundClassName)} />
				<div
				height="16"
				width="12"
				/>
			</Container>
		</section>
	);
}

ProductHeroLayout.propTypes = {
	backgroundClassName: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHeroLayout);