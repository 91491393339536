import React from "react";
import axios from 'axios';
import { authMiddleWare } from '../../../util/auth';
import { removeAllSavedCookies } from "../../../util/firebaseAuth";

import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography, withStyles } from "@material-ui/core";
import IconButton from "../../../mui_modules/components/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import Button from "../../../mui_modules/components/Button";

const styles = (theme) => ({
	list: {
		width: '100%',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(-1),
	},
})

class RecordBuyInAndCashout extends React.Component {
	constructor(props) {
		super(props);
		this.analyticsObj = {
			source: "recordBuyInAndCashout",
			page: this.props.pageName,
		}

		this.state = {
			User: null,
			Epic: null,
			userEpicBuyInCashoutStatus: null,
			disabled: false,
		}
	}

	handleEditClick = (epic) => (event) => {
		event.preventDefault()
		this.setState({
			Epic: epic,
			userEpicBuyInCashoutStatus: (epic.Deposited) ? (epic.Withdrawn) ? 2 : 1 : 0,
		})
	};

	handleBuyInCashoutChange = (event, buyInOrCashout) => {
		this.setState({ userEpicBuyInCashoutStatus: buyInOrCashout });
	}

	handleEditClose = () => {
		this.setState({ Epic: null })
	}

	handleEditSubmit = (event) => {
		event.preventDefault();
		this.setState({ disabled: true });
		let userEpicUpdateObj = null;
		switch (this.state.userEpicBuyInCashoutStatus) {
			case 0:
				userEpicUpdateObj = {
					Deposited: false,
					Withdrawn: false,
				};
				break;
			case 1:
				userEpicUpdateObj = {
					Deposited: true,
					Withdrawn: false,
				};
				break;
			case 2:
				userEpicUpdateObj = {
					Deposited: true,
					Withdrawn: true,
				}
				break;
			default:
				this.setState({
					errorMsg: 'Impossible value attempted',
					disabled: false,
				})
				return;
		}
		axios
			.post(this.props.api_url + `/admin/userEpicFlags/${this.state.User.Id}/${this.state.Epic.Epic.Id}`, userEpicUpdateObj)
			.then((response) => {
				console.log(response);
				var epic = this.state.Epic;
				epic.Deposited = userEpicUpdateObj.Deposited;
				epic.Withdrawn = userEpicUpdateObj.Withdrawn;
				this.setState({ Epic: epic, disabled: false });
				this.handleEditClose();
			})
			.catch((error) => {
				console.error(error);
				this.setState({ disabled: false });
			})
	}

	handleUserChange = (event, value, reason) => {
		this.setState({ User: value, disabled: true });
	}

	getUserEpics = (targetUserId) => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };

		axios
			.get(this.props.api_url + `/users/${targetUserId}/user_epics`)
			.then((response) => {
				console.log(response.data);
				this.userEpics = response.data;
				this.setState({ disabled: false })
			})
			.catch((error) => {
				console.error(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				this.setState({
					errorMsg: 'Error in retrieving the data',
					disabled: false,
				});
			})
	}

	componentDidUpdate() {
		if (this.state.User == null) {
			if (this.preparedDataUserId != null) {
				this.preparedDataUserId = null;
				this.userEpics = null
				this.setState({ disabled: false })
			}
		}
		else if (this.state.User.Id !== this.preparedDataUserId) {
			console.log("prev user: " + this.preparedDataUserId + " | new user: " + this.state.User.Id)
			this.preparedDataUserId = this.state.User.Id
			this.getUserEpics(this.state.User.Id);
		}
	}

	renderUserEpic = (user_epic) => {
		if ("Epic" in user_epic) {
			return (
				<ListItem key={user_epic.Epic.Id} divider>
					<Grid container>
						<Grid item xs={12} sm={1}>
							{user_epic.Role}
						</Grid>
						<Grid item xs={12} sm={8}>
							<ListItemText primary={user_epic.Epic.Name} secondary={user_epic.Project.Name} />
						</Grid>
						<Grid item xs={12} sm={3}>
							<Button disabled analyticsObj={this.analyticsObj}>
								{(user_epic.Deposited) ? (user_epic.Withdrawn) ? "Withdrawn" : "Deposited" : "Awaiting payment"}
							</Button>
						</Grid>
						<ListItemSecondaryAction>
							<IconButton onClick={this.handleEditClick(user_epic)} analyticsObj={this.analyticsObj} disabled={this.state.disabled}>
								<EditIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</Grid>
				</ListItem>
			);
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<Grid container>
				<Grid item xs={12} sm={4}>
					<Autocomplete
						disabled={this.state.disabled}
						fullWidth
						options={this.props.all_users}
						getOptionLabel={(option) => option.Name + " (" + option.GitHubId + ")"}
						// defaultValue={[allUsers[13]]}
						value={this.state.User}
						id="user"
						name="User"
						onChange={this.handleUserChange}
						getOptionSelected={(option, value) => {
							if (option.Id === value.Id) {
								return true;
							} else {
								return false;
							}
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="User"
								placeholder="John Doe"
							/>
						)}
					/>
				</Grid>
				<Grid item xs={false} sm={8}></Grid>
				{(!this.preparedDataUserId) ?
					<Typography variant="subtitle1">No user selected</Typography>
					:
					<List className={classes.list}> {
						this.userEpics.map((user_epic) => this.renderUserEpic(user_epic))
					} </List>
				}
				<Dialog open={this.state.Epic != null} onClose={this.handleEditClose}>
					<DialogTitle>Record Buy-in/Cashout</DialogTitle>
					<DialogContent>
						<ToggleButtonGroup value={this.state.userEpicBuyInCashoutStatus} exclusive onChange={this.handleBuyInCashoutChange}>
							<ToggleButton value={0}>Awaiting Payment</ToggleButton>
							<ToggleButton value={1}>Deposited</ToggleButton>
							<ToggleButton value={2}>Withdrawn</ToggleButton>
						</ToggleButtonGroup>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleEditClose} color="primary" analyticsObj={this.analyticsObj} disabled={this.state.disabled}>
							Cancel
						</Button>
						<Button onClick={this.handleEditSubmit} color="secondary" analyticsObj={this.analyticsObj} disabled={this.state.disabled}>
							Submit
						</Button>
					</DialogActions>
				</Dialog>
			</Grid >
		)
	}
}

export default withStyles(styles)(RecordBuyInAndCashout);