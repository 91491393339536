import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, FormControl, FormLabel, makeStyles } from '@material-ui/core';
import Markdown from './Markdown';

const MarkdownTextFieldDisplay = (props) => {
    const useStyles = makeStyles((theme) => createStyles({
        label: {
            transform: 'translate(0, 1.5px) scale(0.75)',
            transformOrigin: 'top left',
        },
        value: {
            fontSize: theme.typography.htmlFontSize,
            minHeight: (theme.typography.htmlFontSize * 1.5) * props.rows,
            maxHeight: (theme.typography.htmlFontSize * 1.5) * props.rowsMax,
            paddingTop: theme.spacing(1) - 2,
            paddingBottom: theme.spacing(1) - 1,
        },
    }));

    const classes = useStyles(props);
    const labelId = "".concat(props.id, "-label");
    return (
        <FormControl fullWidth={props.fullWidth}>
            <FormLabel
                id={labelId}
                className={(props.value && props.value !== "") ? classes.label : null}
            >
                {props.label}
            </FormLabel>
            <Markdown id={props.id} className={classes.value}>
                {props.value}
            </Markdown>
        </FormControl>
    )
}

MarkdownTextFieldDisplay.propTypes = {
    id: PropTypes.string,
    fullWidth: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
}

export default MarkdownTextFieldDisplay;