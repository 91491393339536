import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Container, CircularProgress, Hidden, ListItem, ListItemIcon, ListItemText, Grid, List, ListSubheader } from '@material-ui/core';
import axios from 'axios';
import { authMiddleWare } from '../../util/auth';
import CssBaseline from '@material-ui/core/CssBaseline';
import LearnAppBar from './views/LearnAppBar';
import { withStyles } from '@material-ui/core/styles';
import ViewTransactionDialog from './views/ViewTransactionDialog';
import { removeAllSavedCookies } from '../../util/firebaseAuth';
import { TransactionTypes, txnTypeToIcon, getCurrencySymbol } from '../../util/uiUtil';

const style = (theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.default,
		overflow: 'hidden',
		flexDirection: 'column',
		alignItems: 'center',
	},
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		top: '35%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	container: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
			margin: theme.spacing(0),
		},
		[theme.breakpoints.up('md')]: {
			width: theme.breakpoints.values.md,
			marginTop: theme.spacing(10),
			marginBottom: theme.spacing(10),
		},
	},
	list: {
		width: '100%',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(-1),
	},
	transactionName: {
		'&:hover': {
			background: "#bbdefb",
		},
		background: "#ffffff",
		margin: theme.spacing(0),

	},
	balanceIncrease: {
		color: "#4caf50",
		fontSize: theme.typography.htmlFontSize,
	},
	balanceDecrease: {
		color: "#b80000",
		fontSize: theme.typography.htmlFontSize,
	},
	balanceNeutral: {
		color: "#444444",
		fontSize: theme.typography.htmlFontSize,
	},
});

class TransactionPage extends React.Component {
	constructor(props) {
		super(props)
		this.userId = this.props.match.params.userId;

		this.state = {
			uiLoading: true,
			viewOpen: false,
			viewTransaction: null,
		}
		this.analyticsObj = {
			source: "TransactionPage", //What is this?
			page: "TransactionPage",
		}
		this.userDetails =  null;
		this.transactions = null;
	}
	componentDidMount = () => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		Promise.all([
			axios.get(this.props.api_url + '/user/' + this.userId),
			axios.get(this.props.api_url + '/users/' + this.userId + '/transactions')
		]).then(([response1, response2]) => {
			this.userDetails = response1.data.userCredentials;
			this.transactions = response2.data.map((transaction) => {
				transaction.TransactionTime = (transaction.TransactionTime) ? new Date(transaction.TransactionTime._seconds * 1000) : null;
				transaction.CreatedAt = (transaction.CreatedAt) ? new Date(transaction.CreatedAt._seconds * 1000) : null;
				transaction.LastModifiedAt = (transaction.LastModifiedAt) ? new Date(transaction.LastModifiedAt._seconds * 1000) : null;
				return transaction;
			})
			this.setState({ uiLoading: false });
		})
		.catch((error) => {
			console.error(error);
			if (error.response != null && error.response.status === 403) { //is this the correct way of handling this kind of error?
				removeAllSavedCookies();
				this.props.history.push('/login');
			}
		});
	};

	handleViewOpen(transaction) {
		this.setState({
			viewOpen: true,
			viewTransaction: transaction
		});
	}

	handleViewClose = () => {
		this.setState({ viewOpen: false });
	};

	getSecondaryTextLeft = (transaction) => {
		const baseText = (transaction.TransactionTime) ? transaction.TransactionTime.toLocaleString() : transaction.LastModifiedAt.toLocaleString();
		if (transaction.Status !== "SUCCESS") {
			const prefix = transaction.Status.charAt(0) + transaction.Status.toLowerCase().slice(1);
			return prefix + " ∙ " + baseText;
		}
		return baseText;
	}

	getClassNameForTxnAmount = (transaction) => {
		if (transaction.Status === "SUCCESS") {
			if ((transaction.Type === TransactionTypes.DEPOSIT) || (transaction.Type === TransactionTypes.WINNING)) {
				return this.props.classes.balanceIncrease;
			} else {
				return this.props.classes.balanceDecrease;
			}
		} else {
			return this.props.classes.balanceNeutral;
		}
	}

	render() {
		const { classes } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<div className={classes.loadingRoot}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</div>
			);
		} else {
			const { classes, history } = this.props;
			return (
				<div>
					<CssBaseline />
					<LearnAppBar pageName={"TransactionPage"} history={history} />

					<section className={classes.root}>
						<Container className={classes.container}>
							<List className={classes.list} subheader={
								<ListSubheader component={'div'} >
									All Transactions
								</ListSubheader>
							}>
								{(this.transactions) ? this.transactions.map((transaction) => (
									<ListItem onClick={() => this.handleViewOpen({ transaction })} dense className={classes.transactionName} key={transaction.TransactionId} divider >
										<Grid container justify='center' alignItems='center'>
											<Grid item xs={1} >
												<ListItemIcon>{txnTypeToIcon(transaction.Type, transaction.Status)}</ListItemIcon>
											</Grid>
											<Grid item xs={7} sm={8} >
												<ListItemText
													primary={transaction.Message}
													secondary={this.getSecondaryTextLeft(transaction)}
												/>
											</Grid>
											<Hidden smDown>
												<Grid item sm={1} >
													<ListItemIcon fontSize={22}>
														<Chip fontSize={22} label={transaction.Method} />
													</ListItemIcon>
												</Grid>
											</Hidden>
											<Grid item xs={4} sm={2}>
												<ListItemText disableTypography fontWeight="fontWeightBold" align="right" className={this.getClassNameForTxnAmount(transaction)}>
													{((transaction.Type === TransactionTypes.DEPOSIT) || (transaction.Type === TransactionTypes.WINNING)) ? "+" : "-"}
													{getCurrencySymbol(transaction.Value.Currency) + transaction.Value.Amount}
												</ListItemText>
											</Grid>
										</Grid>
									</ListItem>
								)) : null}
							</List>

							{(this.state.viewOpen) ?
								<ViewTransactionDialog analyticsObj={this.analyticsObj} userDetails={this.userDetails} open={this.state.viewOpen} onClose={this.handleViewClose} {...this.state.viewTransaction} />
								: null}
						</Container>
					</section>
				</div>
			);
		}
	}
}

TransactionPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(style)(TransactionPage);
