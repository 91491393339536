import React from "react";
import PropTypes from "prop-types";
import { withStyles, Container } from "@material-ui/core";
import Typography from '../components/Typography';
import Link from "../components/Link";

const styles = (theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.paper,
	},
	container: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(10),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
		},
		[theme.breakpoints.up('md')]: {
			width: theme.breakpoints.values.md,
		},
	},
	title: {
		marginBottom: theme.spacing(3),
	},
})

function LinkToFAQ(props) {
	const { classes, pageName } = props;
	const analyticsObj = {
		source: "LinkToFAQ",
		page: pageName,
	};
	
	return (
		<section className={ classes.root }>
			<Container className={ classes.container }>
				<Typography variant="h5" className={classes.title} >
					Still have doubts regarding the program?
				</Typography>
				<Typography variant="body1" className={classes.title} >
					You might find answers to your questions in the <Link
						href="contactUs"
						color="secondary"
						analyticsObj={ analyticsObj }
					>FAQs</Link>. 
					<br/>
					If not, you can directly get in touch with us on the <Link
						href="contactUs"
						color="secondary"
						analyticsObj={ analyticsObj }
					>
						Contact Us page
					</Link>.
				</Typography>
			</Container>
		</section>
	)
}

LinkToFAQ.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LinkToFAQ);