import React from 'react';
import { withStyles, AppBar, Toolbar, IconButton, Grid, TextField, Select, Collapse, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../../mui_modules/components/Button';
import Typography from '../../../mui_modules/components/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = ((theme) => ({
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: 'inherit',
	},
	form: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(3),
		width: '100%',
	},
	advancedConfigSection: {
		marginTop: theme.spacing(2),
	},
}))

class AddEpicDialog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			Name: "",
			Description: "",
			ValueCurrency: "INR",
			ValueAmount: "",
			BuyInCurrency: "INR",
			BuyInAmount: "",
			MinNumMentees: "",
			MaxNumMentees: "",
			Timeline: {},
			DurationInWeeks: "",
			State: "",
			MentorshipCommissionRate: 10,
			MentorSecurityRatio: 100,
			TeamWorkToCompletionValueRatio: 0,
			advanceConfigOpen: false,
			buttonDisabled: false,
		}

		this.transition = React.forwardRef(function Transition(props, ref) {
			return <Slide direction="up" ref={ref} {...props} />;
		});

		this.analyticsObj = {
			source: "AddEpicDialog",
			page: this.props.pageName,
		};
	}

	updateProps = () => {
		if (this.props.buttonType === 'Edit') {
			const initialMentorshipCommissionRate = this.props.epic.MentorshipCommissionRate ? this.props.epic.MentorshipCommissionRate : 0.1;
			const initialMentorSecurityRatio = this.props.epic.MentorSecurityRatio ? this.props.epic.MentorSecurityRatio : 1;
			const initialTeamWorkToCompletionValueRatio = this.props.epic.TeamWorkToCompletionValueRatio ? this.props.epic.TeamWorkToCompletionValueRatio : 0;
			this.setState({
				Name: this.props.epic.Name,
				Description: this.props.epic.Description,
				ValueCurrency: this.props.epic.Value.Currency,
				ValueAmount: this.props.epic.Value.Amount,
				BuyInCurrency: this.props.epic.BuyIn.Currency,
				BuyInAmount: this.props.epic.BuyIn.Amount,
				MinNumMentees: this.props.epic.Mentees.Min,
				MaxNumMentees: this.props.epic.Mentees.Max,
				Timeline: this.props.epic.Timeline,
				DurationInWeeks: this.props.epic.DurationInWeeks,
				State: this.props.epic.State,
				MentorshipCommissionRate: initialMentorshipCommissionRate * 100,
				MentorSecurityRatio: initialMentorSecurityRatio * 100,
				TeamWorkToCompletionValueRatio: initialTeamWorkToCompletionValueRatio * 100,
				advanceConfigOpen: false,
				buttonDisabled: false,
			});
		} else if (this.props.buttonType === 'Add') {
			this.setState({
				Name: "",
				Description: "",
				ValueCurrency: "INR",
				ValueAmount: "",
				BuyInCurrency: "INR",
				BuyInAmount: "",
				MinNumMentees: "",
				MaxNumMentees: "",
				Timeline: {},
				DurationInWeeks: "",
				State: "Pending",
				MentorshipCommissionRate: 10,
				MentorSecurityRatio: 100,
				TeamWorkToCompletionValueRatio: 0,
				advanceConfigOpen: false,
				buttonDisabled: false,
			});
		}

		this.props.setUpdateDialogProps(false);
	}

	handleAdvConfigCollapseClick = () => {
		this.setState({ advanceConfigOpen: !this.state.advanceConfigOpen });
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ buttonDisabled: true });
		const newEpic = {
			Name: this.state.Name,
			Description: this.state.Description,
			State: this.state.State,
			Value: {
				Currency: this.state.ValueCurrency,
				Amount: parseInt(this.state.ValueAmount)
			},
			BuyIn: {
				Currency: this.state.BuyInCurrency,
				Amount: parseInt(this.state.BuyInAmount)
			},
			Mentees: {
				Min: this.state.MinNumMentees,
				Max: this.state.MaxNumMentees,
				Curr: [],
			},
			Timeline: this.state.Timeline,
			DurationInWeeks: this.state.DurationInWeeks,
			MentorshipCommissionRate: this.state.MentorshipCommissionRate / 100,
			MentorSecurityRatio: this.state.MentorSecurityRatio / 100,
			TeamWorkToCompletionValueRatio: this.state.TeamWorkToCompletionValueRatio / 100,
			Project: {
				Id: this.props.project.Id,
				Name: this.props.project.Name,
				Owner: this.props.project.Owner,
			}
		};
		this.props.handleSubmit(newEpic).then(() => {
			this.setState({ buttonDisabled: false });
		});
	};

	render() {
		const { classes } = this.props;

		if (this.props.updateDialogProps) {
			this.updateProps();
		}

		return (
			<Dialog fullWidth open={this.props.open} onClose={this.props.handleClose} TransitionComponent={this.transition}>
				<AppBar position="relative" className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close">
							<CloseIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{this.props.buttonType === 'Edit' ? 'Edit Epic' : 'Create a new Epic'}
						</Typography>
					</Toolbar>
				</AppBar>

				<form className={classes.form} noValidate>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="epicName"
								label="Epic Name"
								name="Name"
								autoComplete="epicName"
								helperText={this.props.errors.Name}
								value={this.state.Name}
								error={this.props.errors.Name ? true : false}
								onChange={this.handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="epicDescription"
								label="Epic Description"
								name="Description"
								autoComplete="epicDescription"
								multiline
								rows={3}
								rowsMax={10}
								helperText={this.props.errors.Description}
								error={this.props.errors.Description ? true : false}
								onChange={this.handleChange}
								value={this.state.Description}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Select
								label="Currency"
								variant="outlined"
								required
								id="epicValueCurrency"
								name="ValueCurrency"
								value={this.state.ValueCurrency}
								onChange={this.handleChange}
							>
								<MenuItem value={"INR"}>INR</MenuItem>
							</Select>
							<TextField
								variant="outlined"
								required
								id="epicValueAmount"
								label="Value"
								name="ValueAmount"
								type="number"
								autoComplete="epicValue"
								helperText={this.props.errors.Value}
								value={this.state.ValueAmount}
								error={this.props.errors.Value ? true : false}
								onChange={this.handleChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Select
								label="Currency"
								variant="outlined"
								required
								id="epicBuyInCurrency"
								name="BuyInCurrency"
								value={this.state.BuyInCurrency}
								onChange={this.handleChange}
							>
								<MenuItem value={"INR"}>INR</MenuItem>
							</Select>
							<TextField
								variant="outlined"
								required
								id="epicBuyInAmount"
								label="BuyIn"
								name="BuyInAmount"
								type="number"
								autoComplete="epicBuyIn"
								helperText={this.props.errors.BuyIn}
								value={this.state.BuyInAmount}
								error={this.props.errors.BuyIn ? true : false}
								onChange={this.handleChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="minNumMentees"
								label="Minimum number of Mentees"
								name="MinNumMentees"
								type="number"
								autoComplete="minNumMentees"
								helperText={this.props.errors.MinNumMentees}
								value={this.state.MinNumMentees}
								error={this.props.errors.MinNumMentees ? true : false}
								onChange={this.handleChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="maxNumMentees"
								label="Maximum number of Mentees"
								name="MaxNumMentees"
								type="number"
								autoComplete="maxNumMentees"
								helperText={this.props.errors.MaxNumMentees}
								value={this.state.MaxNumMentees}
								error={this.props.errors.MaxNumMentees ? true : false}
								onChange={this.handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								id="durationInWeeks"
								label="Duration"
								name="DurationInWeeks"
								type="number"
								autoComplete="durationInWeeks"
								helperText={this.props.errors.DurationInWeeks}
								value={this.state.DurationInWeeks}
								error={this.props.errors.DurationInWeeks ? true : false}
								onChange={this.handleChange}
								InputProps={{
									endAdornment: <InputAdornment position="end">Weeks</InputAdornment>,
								}}
							/>
						</Grid>

						{/* TODO: Add Timeline field */}

						<Grid item xs={12}>
							<Typography variant="caption" color="textSecondary" onClick={this.handleAdvConfigCollapseClick}>
								Advanced configurations {(this.state.advanceConfigOpen) ? "-" : "+"}
							</Typography>
							<Divider />
							<Collapse in={this.state.advanceConfigOpen} timeout="auto" unmountOnExit className={classes.advancedConfigSection}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<TextField
											variant="outlined"
											fullWidth
											required
											id="mentorshipCommissionRate"
											label="Mentors' Commission"
											name="MentorshipCommissionRate"
											type="number"
											defaultValue={0.1}
											inputProps={{ min: 0, max: 100 }}
											autoComplete="mentorshipCommissionRate"
											helperText={this.props.errors.MentorshipCommissionRate}
											value={this.state.MentorshipCommissionRate}
											error={this.props.errors.MentorshipCommissionRate ? true : false}
											onChange={this.handleChange}
											InputProps={{
												endAdornment: <InputAdornment position="end">%</InputAdornment>,
											}}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant="outlined"
											fullWidth
											required
											id="mentorSecurityRatio"
											label="Mentor buy-in protection"
											name="MentorSecurityRatio"
											type="number"
											defaultValue={100}
											inputProps={{ min: 0, max: 100 }}
											title="Percentage of earnings solely for mentoring to be dispensed"
											autoComplete="mentorSecurityRatio"
											helperText={this.props.errors.MentorSecurityRatio}
											value={this.state.MentorSecurityRatio}
											error={this.props.errors.MentorSecurityRatio ? true : false}
											onChange={this.handleChange}
											InputProps={{
												endAdornment: <InputAdornment position="end">%</InputAdornment>,
											}}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant="outlined"
											fullWidth
											required
											id="teamWorkToCompletionValueRatio"
											label="Importance of team-work"
											name="TeamWorkToCompletionValueRatio"
											type="number"
											defaultValue={0}
											inputProps={{ min: 0, max: 100 }}
											autoComplete="teamWorkToCompletionValueRatio"
											helperText={this.props.errors.TeamWorkToCompletionValueRatio}
											value={this.state.TeamWorkToCompletionValueRatio}
											error={this.props.errors.TeamWorkToCompletionValueRatio ? true : false}
											onChange={this.handleChange}
											InputProps={{
												endAdornment: <InputAdornment position="end">%</InputAdornment>,
											}}
										/>
									</Grid>
								</Grid>
							</Collapse>
						</Grid>

						<Grid item xs={12}>
							<Button
								fullWidth
								color="primary"
								size="large"
								variant="contained"
								onClick={this.handleSubmit}
								analyticsObj={this.analyticsObj}
								disabled={this.state.buttonDisabled}
							>
								{this.props.buttonType === 'Edit' ? 'Save' : 'Submit'}
							</Button>
						</Grid>
					</Grid>
				</form>
			</Dialog>
		)
	}
}

export default withStyles(styles)(AddEpicDialog);