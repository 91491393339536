import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { tradeMarkedAlokit } from '../../../util/uiUtil';

const styles = StyleSheet.create({
	tableContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 24,
		borderWidth: 1,
	},
	header: {
		flexDirection: 'row',
		borderBottomWidth: 1,
		alignItems: 'center',
		height: 24,
		textAlign: 'center',
		fontStyle: 'bold',
		flexGrow: 1,
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		height: 24,
		fontStyle: 'bold',
	},
	description: {
		width: '75%',
		borderRightWidth: 1,
		marginLeft: '5px'
	},
	amount: {
		width: '25%',
		marginLeft: '5px',
	},
});

const ReceiptItemsTable = (props) => (
	<View style={styles.tableContainer}>
		<View style={styles.header}>
			<Text style={styles.description}>{tradeMarkedAlokit()} Epic</Text>
			<Text style={styles.amount}>Amount</Text>
		</View>
		<View style={styles.row}>
			<Text style={styles.description}>{props.epicName}</Text>
			<Text style={styles.amount}>{props.currency} {props.amount}</Text>
		</View>
	</View>
);

export default ReceiptItemsTable