import React from 'react';
import axios from 'axios';

import { CssBaseline, withStyles, Grid, Container, Divider, Collapse } from '@material-ui/core';
import LearnAppBar from './views/LearnAppBar';
import AppFooter from '../../mui_modules/views/AppFooter';
import CircularProgress from '@material-ui/core/CircularProgress';
import { gAnalytics } from '../../util/analytics';
import { authMiddleWare } from '../../util/auth';
import UserProfileDrawer from './views/UserProfileDrawer';
import ProfileMetrics from './views/ProfileMetrics';
import ProjectList from './views/ProjectList';
import Typography from '../../mui_modules/components/Typography';
import { removeAllSavedCookies } from '../../util/firebaseAuth';

const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		top: '35%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	container: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	section: {
		paddingLeft: theme.spacing(4),
	},
});

class ProfilePage extends React.Component {
	constructor(props) {
		super(props);

		this.userId = this.props.match.params.userId;
		// TODO: Currently we can only view our own profile
		// going forward, we would like anyone to be able to view any body's profile

		this.state = {
			open: false,
			name: '',
			profilePicture: '',
			githubId: '',
			joinedAt: null,
			description: '',
			uiLoading: true,
			imageLoading: false,
		};

		gAnalytics.logEvent('screen_view', {
			firebase_screen: 'ProfilePage',
		});
	}

	componentDidMount = () => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get(this.props.api_url + '/user/' + this.userId)
			.then((response) => {
				this.setState({
					name: response.data.userCredentials.Name,
					email: response.data.userCredentials.Email,
					phoneNumber: response.data.userCredentials.PhoneNumber,
					profilePicture: response.data.userCredentials.ImageUrl,
					githubId: response.data.userCredentials.GitHubId,
					joinedAt: new Date(response.data.userCredentials.CreatedAt._seconds * 1000),
					description: response.data.userCredentials.Description,
					uiLoading: false,
				});
			})
			.catch((error) => {
				console.log(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				this.setState({ errorMsg: 'Error in retrieving the profile data for user ' + this.userId });
			});
	};

	handleProjectCollapseClick = () => {
		this.setState({ open: !this.state.open });
	};

	render() {
		const { classes, history } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<div className={classes.loadingRoot}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</div>
			);
		} else {
			return (
				<section>
					<CssBaseline />
					<LearnAppBar pageName={"Profile"} history={history} />
					<Container className={classes.container}>
						<Grid container>
							<Grid item xs={12} sm={4} >
								<UserProfileDrawer pageName={"Profile"} {...this.state} />
							</Grid>
							<Grid item xs={12} sm={8} >
								<ProfileMetrics pageName={"Profile"}
									left={{
										displayType: "money",
										metricValue: {
											Currency: "INR",
											Amount: 1200
										},
										metricColor: '#dd0011',
										text: "Total Diposit",
									}}
									right={{
										displayType: "percent",
										metricValue: 30,
										metricColor: '#00dd11',
										text: "Current Progress",
										textColor: '#000000'
									}}
								/>
								<Divider variant="middle" />

								<Typography variant="caption" color="textSecondary" onClick={this.handleProjectCollapseClick} className={classes.section}>
									Projects {(this.state.open) ? "-" : "+"}
								</Typography>
								<Collapse in={this.state.open} timeout="auto" unmountOnExit>
									<ProjectList api_url={this.props.api_url} pageName="Profile" userId={this.userId} />
								</Collapse>
							</Grid>
						</Grid>
					</Container>
					<AppFooter pageName="Profile" />
				</section>
			)
		}
	}
}

export default withStyles(styles)(ProfilePage);