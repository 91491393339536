import React from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { withStyles } from '@material-ui/styles';
import { Avatar, Card, CardContent, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, ListItemIcon, Tooltip } from '@material-ui/core';
import { authMiddleWare } from '../../../util/auth';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import { convertArrayToObject, EpicStates, isMentor, isProjectOwner, reorderList, storyTypeToIcon } from '../../../util/uiUtil';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '../../../mui_modules/components/IconButton';
import Typography from '../../../mui_modules/components/Typography';
import Button from '../../../mui_modules/components/Button';
import AddIcon from '@material-ui/icons/Add';


const styles = (theme) => ({
	root: {
		overflow: 'auto',
	},
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	flexContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	stateColumns: {
		minWidth: theme.spacing(35),
		width: '100%'
	},
	entireWidth: {
		width: '100%',
	},
	storyCard: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '100%',
	},
	storyCardContent: {
		paddingBottom: '16px !important',
	},
	viewContributors: {
		'& > *': {
			margin: theme.spacing(0.3), // TODO: check spacing(0.1)
		},
	},
	profilePicture: {
		height: theme.spacing(3),
		width: theme.spacing(3),
	},
	storyName: {
		overflow: 'hidden',
		fontSize: '18px',
	},
	addButton: {
		marginTop: theme.spacing(1),
	},
})

class BoardView extends React.Component {
	constructor(props) {
		super(props)
		this.boardStories = {}
		this.boardStoriesOrder = {}
		this.stories = {}
		this.storyState = []
		this.storiesReorder = {} // Object containing {storyId : initialState}
		this.state = {
			uiLoading: true,
			storiesLoading: true,
			boardStoriesOrderLoading: true,
			storyStatesLoading: true,
			showOrderingError: false,
			storyIdWithError: '',
			isDragging: {}, // Object containing {storyId: isDrag}
		}

		this.analyticsObj = {
			source: "BoardView",
			page: this.props.pageName,
		};
	}

	setIsDragging = (storyId, val) => {
		const isDraggingObject = this.state.isDragging;
		if (isDraggingObject[storyId] === val) {
			return;
		}
		isDraggingObject[storyId] = val;
		this.setState({ isDragging: isDraggingObject });
	}

	onBoardOrderEdit = (boardOrderMap, startState, finishState, storyId, initialRank, finalRank) => {
		const board = {
			BoardOrder: boardOrderMap,
			StateChange: !(startState === finishState),
			InitialState: startState,
			FinalState: finishState,
			InitialRankInState: initialRank,
			FinalRankInState: finalRank,
			StoryId: storyId,
		};
		this.storiesReorder[storyId] = board;
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.post(this.props.api_url + `/projects/${this.props.epic.Project.Id}/userEpics/${this.props.epic.Id}/board`, board)
			.then((response) => {
				console.debug(response);
				this.stories[storyId].State = board.FinalState;
				delete this.storiesReorder[storyId];
				this.setIsDragging(storyId, false);
			})
			.catch((err) => {
				console.error(err);
				if (err.response != null && err.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login')
				}
				if (err.response != null && err.response.data != null && err.response.data.message != null) {
					for (let msg of err.response.data.message) {
						this.props.enqueueSnackbar(msg, { variant: "error" })
					}
				} else {
					this.props.enqueueSnackbar(`Failed to reorder stories`, { variant: "error" });
				}
				this.setState({
					showOrderingError: true,
					storyIdWithError: storyId,
				});
			});
	};

	handleErrorDialog = () => {
		this.boardStoriesOrder[this.storiesReorder[this.state.storyIdWithError].InitialState] = [this.state.storyIdWithError].concat(this.boardStoriesOrder[this.storiesReorder[this.state.storyIdWithError].InitialState]);
		this.boardStoriesOrder[this.storiesReorder[this.state.storyIdWithError].FinalState].splice(this.storiesReorder[this.state.storyIdWithError].FinalRankInState, 1);
		delete this.storiesReorder[this.state.storyIdWithError];
		this.setState({
			showOrderingError: false,
			storyIdWithError: '',
		})
		this.setIsDragging(this.state.storyIdWithError, false)
	}

	onDragEnd = (result) => {
		const { destination, source, draggableId } = result;

		if (!destination) {
			this.setIsDragging(draggableId, false);
			return;
		}
		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			this.setIsDragging(draggableId, false);
			return;
		}

		const start = this.boardStoriesOrder[source.droppableId];
		const finish = this.boardStoriesOrder[destination.droppableId];

		if (start === finish) {
			const newStateOrder = reorderList(start, source.index, destination.index);
			this.boardStoriesOrder[source.droppableId] = newStateOrder
		} else {
			const startStoryIds = Array.from(start);
			startStoryIds.splice(source.index, 1);

			const finishStoryIds = Array.from(finish);
			finishStoryIds.splice(destination.index, 0, draggableId);

			this.boardStoriesOrder[source.droppableId] = startStoryIds;
			this.boardStoriesOrder[destination.droppableId] = finishStoryIds;
		}

		this.onBoardOrderEdit(this.boardStoriesOrder, source.droppableId, destination.droppableId, this.boardStoriesOrder[destination.droppableId][destination.index], source.index, destination.index);
	};

	rearrangeBoardStories() {
		this.storyState.forEach((state) => {
			var stateStoryIds = []
			this.boardStories[state].forEach((id) => {
				if (!(this.boardStoriesOrder[state].includes(id))) {
					stateStoryIds.push(id);
				}
			})
			this.boardStoriesOrder[state] = stateStoryIds.concat(this.boardStoriesOrder[state]);

			var hasStoryIds = [];
			this.boardStoriesOrder[state].forEach((id) => {
				if (this.boardStories[state].includes(id)) {
					hasStoryIds.push(id);
				}
			})
			this.boardStoriesOrder[state] = hasStoryIds;
		});
	}

	componentDidMount() {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get(this.props.api_url + `/projects/${this.props.epic.Project.Id}/epics/${this.props.epic.Id}/stories`)
			.then((response) => {
				this.stories = convertArrayToObject(response.data, 'StoryId');
				this.storyState = ['Backlog', 'In Progress', 'In Review', 'Done'] //TODO: Call this from epic document in future implementation
				this.storyState.forEach((state) => {
					this.boardStories[state] = []
				})
				response.data.forEach((value) => {
					this.boardStories[value.State].push(value.StoryId)
				})
				this.setState({
					storiesLoading: false,
				});
			})
			.catch((error) => {
				console.error(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login')
				}
				this.setState({ errorMsg: 'Error in retrieving the data' });
			});

		axios
			.get(this.props.api_url + `/users/${localStorage.getItem('UserId')}/user_epics/${this.props.epic.Id}`)
			.then((response) => {
				this.boardStoriesOrder = response.data.Board;
				if (!this.boardStoriesOrder) {
					this.boardStoriesOrder = {}
					var storyStates = ['Backlog', 'In Progress', 'In Review', 'Done']
					storyStates.forEach((state) => {
						this.boardStoriesOrder[state] = []
					})
				}
				this.setState({
					boardStoriesOrderLoading: false,
				});
			})
			.catch((error) => {
				console.error(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login')
				}
			})
	};

	componentDidUpdate() {
		if (!this.state.boardStoriesOrderLoading && !this.state.storiesLoading && this.state.uiLoading) {
			this.rearrangeBoardStories();
			this.setState({
				uiLoading: false,
			});
		}
	};

	render() {
		const { classes } = this.props;

		if (this.state.uiLoading) {
			return (
				<div className={classes.loadingRoot}>
					{this.state.uiLoading && <CircularProgress size={150} />}
				</div>
			)
		} else {
			return (
				<>
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Container className={classes.root}>
							<List className={classes.flexContainer}>
								{this.storyState.map((state) => (
									<ListItem alignItems="flex-start" className={classes.stateColumns} disableGutters key={state}>
										<Droppable droppableId={state}>
											{(provided) => (
												<div className={classes.entireWidth}>
													<Typography align="center" variant="h6">
														{state}
													</Typography>
													<List
														innerRef={provided.innerRef}
														{...provided.droppableProps}
														className={classes.entireWidth}
													>
														{this.boardStoriesOrder[state].map((storyId, index) => {
															const story = this.stories[storyId];
															return (
																<Draggable
																	key={storyId}
																	draggableId={storyId}
																	index={index}
																	className={classes.entireWidth}
																	isDragDisabled={this.state.isDragging[storyId]}
																>
																	{(provided, snapshot) => {
																		if (
																			((this.state.isDragging[storyId] === undefined) && snapshot.isDragging) ||
																			((this.state.isDragging[storyId] !== undefined) && (this.state.isDragging[storyId] !== snapshot.isDragging))
																		) {
																			this.setIsDragging(storyId, snapshot.isDragging);
																		}
																		return (
																			<ListItem
																				{...provided.draggableProps}
																				{...provided.dragHandleProps}
																				innerRef={provided.innerRef}
																				disableGutters
																				className={classes.entireWidth}
																				disabled={this.state.isDragging[storyId]}
																			>
																				<Card className={classes.storyCard}>
																					<CardContent className={classes.storyCardContent}>
																						<Grid container>
																							<Grid item xs={2}>
																								<Tooltip title={(story.Type) ? story.Type : "No Story Type"} placement="bottom" >
																									<ListItemIcon>
																										{storyTypeToIcon(story.Type)}
																									</ListItemIcon>
																								</Tooltip>
																							</Grid>
																							<Grid item xs={8}>
																								<Typography onClick={(this.state.isDragging[storyId]) ? () => (false) : () => this.props.handleViewOpen({ story })} className={classes.storyName}>
																									{story.Name}
																								</Typography>
																							</Grid>
																							<Grid item xs={2}>
																								<IconButton onClick={this.props.handleMenuOpen(story)} aria-haspopup="true" analyticsObj={this.analyticsObj}>
																									<MoreVertIcon fontSize="small" />
																								</IconButton>
																							</Grid>
																							<Grid item xs={2}>
																								<ListItemIcon>
																									<Chip size="small" label={story.Credits} />
																								</ListItemIcon>
																							</Grid>
																							<Grid item xs={10}>
																								{story.Contributors ?
																									<Grid container justify="flex-end" className={classes.viewContributors}>
																										{story.Contributors.map((contributor) =>
																											<Tooltip title={contributor.Name} key={contributor.Id} placement="bottom">
																												<Avatar className={classes.profilePicture} key={contributor.Id} alt={contributor.Id} src={contributor.PhotoURL} />
																											</Tooltip>
																										)}
																									</Grid>
																									: null}
																							</Grid>
																						</Grid>
																					</CardContent>
																				</Card>
																			</ListItem>
																		)
																	}}
																</Draggable>
															)
														})}
														{provided.placeholder}
													</List>
													{((isProjectOwner(this.props.epic.Project)) || (isMentor(this.props.epic))) ?
														<span>
															<Button
																size="small"
																variant="text"
																color="inherit"
																className={classes.addButton}
																onClick={this.props.handleAddStoryClick(state)}
																analyticsObj={this.analyticsObj}
																disabled={this.props.allUsersLoading || this.props.epic.State === EpicStates.FINISHED}
																startIcon={<AddIcon />}
															>
																Add Story
															</Button>
														</span>
														: null}
												</div>
											)}
										</Droppable>
									</ListItem>
								))}
							</List>
						</Container>
					</DragDropContext>
					<Dialog
						open={this.state.showOrderingError}
						onClose={this.handleErrorDialog}
					>
						<DialogTitle>
							{"Story Reordering Error"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								We weren't able to update your Board order. Please try to reorder it again or refresh. Sorry for the inconvinience.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleErrorDialog} analyticsObj={this.analyticsObj}>
								OK
							</Button>
						</DialogActions>
					</Dialog>
				</>
			)
		}
	}
}

export default withStyles(styles)(BoardView);