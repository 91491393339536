import React from 'react';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, withStyles } from '@material-ui/core';
import Button from '../../../mui_modules/components/Button';
import EarningsOfUsersInEpic from './EarningsOfUsersInEpic';

const styles = (theme) => ({
	dialogNote: {
		marginTop: theme.spacing(2),
	}
})

class EndEpicDialog extends React.Component {
	render() {
		const { classes } = this.props;

		return (
			<Dialog maxWidth="sm" open={this.props.endEpicDialogOpen} onClose={this.props.handleEndEpicDialogClose} scroll="body">
				<DialogTitle>
					{"Earnings of contributors"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						These are the earnings of each contributor who has successfully contributed to the epic. Confirm, if you are satisfied with it.
					</DialogContentText>
					<EarningsOfUsersInEpic userEpic={this.props.userEpic} ProjectId={this.props.ProjectId} EpicId={this.props.EpicId} api_url={this.props.api_url} analyticsObj={this.props.analyticsObj} history={this.props.history} buyInValue={this.props.buyInValue} epicValue={this.props.epicValue} />
					<DialogContentText className={classes.dialogNote}>
						Note: The winnings have been calculated after deducting 10% of total pool amount as platform fee.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.handleEndEpicDialogClose} analyticsObj={this.props.analyticsObj} color="secondary">
						Cancel
					</Button>
					<Button onClick={() => this.props.handleFinalDialogOpen()} analyticsObj={this.props.analyticsObj} color="secondary" variant="contained">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default withStyles(styles)(EndEpicDialog);