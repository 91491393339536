import React from 'react';
import { withStyles, Dialog, DialogTitle, TextField, Container, CircularProgress, Backdrop, Checkbox, FormControlLabel, FormControl, Typography, FormLabel } from '@material-ui/core';
import clsx from 'clsx';
import crypto from "crypto";
import axios from 'axios';
import { withSnackbar } from 'notistack';
import Button from '../../../mui_modules/components/Button';
import { cashfreeConfig } from '../../../util/config';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import { isMentee, isMentor, isProjectOwner, TransactionTypes } from '../../../util/uiUtil';

const styles = (theme) => ({
	overlay: {
		zIndex: theme.zIndex.modal + 1,
		backgroundColor: '#0009',
		color: '#fff',
	},
	dialogTitle: {
		textAlign: 'center'
	},
	container: {
		padding: '10px',
	},
	textField: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	buyInFieldBox: {
		display: 'flex',
		flexDirection: 'row',
	},
	currencyField: {
		marginRight: '10px',
	},
	amountField: {
		flexGrow: 1,
	},
	termsFormControl: {
		margin: theme.spacing(1)
	},
	termsFormControlLabel: {
		alignItems: 'flex-start',
		margin: theme.spacing(1),
	}
})
const isPhoneNumber = (phoneNumber) => {
	const phoneRegEx = /^([6-9]{1}[0-9]{9})$/;
	if (phoneNumber && phoneNumber.match(phoneRegEx)) return true;
	else return false;
};
class CashfreeBuyIn extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			uiLoading: false,
			proceedBtnDisabled: true,
			termsAgreed: Array(this.getTerms().length).fill(false),
			cashfreeCheckoutFormData: {
				customerEmail: JSON.parse(localStorage.getItem('UserDetails')).Email,
				customerPhone: JSON.parse(localStorage.getItem('UserDetails')).PhoneNumber,
			},
		};
		this.analyticsObj = {
			source: this.props.source,
			page: this.props.pageName,
		};
	}

	handleChange = (event) => {
		let element = event.target.name;
		let value = event.target.value;
		this.setState({
			cashfreeCheckoutFormData: {
				...this.state.cashfreeCheckoutFormData,
				[element]: value
			}
		})
	}

	handleTerms = (event) => {
		const element = event.target.name;
		const value = event.target.checked;
		const terms = this.state.termsAgreed.slice()
		terms[parseInt(element, 10)] = value
		this.setState({ proceedBtnDisabled: terms.includes(false), termsAgreed: terms })
	}

	getTerms = () => {
		const terms = [
			"I agree to refrain from disclosing, reproducing, summarising and/or distributing Confidential Information and confidential materials of the Disclosing Party except in connection with the Proposed Transaction.",
			"I agree to not use the Disclosing Party's name, trademarks, proprietary words or symbols or disclose under this Agreement in any publication, press release, marketing material, or otherwise without the prior written approval of the other.",
			"I agree to indemnify the Disclosing Party for all costs, expenses or damages that Disclosing Party incurs as a result of any violation of any provisions of this Agreement.",
		]
		return terms;
	};

	getNewTransactionId = async () => {
		const userId = localStorage.getItem('UserId');
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		const newTransactionDetails = {
			Currency: this.props.epic.Value.Currency,
			Amount: this.props.epic.Value.Amount,
			Details: {
				Epic: {
					Id: this.props.epic.Id,
					Name: this.props.epic.Name,
				},
				Project: {
					Id: this.props.epic.Project.Id,
					Name: this.props.epic.Project.Name,
				},
				Role: isProjectOwner(this.props.epic.Project) ? "Project Owner"
					: isMentor(this.props.epic) ? "Mentor"
						: isMentee(this.props.epic) ? "Mentee"
							: "Unknown"
			}
		}
		try {
			const response = await axios
				.post(this.props.api_url + '/users/' + userId + '/transactions/' + TransactionTypes.DEPOSIT, newTransactionDetails);
			return response.data.id;
		} catch (error) {
			console.error(error);
			if (error.response != null && error.response.status === 403) {
				removeAllSavedCookies();
				this.props.enqueueSnackbar("Session timeout: Please login again", { variant: "info" });
				this.props.history.push('/login');
			}
			if (error.response != null && error.response.data != null & error.response.data.error != null) {
				this.props.enqueueSnackbar(error.response.data.error, { variant: "error" });
				return error.response.data.id;
			} else {
				this.props.enqueueSnackbar("Something went wrong", { variant: "error" });
				return undefined;
			}
		}
	}

	openCashfree = () => {
		this.setState({ uiLoading: true, proceedBtnDisabled: true });
		let userId = localStorage.getItem('UserId');
		let epicId = this.props.epic.Id;
		let projectId = this.props.epic.Project.Id;
		if (parseFloat(this.props.Amount) === 0.0) {
			// if the buy-in amount for the user is zero, skip Cashfree
			const form = document.createElement('form');
			form.method = 'post';
			form.action = this.props.api_url + `/users/${userId}/projects/${projectId}/epics/${epicId}/zeroDeposit`;
			document.body.appendChild(form);
			form.submit();
			this.setState({ uiLoading: false });
		} else {
			this.getNewTransactionId()
				.then((txnId) => {
					console.assert(txnId !== undefined);
					if (txnId === undefined) {
						throw RangeError("Transaction ID cannot be undefined");
					}
					let cashfreeCheckoutFormData = {
						appId: cashfreeConfig.cfAppId,
						orderId: txnId,
						orderAmount: this.props.Amount,
						orderCurrency: this.props.Currency,
						orderNote: `[BuyIn] ${JSON.parse(localStorage.getItem('UserDetails')).Name} (user-id:${localStorage.getItem('UserId')}) paid ${this.props.Currency}${this.props.Amount}`,
						customerName: JSON.parse(localStorage.getItem('UserDetails')).Name,
						customerEmail: this.state.cashfreeCheckoutFormData.customerEmail,
						customerPhone: this.state.cashfreeCheckoutFormData.customerPhone,
						returnUrl: this.props.api_url + `/users/${userId}/projects/${projectId}/epics/${epicId}/transactions/${txnId}`,
						notifyURL: this.props.api_url + `/users/${userId}/projects/${projectId}/epics/${epicId}/transactions/${txnId}`,
						signature: ''
					}
					const form = document.createElement('form');
					form.method = 'post';
					form.action = cashfreeConfig.cashfreeAPI;
					let secretKey = cashfreeConfig.cfSecretKey;
					let sortedkeys = Object.keys(cashfreeCheckoutFormData);
					sortedkeys.sort();
					let signatureData = "";
					for (let i = 0; i < sortedkeys.length - 1; i++) {
						let key = sortedkeys[i];
						signatureData += key + cashfreeCheckoutFormData[key];
					}
					cashfreeCheckoutFormData.signature = crypto.createHmac('sha256', secretKey).update(signatureData).digest('base64');
					for (let i = 0; i < sortedkeys.length; i++) {
						let key = sortedkeys[i];
						const hiddenField = document.createElement('input');
						hiddenField.type = 'hidden';
						hiddenField.name = key;
						hiddenField.value = cashfreeCheckoutFormData[key];
						form.appendChild(hiddenField);
					}
					document.body.appendChild(form);
					form.submit();
					this.setState({ uiLoading: false });
				})
				.catch((err) => {
					console.error("Could not create a new transaction", err);
					this.setState({
						uiLoading: false,
						proceedBtnDisabled: false,
					})
				})
		}
	}

	render() {
		const { epic, classes } = this.props;

		return (
			<>
				<Backdrop className={classes.overlay} open={this.state.uiLoading}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<Dialog fullWidth maxWidth="xs" open={this.props.dialogOpen} onClose={this.props.closeCashfreeDialog} scroll="body">
					<DialogTitle className={classes.dialogTitle}>Contact Details</DialogTitle>
					<Container className={classes.container}>
						<TextField
							variant="outlined"
							required
							fullWidth
							id="customerEmail"
							label="Email"
							name="customerEmail"
							value={this.state.cashfreeCheckoutFormData.customerEmail}
							onChange={(event) => this.handleChange(event)}
							className={classes.textField}
						/>
						<TextField
							variant="outlined"
							required
							fullWidth
							id="customerPhone"
							label="Phone Number"
							name="customerPhone"
							error={!isPhoneNumber(this.state.cashfreeCheckoutFormData.customerPhone)}
							helperText={(isPhoneNumber(this.state.cashfreeCheckoutFormData.customerPhone) ? "" : "Customer Phone number must be valid")}
							value={this.state.cashfreeCheckoutFormData.customerPhone}
							onChange={(event) => this.handleChange(event)}
							className={classes.textField}
						/>
						<TextField
							variant="outlined"
							required
							fullWidth
							id="projectName"
							label="Project Name"
							name="projectName"
							value={epic.Project.Name}
							onChange={(event) => this.handleChange(event)}
							className={classes.textField}
						/>
						<TextField
							variant="outlined"
							required
							fullWidth
							id="epicName"
							label="Epic Name"
							name="epicName"
							value={epic.Name}
							onChange={(event) => this.handleChange(event)}
							className={classes.textField}
						/>
						<div className={classes.buyInFieldBox}>
							<TextField
								variant="outlined"
								required
								id="currency"
								label="Currency"
								name="currency"
								value={this.props.Currency}
								onChange={(event) => this.handleChange(event)}
								className={clsx(classes.textField, classes.currencyField)}
								disabled
							/>
							<TextField
								variant="outlined"
								required
								id="amount"
								label="Amount"
								name="amount"
								value={this.props.Amount}
								onChange={(event) => this.handleChange(event)}
								className={clsx(classes.textField, classes.amountField)}
								disabled
							/>
						</div>
						<FormControl className={classes.termsFormControl} required fullWidth>
							<FormLabel>Terms and Conditions</FormLabel>
							{this.getTerms().map((termsText, index) => {
								return <React.Fragment key={index}>
									<FormControlLabel
										className={classes.termsFormControlLabel}
										control={
											<Checkbox
												checked={this.state.termsAgreed[index]}
												onChange={(event) => this.handleTerms(event)}
												name={'' + index}
												required
											/>
										}
										label={<Typography variant="body2">{termsText}</Typography>}
									/>
								</React.Fragment>
							})}
						</FormControl>
						<Button
							variant="contained"
							color="secondary"
							onClick={this.openCashfree}
							analyticsObj={this.analyticsObj}
							fullWidth
							className={classes.textField}
							disabled={this.state.proceedBtnDisabled || !isPhoneNumber(this.state.cashfreeCheckoutFormData.customerPhone)}
						>
							Proceed
						</Button>
					</Container>
				</Dialog>
			</>
		)
	}
}

export default withStyles(styles)(withSnackbar(CashfreeBuyIn));