import React from 'react';
import { Grid, SvgIcon, Typography, withStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import Button from '../../../mui_modules/components/Button';


const styles = (theme) => ({
	textField: {
        marginTop: '10px',
        marginBottom: '10px',
    },
	largeIcon: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(4),
		height: 250,
		width: 250,
	},
	failureIcon: {
		color: "#d50000",
	},
	grid: {
		marginBottom: theme.spacing(8),
	},
	gridContainer: {
		marginTop: "75px",
	}

})

class PaymentFailure extends React.Component {
	render(){
		const { classes, analyticsObj, projectId, epicId } = this.props;
		return (
			<div>
				<Grid container spacing={2} className={classes.grid}>
					<Grid container item xs={12} justify="center">
						<SvgIcon className={classes.largeIcon}>
							<ErrorIcon className={classes.failureIcon}/>
						</SvgIcon>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h4" align="center" color='error'>
							TRANSACTION FAILED :'(
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" align="center">
							Some error occured during the transaction
						</Typography>
					</Grid>
					<Grid container item xs={12} justify="center" className={classes.gridContainer}>
						<Button variant="contained" paddedButton href={`/learn/projects/${projectId}/epics/${epicId}`} color="secondary" analyticsObj={analyticsObj}>
							Go to Epic
						</Button>
					</Grid>
				</Grid>
			</div>
		);
	}
}


export default withStyles(styles)(PaymentFailure);
