import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Container } from '@material-ui/core';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@material-ui/lab';
import Typography from '../components/Typography';

import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import FlightTakeoffOutlinedIcon from '@material-ui/icons/FlightTakeoffOutlined';
import FlightLandOutlinedIcon from '@material-ui/icons/FlightLandOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';

const styles = (theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.secondary,
	},
	container: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(10),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	title: {
		marginBottom: theme.spacing(3),
	},
	eventText: {
		// whiteSpace: 'pre',
		whiteSpace: 'pre-wrap',
	},
})

function ProgramTimeline(props) {
	const { classes } = props;

	const timeline = [
		{
			milestone: true,
			icon: (fontSize) => <PowerSettingsNewOutlinedIcon fontSize={fontSize} />,
			text: "Scope work for the project"
		},
		{
			milestone: false,
			icon: (fontSize) => <ListOutlinedIcon fontSize={fontSize} />,
			text: "Divide the task into milestones"
		},
		{
			milestone: false,
			icon: (fontSize) => <AttachMoneyOutlinedIcon fontSize={fontSize} />,
			text: "Assign project and buy-in values"
		},
		{
			milestone: false,
			icon: (fontSize) => <PersonAddOutlinedIcon fontSize={fontSize} />,
			text: "Invite candidates and mentors"
		},
		{
			milestone: true,
			icon: (fontSize) => <FlightTakeoffOutlinedIcon fontSize={fontSize} />,
			text: "Project kick-off"
		},
		{
			milestone: false,
			icon: (fontSize) => <EventOutlinedIcon fontSize={fontSize} />,
			text: "Daily reviews with candidates"
		},
		{
			milestone: false,
			icon: (fontSize) => <FlightLandOutlinedIcon fontSize={fontSize} />,
			text: "Project ends"
		},
		{
			milestone: false,
			icon: (fontSize) => <AttachMoneyOutlinedIcon fontSize={fontSize} />,
			text: "Candidate settlements"
		},
	]

	return (
		<section className={ classes.root }>
			<Container className={ classes.container }>
				{ (props.titleText.trim() && props.titleText.trim().length !== 0) ?
					<Typography variant="h4" marked="center" className={classes.title} component="h2">
						{props.titleText}
					</Typography>
					: null
				}
				{/* <Timeline align="alternate"> */}
				<Timeline align="left">
					{ timeline.map((event, index) => (
						<TimelineItem key={index}>
							{/* <TimelineOppositeContent>
								<Typography variant="body2" color="textSecondary">
									{event.date}
								</Typography>
							</TimelineOppositeContent> */}
							<TimelineSeparator>
								{ (event.milestone) ? 
									<TimelineDot color="secondary" variant="default">
										{event.icon("default")}
									</TimelineDot> 
								:
									<TimelineDot variant="outlined">
										{event.icon("small")}
									</TimelineDot> 
								}
							{ (index !== timeline.length - 1) ? <TimelineConnector /> : null }
							</TimelineSeparator>
							<TimelineContent>
								<Typography className={ classes.eventText }>
									{ event.text }
								</Typography>
							</TimelineContent>
						</TimelineItem>
					))}
				</Timeline>
			</Container>
		</section>
	);
}

ProgramTimeline.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProgramTimeline);