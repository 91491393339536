import React from 'react';
import { Chip, Avatar } from "@material-ui/core"
import Link from '../mui_modules/components/Link';
import { TurnedIn, BugReport, MenuBookTwoTone, ArrowUpward, Help, CallMade, CallReceived, FormatIndentIncrease, FormatIndentDecrease, Close } from '@material-ui/icons';
import { AccentColors } from '../resources/Colors';

export const storyChipRender = (value) => {
	return (
		<Chip
			avatar={<Avatar alt={value.chip.Id} src={value.chip.PhotoURL} />}
			label={value.chip.Name}
			key={value.chip.Id}
		/>
	)
}

export const userChipRender = (value, analyticsObj, key = "", clickable = true, classes) => {
	return (
		<Chip
			key={key}
			avatar={<Avatar alt={value.Id} src={value.PhotoURL} />}
			label={value.Name}
			clickable={clickable}
			component={Link}
			className={classes}
			href={`/learn/users/${value.Id}`}
			underline='none'
			analyticsObj={analyticsObj}
		/>
	)
}

export const isProjectOwner = (project) => {
	return (project.Owner.Id === localStorage.getItem('UserId')) ? true : false;
};

export const isMentor = (epic) => {
	return (epic.Mentors.map((m) => { return m.Id; }).indexOf(localStorage.getItem('UserId')) > -1) ? true : false;
};

export const isMentee = (epic) => {
	return (epic.Mentees.Curr.map((m) => { return m.Id; }).indexOf(localStorage.getItem('UserId')) > -1) ? true : false;
};

/**
 *
 * @param {*} array: An array of objects containing the key.
 * @param {*} key: A key which is used for indexing.
 * @returns An object that contains the elements of the array map to their key.
 */
export const convertArrayToObject = (array, key) =>
	array.reduce(
		(acc, item) => ({
			...acc,
			[item[key]]: item
		}),
		{}
	);

export const reorderList = (list, currentIndex, targetIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(currentIndex, 1);
	result.splice(targetIndex, 0, removed);
	return result;
};

export const storyTypeToIcon = (type) => {
	switch (type) {
		case "Bug": return (<BugReport style={{ color: AccentColors.IndicatorRed }} />);
		case "Feature": return (<TurnedIn style={{ color: AccentColors.PositiveGreen }} />);
		case "Enhancement": return (<ArrowUpward style={{ color: AccentColors.ProgressiveYellow }} />);
		case "Learning": return (<MenuBookTwoTone style={{ color: AccentColors.ActionBlue }} />);
		default: return (<Help style={{ color: AccentColors.UnknownGray }} />)
	}
}
export const tradeMarkedAlokit = () => {
	return (
		"Alokit\u2122"
	)
};

export const EpicStates = Object.freeze({
	PENDING: "Pending",
	OPEN: "Open",
	ONGOING: "Ongoing",
	FINISHED: "Finished",
	DORMANT: "Dormant"
})

/*
* Method to verify if a value exists in a object
*/
export const ifValueExists = (obj, value) => {
	return Object.keys(obj).some(k => (obj[k] === value));
};

export const getCurrencySymbol = (currency) => {
	switch (currency) {
		case "INR": return "\u20B9";
		case "USD": return "US\u0024 ";
		case "EUR": return "\u20AC";
		case "GBP": return "\u00A3";
		case "CAD": return "CA\u0024 ";
		case "AUD": return "AU\u0024 ";
		case "JPY": return "JP\u00A5 ";
		case "CNY": return "CN\u00A5 ";
		case "HKD": return "HK\u0024 ";
		case "SGD": return "SG\u0024 ";
		default: return `${currency} `;
	}
}

/**
 * TransactionTypes lists all the different types of transactions for a user's wallet
 */
export const TransactionTypes = Object.freeze({
	DEPOSIT: "Deposit",
	WITHDRAWAL: "Withdrawal",
	BUY_IN: "BuyIn",
	WINNING: "Winning",
})

/**
 * 
 * @param {*} txnType is the type of wallet transaction: one of the TransactionTypes
 * @param {*} txnStatus is the status of wallet transaction
 * @returns The icon corresponding to the transaction type
 */
export const txnTypeToIcon = (txnType, txnStatus) => {
	if (txnStatus === "SUCCESS") {
		switch (txnType) {
			case TransactionTypes.DEPOSIT: return (<CallReceived style={{ color: AccentColors.PositiveGreen }} />);
			case TransactionTypes.WITHDRAWAL: return (<CallMade style={{ color: AccentColors.UnknownGray }} />);
			case TransactionTypes.BUY_IN: return (<FormatIndentIncrease style={{ color: AccentColors.UnknownGray }} />);
			case TransactionTypes.WINNING: return (<FormatIndentDecrease style={{ color: AccentColors.PositiveGreen }} />);
			default: return (<Help style={{ color: AccentColors.UnknownGray }} />)
		}
	}
	else if ((txnStatus === "PENDING") || (txnStatus === "FLAGGED")) {
		switch (txnType) {
			case TransactionTypes.DEPOSIT: return (<CallReceived style={{ color: AccentColors.ProgressiveYellow }} />);
			case TransactionTypes.WITHDRAWAL: return (<CallMade style={{ color: AccentColors.ProgressiveYellow }} />);
			case TransactionTypes.BUY_IN: return (<FormatIndentIncrease style={{ color: AccentColors.ProgressiveYellow }} />);
			case TransactionTypes.WINNING: return (<FormatIndentDecrease style={{ color: AccentColors.ProgressiveYellow }} />);
			default: return (<Help style={{ color: AccentColors.ProgressiveYellow }} />)
		}
	}
	else {
		return (<Close style={{ color: AccentColors.IndicatorRed }} />)
	}
}