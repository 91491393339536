import React, { Component } from 'react';
import withRoot from '../mui_modules/withRoot';

import { CssBaseline }  from "@material-ui/core"
import AppAppBar from '../mui_modules/views/AppAppBar';
import AppFooter from '../mui_modules/views/AppFooter';
import MenteeEligibility from '../mui_modules/views/MenteeEligibility';
import MenteeWelcomeHero from '../mui_modules/views/MenteeWelcomeHero';
import MenteeForm from '../mui_modules/views/MenteeFormView';

import { gAnalytics } from "../util/analytics";

class Apply extends Component {
	constructor(props) {
		super(props);
		gAnalytics.logEvent('screen_view', {
			firebase_screen: 'apply',
		});
	}

	render() {
		return (
			<React.Fragment>
				<CssBaseline/>
				<AppAppBar applyButton="false" pageName={ "Apply" } />
				<MenteeWelcomeHero pageName={ "Apply" } />
				<MenteeEligibility pageName={ "Apply" } />
				<MenteeForm pageName={ "Apply" } />
				<AppFooter pageName={ "Apply" } />
			</React.Fragment>
		)
	}
}

export default withRoot(Apply);