import React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { CircularProgress, CssBaseline, withStyles } from '@material-ui/core';
import PaymentSuccess from './views/PaymentSuccess';
import PaymentFailure from './views/PaymentFailure';
import PaymentInitiated from './views/PaymentInitiated';
import LearnAppBar from './views/LearnAppBar';
import AppFooter from '../../mui_modules/views/AppFooter';
import { removeAllSavedCookies } from '../../util/firebaseAuth';

const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		top: '35%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
})

class TransactionSummary extends React.Component {
	constructor(props) {
		super(props);

		this.transaction = new Map();
		this.state = {
			uiLoading: true,
		}
		this.analyticsObj = {
			source: "TransactionSummary",
			page: "TransactionSummary",
		};
	}

	componentDidMount() {
		dayjs.extend(relativeTime);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		const userId = localStorage.getItem('UserId');
		const transactionId = this.props.match.params.transactionId;
		axios
			.get(this.props.api_url + `/users/${userId}/transactions/${transactionId}`)
			.then((response) => {
				this.transaction = response.data;
				this.transaction.txTime = this.transaction.txTime ? new Date(this.transaction.txTime._seconds * 1000) : new Date(this.transaction.CreatedAt._seconds * 1000);
				this.setState({ uiLoading: false });
			})
			// TODO: Add history
			.catch((error) => {
				console.log(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				this.setState({ errorMsg: 'Error in retrieving the data' });
			});
	}

	render() {
		const { classes, history } = this.props;
		const projectId = this.props.match.params.projectId;
		const epicId = this.props.match.params.epicId;
		if (this.state.uiLoading === true) {
			return (
				<div className={classes.loadingRoot}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</div>
			);
		} else {
			return (
				<div>
					<CssBaseline />
					<LearnAppBar pageName={"TransactionSummary"} history={history} />
					{(this.transaction.txStatus === "SUCCESS") ?
						<PaymentSuccess analyticsObj={this.analyticsObj} transaction={this.transaction} projectId={projectId} epicId={epicId} api_url={this.props.api_url} />
						: (this.transaction.txStatus === "PENDING") ? <PaymentInitiated analyticsObj={this.analyticsObj} transaction={this.transaction} projectId={projectId} epicId={epicId} api_url={this.props.api_url} /> 
							: <PaymentFailure analyticsObj={this.analyticsObj} projectId={projectId} epicId={epicId} />}
					<AppFooter pageName="PaymentSuccess" />
				</div>
			);
		}
	}
}

export default withStyles(styles)(TransactionSummary);
