import React from 'react';
import axios from 'axios';
import { authMiddleWare } from '../../../util/auth';
import { EpicStates } from '../../../util/uiUtil';
import { withStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import EpicCard from './EpicCard';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import { withSnackbar } from 'notistack';


const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	card: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	nested: {
		paddingLeft: theme.spacing(3),
	},
})

class ActiveEpicList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			uiLoading: true,
			user_epics: null,
		}
		this.epics = null;

		this.analyticsObj = {
			source: "ActiveEpicList",
			page: this.props.pageName,
		};
	}

	componentDidMount() {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get(this.props.api_url + `/users/${this.props.userId}/user_epics_with_details`)
			.then((response) => {
				const userEpics = response.data.filter((epic) => [EpicStates.OPEN, EpicStates.ONGOING].includes(epic.State)).sort((epic1, epic2) => (epic2.LastModifiedAt._seconds - epic1.LastModifiedAt._seconds))
				this.setState({
					uiLoading: false,
					user_epics: userEpics
				});
			})
			.catch((err) => {
				console.error(err);
				if (err.response != null && err.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login')
				}
				if (err.response != null && err.response.data != null && err.response.data.message != null) {
					for (let msg of err.response.data.message) {
						this.props.enqueueSnackbar(msg, { variant: "error" })
					}
				} else {
					this.props.enqueueSnackbar(`Error in retrieving the epic data`, { variant: "error" });
				}
			});
	}

	render() {
		const { classes } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<div className={classes.loadingRoot}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgress} />}
				</div>
			);
		} else {
			return (
				<section className={classes.root}>
					{(this.state.user_epics) ? this.state.user_epics.map((epic) => (
						<div className={classes.card} key={epic.EpicId}>
							<EpicCard
								epic={epic}
								api_url={this.props.api_url}
								history={this.props.history}
								project={epic.Project}
								analyticsObj={this.analyticsObj}
							/>
						</div>
					)) : null}
				</section>
			)
		}
	}
}

export default withStyles(styles)(withSnackbar(ActiveEpicList));