import React, { Component } from 'react';
import withRoot from '../mui_modules/withRoot';

import { CssBaseline }  from "@material-ui/core"
import AppAppBar from '../mui_modules/views/AppAppBar';
import AppFooter from '../mui_modules/views/AppFooter';
import MentorEligibility from '../mui_modules/views/MentorEligibility';
import MentorResponsibilities from '../mui_modules/views/MentorResponsibilities';
import MentorWelcomeHero from '../mui_modules/views/MentorWelcomeHero';

import { gAnalytics } from "../util/analytics";
import MentorForm from '../mui_modules/views/MentorFormView';

class MentorApply extends Component {
	constructor(props) {
		super(props);
		gAnalytics.logEvent('screen_view', {
			firebase_screen: 'mentorApply',
		});
	}

	render() {
		return (
			<React.Fragment>
				<CssBaseline/>
				<AppAppBar applyButton="false" pageName={ "MentorApply" } />
				<MentorWelcomeHero pageName={ "MentorApply" } />
				<MentorEligibility pageName={ "MentorApply" } />
				<MentorResponsibilities titleText="Mentor Responsibilities" pageName={ "MentorApply" } />
				<MentorForm pageName={ "MentorApply" } />
				<AppFooter pageName={ "MentorApply" } />
			</React.Fragment>
		)
	}
}

export default withRoot(MentorApply);