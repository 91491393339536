import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '../components/Button';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Link from '../components/Link';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import facebookIcon from '../../resources/images/facebook.png';
import linkedinIcon from '../../resources/images/linkedin.png';
import instagramIcon from '../../resources/images/instagram.svg';
import SDG4 from '../../resources/images/SDG4.png';
import SDG8 from '../../resources/images/SDG8.png';
import { tradeMarkedAlokit } from '../../util/uiUtil';

const styles = (theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.paper,
	},
	container: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(8),
		display: 'flex',
	},
	iconsWrapper: {
		height: 120,
	},
	icons: {
		display: 'flex',
	},
	icon: {
		width: 48,
		height: 48,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.palette.background.default,
		marginRight: theme.spacing(1),
		'&:hover': {
			backgroundColor: theme.palette.grey[100],
		},

	},
	list: {
		margin: 0,
		listStyle: 'none',
		padding: 0,
	},
	listItem: {
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	language: {
		marginTop: theme.spacing(1),
		width: 150,
	},
	sdgTooltip: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.secondary,
		wordWrap: 'break-word',
		boxShadow: theme.shadows[1],
		fontSize: theme.typography.fontSize,
		fontWeight: theme.typography.fontWeightLight,
	},
	sdgButton: {
		padding: 0,
	},

});

// const LANGUAGES = [
// 	{
// 		code: 'en-US',
// 		name: 'English',
// 	},
// ];

function AppFooter(props) {
	const { classes, pageName } = props;

	const analyticsObj = {
		source: "AppFooter",
		page: pageName,
	};

	const Copyright = () => {
		return (
			<React.Fragment>
				{'© '}
				<Link color="inherit" href="https://alokit.in/" analyticsObj={analyticsObj} >
					{tradeMarkedAlokit()}
				</Link>{' '}
				{new Date().getFullYear()}
			</React.Fragment>
		);
	}

	const causes = [
		{
			"title": "Sustainable Development Goal 4: Ensure inclusive and equitable quality education and promote lifelong learning oppurtunities for all.",
			"href": "https://www.undp.org/content/undp/en/home/sustainable-development-goals/goal-4-quality-education.html",
			"src": SDG4,
			"alt": "SDG4",
		},
		{
			"title": "Sustainable Development Goal 8: Promote sustained,inclusive and sustainable economic growth,full and productive emplolyment and decent work for all.",
			"href": "https://www.undp.org/content/undp/en/home/sustainable-development-goals/goal-8-decent-work-and-economic-growth.html",
			"src": SDG8,
			"alt": "SDG8",
		},

	]

	return (
		<Typography component="footer" className={classes.root}>
			<Container className={classes.container}>
				<Grid container spacing={5}>
					<Grid item xs={4} sm={4} md={3}>
						<Grid
							container
							direction="column"
							justify="flex-end"
							className={classes.iconsWrapper}
							spacing={2}
						>
							<Grid item className={classes.icons}>
								<Link
									href="https://www.linkedin.com/company/alokit-innovations"
									target="_blank"
									className={classes.icon}
									analyticsObj={analyticsObj}
								>
									<img src={linkedinIcon} alt="LinkedIn" width="32" height="32" />
								</Link>
								<Link
									href="https://www.facebook.com/Alokit-Innovations-109094627516849"
									target="_blank"
									className={classes.icon}
									analyticsObj={analyticsObj}
								>
									<img src={facebookIcon} alt="Facebook" width="32" height="32" />
								</Link>
								<Link
									href="https://www.instagram.com/alokit.in/"
									target="_blank"
									className={classes.icon}
									analyticsObj={analyticsObj}
								>
									<img src={instagramIcon} alt="Instagram" width="32" height="32" />
								</Link>
							</Grid>
							<Grid item>
								<Copyright />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={4} sm={4} md={3}>
						<Typography variant="h6" marked="left" gutterBottom>
							Legal
						</Typography>
						<ul className={classes.list}>
							<li className={classes.listItem}>
								<Link href="/terms" analyticsObj={analyticsObj} >Terms and Conditions</Link>
							</li>
							<li className={classes.listItem}>
								<Link href="/privacy" analyticsObj={analyticsObj} >Privacy</Link>
							</li>
							<li className={classes.listItem}>
								<Link href="/returns" analyticsObj={analyticsObj} >Returns and Refunds</Link>
							</li>
							<li className={classes.listItem}>
								<Link href="/contactUs" analyticsObj={analyticsObj} >Contact Us</Link>
							</li>
						</ul>
					</Grid>
					<Grid item xs={false} sm={false} md={3}></Grid>
					<Grid item xs={4} sm={4} md={3}>
						<Typography variant="h6" marked="left" gutterBottom>
							Causes we support
						</Typography>
						<GridList cols={3.4} cellHeight='auto'>
							{causes.map((cause) => (
								<Tooltip
									title={cause.title}
									interactive
									arrow
									classes={{ tooltip: classes.sdgTooltip }}
									key={cause.alt}
								>
									<GridListTile rows={0.7}>
										<Button
											target="_blank"
											className={classes.sdgButton}
											href={cause.href}
											analyticsObj={analyticsObj}
										>
											<img src={cause.src} alt={cause.alt} width="100%" />
										</Button>
									</GridListTile>
								</Tooltip>
							))}
						</GridList>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="caption">
							{'Icons made by '}
							<Link href="https://www.freepik.com" rel="sponsored" title="Freepik" analyticsObj={analyticsObj} >
								Freepik
							</Link>
							{' from '}
							<Link href="https://www.flaticon.com" rel="sponsored" title="Flaticon" analyticsObj={analyticsObj} >
								www.flaticon.com
							</Link>
							{' is licensed by '}
							<Link
								href="https://creativecommons.org/licenses/by/3.0/"
								title="Creative Commons BY 3.0"
								target="_blank"
								rel="noopener noreferrer"
								analyticsObj={analyticsObj}
							>
								CC 3.0 BY
							</Link>
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Typography>
	);
}

export default withStyles(styles)(AppFooter);