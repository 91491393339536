import React, { useEffect } from 'react';
import axios from 'axios';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, FormHelperText, TextField, Dialog, DialogTitle, DialogContent, Select, MenuItem, InputLabel } from '@material-ui/core';
import { Button } from '../../../mui_modules/components';

const styles = () => ({
	addBeneficiary: {
		textTransform: 'capitalize',
		width: '100%',
	},
});


function WithdrawalDialog(props) {
	const { enqueueSnackbar, analyticsObj, history, classes } = props;
	const benGetURL = `${props.api_url}/projects/${props.projectId}/epics/${props.epicId}/beneficiaries/amount`
	const benPostURL = `${props.api_url}/users/${props.userId}/beneficiary`;
	const transferURL = `${props.api_url}/projects/${props.projectId}/epics/${props.epicId}/transfer`
	const [beneficiaries, setBeneficiaries] = React.useState([]);
	const [openBeneficiaryDialog, setOpenBeneficiaryDialog] = React.useState(false);
	const [benName, setBenName] = React.useState("");
	const [phoneNumber, setPhoneNumber] = React.useState("");
	const [formError, setFormError] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [address, setAddress] = React.useState("");
	const [selectedBenId, setSelectedBenId] = React.useState("");
	const [benType, setBenType] = React.useState("");
	const [bankAccount, setBankAccount] = React.useState("");
	const [ifsc, setIfsc] = React.useState("");
	const [vpa, setVpa] = React.useState("");
	const [amount, setAmount] = React.useState({});
	const [proceedButtonDisabled, setProceedButtonDisabled] = React.useState(false);
	const [proceedTransferDisabled, setProceedTransferDisabled] = React.useState(false);
	const BeneficiaryType = Object.freeze({
		NETBANKING: "Internet Banking",
		UPI: "UPI",
	});

	useEffect(() => {
		const getBeneficiaries = () => {
			axios.get(benGetURL)
				.then(response => {
					if (response.data.beneficiaries) {
						setBeneficiaries(response.data.beneficiaries);
						setAmount(response.data.amount[0]);
					}
					else {
						if (props.openDialog) {
							enqueueSnackbar("Could not fetch beneficiaries", { variant: "warning" });
							props.handleDialogClose();
						}
					}
				})
				.catch(error => {
					props.handleDialogClose();
					if (error.response != null && error.response.status === 403) {
						removeAllSavedCookies();
						enqueueSnackbar("Session timeout: Please login again", { variant: "info" });
						history.push('/login');
					}
					if (error.response != null && error.response.data != null & error.response.data.error != null) {
						enqueueSnackbar(error.response.data.error, { variant: "error" });
					} else {
						if (props.openDialog) {
							enqueueSnackbar("Could not fetch beneficiaries", { variant: "error" });
						}
					}
				});
		}
		getBeneficiaries();
	}, [benGetURL, enqueueSnackbar, history, props]);

	const closeBeneficiaryDialog = () => {
		setOpenBeneficiaryDialog(false)
	};

	const handleAddBeneficiary = () => {
		props.handleDialogClose();
		setOpenBeneficiaryDialog(true)
	};

	const handleProceed = () => {
		setProceedTransferDisabled(true)
		// check if the user has selected a beneficiary
		if (!selectedBenId) {
			enqueueSnackbar("Please select a beneficiary", { variant: "info" });
			setProceedTransferDisabled(false);
			return;
		}
		const reqBody = {
			Id: selectedBenId,
			Amount: '' + amount.Amount,
			Currency: amount.Currency,
		}
		axios.post(transferURL, reqBody)
			.then((response) => {
				if (amount.Amount === 0) {
					enqueueSnackbar("Epic marked as complete!", { variant: "success" });
					props.handleDialogClose();
					window.location.reload();
					return;
				}
				enqueueSnackbar("Requested Transfer Successfully!", { variant: "success" });
				props.handleDialogClose();
				const transactionPath = `/learn/users/${props.userId}/projects/${props.projectId}/epics/${props.epicId}/transactions/${response.data.TransactionId}`;
				history.push(transactionPath);
			})
			.catch((error) => {
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					enqueueSnackbar("Session timeout: Please login again", { variant: "info" });
					history.push('/login');
				}
				if (error.response != null && error.response.data != null && error.response.data.message != null) {
					enqueueSnackbar(error.response.data.message, { variant: "error" });
					if (error.response.data.TransactionId) {
						const transactionPath = `/learn/users/${props.userId}/projects/${props.projectId}/epics/${props.epicId}/transactions/${error.response.data.TransactionId}`;
						history.push(transactionPath);
					}
					else {
						setProceedTransferDisabled(false);
						props.handleDialogClose();
					}
				} else {
					enqueueSnackbar("Could not withdraw amount", { variant: "error" });
					setProceedTransferDisabled(false);
					props.handleDialogClose();
				}
			})
	};

	const handleChange = (event) => {
		setSelectedBenId(event.target.value)
	};

	const handleSubmitBeneficiary = () => {
		setProceedButtonDisabled(true);
		setFormError("");
		let reqBody = {
			Name: benName,
			Phone: phoneNumber,
			Email: email,
			Address: address,
		};
		switch (benType) {
			case BeneficiaryType.NETBANKING:
				reqBody["BankAccount"] = bankAccount;
				reqBody["IFSC"] = ifsc;
				break;
			case BeneficiaryType.UPI:
				reqBody["VPA"] = vpa;
				break;

			default:
				break;
		};
		axios.post(benPostURL, reqBody)
			.then((response) => {
				setOpenBeneficiaryDialog(false);
				enqueueSnackbar("Added Beneficiary Successfully", { variant: "success" });
			})
			.catch(error => {
				setProceedButtonDisabled(false);
				if (error.response != null && error.response.status === 403) {
					setOpenBeneficiaryDialog(false);
					removeAllSavedCookies();
					enqueueSnackbar("Session timeout: Please login again", { variant: "info" });
					history.push('/login');
				}
				if (error.response != null && error.response.data != null && error.response.data.message != null) {
					setFormError(error.response.data.message);
				} else {
					enqueueSnackbar("Could not add beneficiary", { variant: "error" });
					setOpenBeneficiaryDialog(false);
				}
			});
	};

	if (amount.Amount === 0) {
		return (
			<Dialog fullWidth maxWidth="md" open={props.openDialog} onClose={props.handleDialogClose} scroll='body'>
				<DialogTitle>Mark as completed</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							No beneficiaries required for zero withdrawal amount.
						</Grid>
						<Grid item xs={12}>
							<Button
								variant="outlined"
								color="primary"
								fullWidth
								onClick={handleProceed}
								analyticsObj={analyticsObj}
								disabled={proceedTransferDisabled}
							>
								Proceed
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		);
	}
	return (
		<>
			<Dialog fullWidth maxWidth="md" open={props.openDialog} onClose={props.handleDialogClose} scroll="body">
				<DialogTitle>
					{"Withdraw"}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={9}>
							<InputLabel id="select-beneficiary-label" required>Select Beneficiary</InputLabel>
							<Select
								fullWidth
								labelId="select-beneficiary-label"
								id="select-beneficiary"
								value={selectedBenId}
								onChange={handleChange}
							>
								{beneficiaries.map(beneficiary => <MenuItem value={beneficiary.Id} key={beneficiary.Id}>{beneficiary.Description}</MenuItem>)}
								<Button
									variant='text'
									color="primary"
									fullWidth
									onClick={handleAddBeneficiary}
									analyticsObj={analyticsObj}
									className={classes.addBeneficiary}
								>
									{/* <MenuItem value='add-beneficiary' > */}
									+ Add Beneficiary
									{/* </MenuItem> */}
								</Button>
							</Select>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField disabled id="amount-disabled" label="Amount" defaultValue={`${amount.Amount} ${amount.Currency}`} />
						</Grid>
						<Grid item xs={12}>
							<Button
								variant="outlined"
								color="primary"
								fullWidth
								onClick={handleProceed}
								analyticsObj={analyticsObj}
								disabled={proceedTransferDisabled}
							>
								Proceed
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			<Dialog fullWidth maxWidth="md"
				open={openBeneficiaryDialog}
				onClose={closeBeneficiaryDialog}
				scroll="body">
				<DialogTitle>
					{"Add New Beneficiary"}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="beneficiaryName"
								label="Beneficiary Name"
								helperText={"Beneficiary name, only alphabets and white space (100 character limit)"}
								onChange={(event) => setBenName(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<InputLabel id="select-beneficiarytype-label" required>Beneficiary Type</InputLabel>
							<Select
								fullWidth
								labelId="select-beneficiarytype-label"
								id="select-beneficiarytype"
								value={benType}
								onChange={(event) => setBenType(event.target.value)}
							>
								<MenuItem value={BeneficiaryType.NETBANKING}>{BeneficiaryType.NETBANKING}</MenuItem>
								<MenuItem value={BeneficiaryType.UPI}>{BeneficiaryType.UPI}</MenuItem>
							</Select>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								required
								id="phoneNumber"
								label="Phone Number"
								name="phoneNumber"
								pattern="[6-9]{1}[0-9]{9}"
								helperText={"Beneficiaries phone number, phone number registered in India (only digits, 8 - 12 characters after stripping +91)"}
								onChange={(event) => setPhoneNumber(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								required
								id="email"
								label="Email"
								helperText={"Beneficiaries email, string in email Id format (Ex: johndoe_1@cashfree.com) - should contain @ and dot (.) - (200 character limit)"}
								onChange={(event) => setEmail(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								multiline
								rows={2}
								id="Address"
								label="Address"
								helperText={"Beneficiaries address, alphanumeric and space allowed (but script, HTML tags gets sanitized or removed) (150 character limit)"}
								onChange={(event) => setAddress(event.target.value)}
							/>
						</Grid>
						{benType === BeneficiaryType.NETBANKING && <Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								id="Account"
								label="Bank Account ID"
								helperText={"Beneficiary bank account (9 - 18 alphanumeric character limit)"}
								onChange={(event) => setBankAccount(event.target.value)}
							/>
						</Grid>}
						{benType === BeneficiaryType.NETBANKING && <Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								id="ifsc"
								label="IFSC Code"
								helperText={"Accounts IFSC (standard IFSC format) - length 11, first four bank IFSC and 5th digit 0"}
								onChange={(event) => setIfsc(event.target.value)}
							/>
						</Grid>}
						{benType === BeneficiaryType.UPI && <Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								id="UPI"
								label="UPI VPA"
								helperText={"Beneficiary VPA, alphanumeric, dot (.), hyphen (-), at sign (@), and underscore () allowed (100 character limit). Note: underscore () and dot (.) gets accepted before and after at sign (@), but hyphen (-) get only accepted before at sign (@)"}
								onChange={(event) => setVpa(event.target.value)}
							/>
						</Grid>}
						<Grid item xs={12}>
							{formError !== "" && <FormHelperText id="form-error-text" error={true}>
								{formError}
							</FormHelperText>}
							<Button
								fullWidth
								variant="contained"
								color="primary"
								onClick={handleSubmitBeneficiary}
								analyticsObj={analyticsObj}
								disabled={proceedButtonDisabled}
							>
								Proceed
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

WithdrawalDialog.propTypes = {
	api_url: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	analyticsObj: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	openDialog: PropTypes.bool.isRequired,
};

export default withStyles(styles)(WithdrawalDialog);