// config.js

export const firebaseConfig = {
    apiKey: "AIzaSyCpXW3oWlX280Ui2p_ZzgLmrXfCiMbxh5Q",
    authDomain: "alokit.in",
    databaseURL: "https://alokit-mentorship.firebaseio.com",
    projectId: "alokit-mentorship",
    storageBucket: "alokit-mentorship.appspot.com",
    messagingSenderId: "304760088586",
    appId: "1:304760088586:web:80ae3a56c9efc51ec157f3",
    measurementId: "G-7ZCC5PQ771"
};

export const cashfreeConfig = {
    cfAppId: "95839fc5564a1951ec7e73aa093859",
    cfSecretKey: "e39e74165c130ebce22c33180c84c46c70da637",
    cashfreeAPI: 'https://www.cashfree.com/checkout/post/submit'
}