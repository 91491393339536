import React, { Suspense } from 'react';
import axios from 'axios';

import { CssBaseline, withStyles, Grid, Container, Divider, Tabs, Tab } from '@material-ui/core';
import LearnAppBar from './views/LearnAppBar';
import AppFooter from '../../mui_modules/views/AppFooter';
import CircularProgress from '@material-ui/core/CircularProgress';
import { gAnalytics } from '../../util/analytics';
import { authMiddleWare } from '../../util/auth';
import Typography from '../../mui_modules/components/Typography';
import EpicProfileDrawer from './views/EpicProfileDrawer';
import ProfileMetrics from './views/ProfileMetrics';
import Link from '../../mui_modules/components/Link';
import { removeAllSavedCookies } from '../../util/firebaseAuth';
import { withSnackbar } from 'notistack';
import { Alert, AlertTitle, TabPanel, TabContext } from '@material-ui/lab';
import StoryViews from './views/StoryViews';
import EpicStateIndicator from '../../mui_modules/components/EpicStateIndicator';
import { EpicStates, isMentee } from '../../util/uiUtil';
import MeetingNotes from './views/MeetingNotes';

const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		top: '35%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	container: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	name: {
		marginTop: theme.spacing(2),
		width: '100%',
	},
	githubLink: {
		display: 'flex',
		'& > *': {
			margin: theme.spacing(0.5),
		},
		marginBottom: theme.spacing(2),
	},
	githubIcon: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	tabPanel: {
		width: '100%',
		padding: 0,
	},
	epicNameGridItems: {
		display: 'flex',
		alignContent: 'center',
		flexDirection: 'row'
	},
	sectionEarnings: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
	},

});

class EpicPage extends React.Component {
	constructor(props) {
		super(props);

		this.projectId = this.props.match.params.projectId;
		this.epicId = this.props.match.params.epicId;
		this.epic = null;
		this.stories = null;
		this.userEpic = null;

		if (!sessionStorage.getItem('TabValue') || !sessionStorage.getItem('TabValue').startsWith(this.epicId)) {
			sessionStorage.setItem('TabValue', this.epicId + "overview");
		}
		this.state = {
			uiLoading: true,
			userEpicLoading: true,
			epicLoading: true,
			tabValue: sessionStorage.getItem('TabValue').substring(this.epicId.length),
		};

		gAnalytics.logEvent('screen_view', {
			firebase_screen: 'EpicPage',
		});
		this.analyticsObj = {
			source: "EpicPage",
			page: "EpicPage",
		};

		// TODO: StoryList and EpicProfileDrawer, both need allUsers list. Fetch user list here and pass it.
	}

	componentDidMount = () => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		Promise.allSettled([
			axios.get(this.props.api_url + '/projects/' + this.projectId + "/epics/" + this.epicId),
			axios.get(this.props.api_url + `/users/${localStorage.getItem('UserId')}/user_epics/${this.epicId}`)
		]).then(([response1, response2]) => {
			if ((response1.status === "fulfilled") && (response2.status === "fulfilled")) {
				this.epic = response1.value.data;
				this.epic.Id = this.epicId;
				this.userEpic = response2.value.data;
				this.setState({
					uiLoading: false
				});
			} else if ((response1.status === "fulfilled") && (response2.status === "rejected")) {
				if (response2.reason.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				const errorMsg = `[${response2.reason.response.statusText}] ${response2.reason.response.data.error}`
				this.props.enqueueSnackbar(`Error in retrieving the data: ${errorMsg}`, { variant: "error" });
				console.log(`User does not have permission to view epic. Error: ${errorMsg}`);
				this.props.history.goBack();
			} else if (response1.status === "rejected") {
				let error = response1.reason.response;
				console.error(error);
				if (error.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				this.props.enqueueSnackbar(`Error in retrieving the epic data: [${error.statusText}] ${error.data.error}`, { variant: "error" });
				this.props.history.goBack();
			} else {
				console.error(response1, response2);
				this.props.enqueueSnackbar(`Something went wrong...`, { variant: "error" });
			}
		})
	};

	handleChange = (_, newValue) => {
		this.setState({ tabValue: newValue });
		sessionStorage.setItem('TabValue', this.epicId + newValue);
	};

	EarningsOfUsersInEpic = React.lazy(() => import('./views/EarningsOfUsersInEpic'));

	render() {
		const { classes, history } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<div className={classes.loadingRoot}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</div>
			);
		} else if (this.state.errorMsg) {
			return (
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					{this.state.errorMsg}
				</Alert>
			)
		} else {
			return (
				<section>
					<CssBaseline />
					<LearnAppBar pageName={"Epic"} history={history} />
					<Container className={classes.container}>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="caption" className={classes.name} color="textSecondary">
									<Link href={`/learn/projects/${this.epic.Project.Id}`} analyticsObj={this.analyticsObj}>
										{this.epic.Project.Name}
									</Link> /
								</Typography>
							</Grid>
							<Grid item xs={12} className={classes.epicNameGridItems}>
								<EpicStateIndicator
									epicState={this.epic.State}
									epicId={this.epicId}
									api_url={this.props.api_url}
									history={this.props.history}
								/>
								<Typography variant="h5">
									{this.epic.Name}
								</Typography>
							</Grid>
						</Grid>
						<TabContext value={this.state.tabValue}>
							<div className={classes.tabPanel}>
								<Tabs
									value={this.state.tabValue}
									onChange={this.handleChange}
									textColor="secondary"
									indicatorColor="secondary"
									variant="fullWidth"
								>
									<Tab value="overview" label="Overview" />
									<Tab value="list" label="List" disabled={isMentee(this.epic) && (!this.userEpic.Deposited)} />
									<Tab value="board" label="Board" disabled={isMentee(this.epic) && (!this.userEpic.Deposited)} />
									<Tab value="notes" label="Meetings" disabled={isMentee(this.epic) && (!this.userEpic.Deposited)} />
								</Tabs>
								<Divider variant="middle" />
							</div>
							<TabPanel value="overview" className={classes.tabPanel}>
								<Grid container>
									<Grid item xs={12} sm={4} >
										<EpicProfileDrawer
											pageName={"Epic"}
											api_url={this.props.api_url}
											history={this.props.history}
											projectId={this.projectId}
											epicId={this.epicId}
											userEpic={this.userEpic}
											{...this.epic}
										/>
									</Grid>

									<Grid item xs={12} sm={8} >
										<ProfileMetrics pageName={"Epic"}
											left={{
												displayType: "money",
												metricValue: this.epic.Value,
												metricColor: '#00dd11',
												text: "Epic Value",
											}}
											right={{
												displayType: "money",
												metricValue: this.epic.BuyIn,
												metricColor: '#dd0011',
												text: "Buy-In",
											}}
										/>
										<Divider variant="middle" />
										{(this.epic.State === EpicStates.FINISHED) ?
											<>
												<div className={classes.sectionEarnings}>
													<Typography variant="h6">Earnings of contributors</Typography>
												</div>
												<Suspense fallback={<div>Loading...</div>}>
													<this.EarningsOfUsersInEpic userEpic={this.userEpic} ProjectId={this.projectId} EpicId={this.epicId} api_url={this.props.api_url} analyticsObj={this.analyticsObj} history={this.props.history} buyInValue={this.epic.BuyIn.Amount} epicValue={this.epic.Value.Amount} />
												</Suspense>
											</>
											: null
										}
									</Grid>
								</Grid>
							</TabPanel>
							<TabPanel value="list" className={classes.tabPanel}>
								<StoryViews viewType='List' epic={this.epic} api_url={this.props.api_url} pageName="Epic" history={this.props.history} />
							</TabPanel>
							<TabPanel value="board" className={classes.tabPanel}>
								<StoryViews viewType='Board' epic={this.epic} api_url={this.props.api_url} pageName="Epic" history={this.props.history} />
							</TabPanel>
							<TabPanel value="notes" className={classes.tabPanel}>
								<MeetingNotes epic={this.epic} api_url={this.props.api_url} pageName="Epic" history={this.props.history} />
							</TabPanel>
						</TabContext>
					</Container>
					<AppFooter pageName="Epic" />
				</section>
			)
		}
	}
}

export default withStyles(styles)(withSnackbar(EpicPage));