import React from 'react';
import Button from '../../../mui_modules/components/Button';
import PropTypes from 'prop-types'
import { EpicStates, isMentee, isMentor, isProjectOwner } from '../../../util/uiUtil';
import CashfreeBuyIn from './CashfreeBuyIn';

class UserActionButtonOnEpic extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			buyInDialogOpen: false,
		};

		this.analyticsObj = {
			source: this.props.source,
			page: this.props.pageName,
		};
	}

	openCashfreeDialog = () => {
		this.setState({ buyInDialogOpen: true });
	}

	closeCashfreeDialog = () => {
		this.setState({ buyInDialogOpen: false });
	}

	ActionButtonTypesOnEpic = Object.freeze({
		BUY_IN: "Buy-In",
		LAUNCH_EPIC: "Launch Epic",
		END_EPIC: "End Epic",
		ONGOING: "Ongoing",
		FINISHED: "Finished",
		WITHDRAW: "Withdraw",
		OPEN: "Open",
		PENDING: "Pending",
	})

	buyInButton = (userEpic, buyInDisabled) => {
		if (!userEpic.Withdrawn) {
			return this.actionButtonsOnEpic(this.ActionButtonTypesOnEpic.BUY_IN, userEpic, buyInDisabled);
		} else {
			return this.actionButtonsOnEpic('error', userEpic);
		}
	}

	epicStatesOnRoleBasis = (epic, userEpic) => {
		if (isProjectOwner(epic.Project) || isMentor(epic)) {
			switch (epic.State) {
				case EpicStates.PENDING:
					return this.actionButtonsOnEpic(this.ActionButtonTypesOnEpic.PENDING, userEpic);
				case EpicStates.OPEN:
					return this.actionButtonsOnEpic(this.ActionButtonTypesOnEpic.LAUNCH_EPIC, userEpic);
				case EpicStates.ONGOING:
					return this.actionButtonsOnEpic(this.ActionButtonTypesOnEpic.END_EPIC, userEpic);
				case EpicStates.FINISHED:
					return this.actionButtonsOnEpic(this.ActionButtonTypesOnEpic.WITHDRAW, userEpic);
				default: return;
			}
		} else {
			switch (epic.State) {
				case EpicStates.PENDING:
					return this.actionButtonsOnEpic(this.ActionButtonTypesOnEpic.PENDING, userEpic);
				case EpicStates.OPEN:
					return this.actionButtonsOnEpic(this.ActionButtonTypesOnEpic.OPEN, userEpic);
				case EpicStates.ONGOING:
					return this.actionButtonsOnEpic(this.ActionButtonTypesOnEpic.ONGOING, userEpic);
				case EpicStates.FINISHED:
					return this.actionButtonsOnEpic(this.ActionButtonTypesOnEpic.WITHDRAW, userEpic);
				default: return;
			}
		}
	}

	userActionButtonOnEpic = (epic, userEpic) => {
		let buyInDisabled = false;
		if (isProjectOwner(epic.Project)) {
			if ((!userEpic.Deposited)) {
				return this.buyInButton(userEpic, buyInDisabled);
			} else if (!userEpic.Withdrawn) {
				return this.epicStatesOnRoleBasis(epic, userEpic);
			} else {
				return this.actionButtonsOnEpic(this.ActionButtonTypesOnEpic.FINISHED, userEpic);
			}
		} else if (isMentor(epic)) {
			if ((!userEpic.Deposited)) {
				buyInDisabled = (epic.State === EpicStates.PENDING);
				return this.buyInButton(userEpic, buyInDisabled);
			} else if (!userEpic.Withdrawn) {
				return this.epicStatesOnRoleBasis(epic, userEpic);
			} else {
				return this.actionButtonsOnEpic(this.ActionButtonTypesOnEpic.FINISHED, userEpic);
			}
		} else if (isMentee(epic)) {
			if ((!userEpic.Deposited)) {
				buyInDisabled = (epic.State === EpicStates.PENDING);
				return this.buyInButton(userEpic, buyInDisabled);
			} else if (!userEpic.Withdrawn) {
				return this.epicStatesOnRoleBasis(epic, userEpic);
			} else {
				return this.actionButtonsOnEpic(this.ActionButtonTypesOnEpic.FINISHED, userEpic);
			}
		}
	}

	actionButtonsOnEpic = (buttonType, userEpic, buyInDisabled) => {
		switch (buttonType) {
			case this.ActionButtonTypesOnEpic.BUY_IN:
				return (<Button variant="contained" color='secondary' onClick={this.openCashfreeDialog} analyticsObj={this.analyticsObj} fullWidth disabled={userEpic.Deposited || buyInDisabled}> {buttonType} </Button>);
			case this.ActionButtonTypesOnEpic.ONGOING:
				return (<Button variant="text" color='secondary' analyticsObj={this.analyticsObj} fullWidth> {buttonType} </Button>);
			case this.ActionButtonTypesOnEpic.LAUNCH_EPIC:
				return (<Button variant="contained" color='secondary' onClick={() => this.props.handleLaunchEpicDialogOpen()} analyticsObj={this.analyticsObj} fullWidth > {buttonType} </Button>);
			case this.ActionButtonTypesOnEpic.END_EPIC:
				return (<Button variant="contained" color='secondary' onClick={() => this.props.handleEndEpicDialogOpen()} analyticsObj={this.analyticsObj} fullWidth > {buttonType} </Button>);
			case this.ActionButtonTypesOnEpic.FINISHED:
				return (<Button variant="text" color='secondary' analyticsObj={this.analyticsObj} fullWidth > {buttonType} </Button>);
			case this.ActionButtonTypesOnEpic.WITHDRAW: //FIX ME: We now show the same handlePaymentOpen dialogue on clicking the button
				return (<Button variant="contained" color='secondary' onClick={() => this.props.handlePaymentOpen({ userEpic })} analyticsObj={this.analyticsObj} fullWidth disabled={userEpic.Withdrawn}> {buttonType} </Button>);
			default:
				return;
		}
	}

	render() {
		const { userEpic, epic } = this.props;
		const buyInProps = isProjectOwner(epic.Project) ? epic.Value : epic.BuyIn;

		return (
			<div align="right">
				{this.userActionButtonOnEpic(epic, userEpic)}
				<CashfreeBuyIn
					dialogOpen={this.state.buyInDialogOpen}
					closeCashfreeDialog={this.closeCashfreeDialog}
					epic={epic}
					api_url={this.props.api_url}
					{...buyInProps}
				/>
			</div>
		)
	}
}

UserActionButtonOnEpic.propTypes = {
	handleLaunchEpicDialogOpen: PropTypes.func.isRequired,
	handleSetEpicState: PropTypes.func.isRequired,
	handlePaymentOpen: PropTypes.func.isRequired,
	userEpic: PropTypes.object.isRequired,
	epic: PropTypes.object.isRequired
};

export default UserActionButtonOnEpic;
