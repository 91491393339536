import React from 'react';
import axios from 'axios';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import { convertArrayToObject, reorderList, storyTypeToIcon } from '../../../util/uiUtil';
import { withStyles, Chip, CircularProgress, ListItem, ListItemAvatar, Container, Typography, ListItemIcon, ListItemSecondaryAction, Avatar, ListItemText, Tooltip, Grid, List, Hidden, Collapse } from '@material-ui/core';
import IconButton from '../../../mui_modules/components/IconButton';
import { authMiddleWare } from '../../../util/auth';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import taskIcon from '../../../resources/images/taskIcon.png';
import StoryTasksList from '../views/StoryTasksList';

const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	root: {
		[theme.breakpoints.up('sm')]: {
		},
		marginTop: theme.spacing(2),
	},
	container: {
		width: '100%',
		'& > *': {
			paddingBottom: theme.spacing(1),
		},
		background: "#f6f8fad",
	},
	list: {
		width: '100%',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(-1),
	},
	storyName: {
		'&:hover': {
			background: "#bbdefb",
		},
		background: "#ffffff",
	},
	profilePicture: {
		height: theme.spacing(3),
		width: theme.spacing(3),
	},
	addStoryButton: {
		marginTop: theme.spacing(1),
	},
	deleteButton: {
		'&:hover': {
			backgroundColor: theme.palette.error.main,
		},
	},
	viewContributors: {
		'& > *': {
			margin: theme.spacing(0.3), // TODO: check spacing(0.1)
		},
	},
	listHeader: {
		backgroundColor: "#ffffff",
		color: '#252525'
		/* To change the font, use the fontFamily rule */
	},
	listItemIcon: {
		marginRight: theme.spacing(-2),
	},
	display: {
		alignItems: 'center',
	},
	customWidth: {
		maxWidth: 200,
	}
})

class StoryList extends React.Component {
	constructor(props) {
		super(props);
		this.stories = {};
		this.userStoryOrder = [];
		this.doneStories = [];
		this.userRole = '';
		this.state = {
			selectedStoryId: '',
			storiesLoading: true,
			storiesOrderLoading: true,
			uiLoading: true,
		}

		this.analyticsObj = {
			source: "StoryList",
			page: this.props.pageName,
		};

		this.onDragEnd = this.onDragEnd.bind(this);
	}

	onStoryOrderEdit = (array) => {
		const Stories = {
			Stories: array,
			UserRole: this.userRole
		};
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.post(this.props.api_url + `/projects/${this.props.epic.Project.Id}/userEpics/${this.props.epic.Id}`, Stories)
			.then((response) => {
				console.debug(response);
				// window.location.reload(); //FIXME: Not sure whether this should be added or not.
			})
			.catch((err) => {
				console.error(err);
				window.location.reload();
			});
	};

	onDragEnd = (result) => {
		// return if story was dropped outside
		if (!result.destination) {
			return;
		}
		// return if the story was dropped in the same place
		if (
			result.source.droppableId === result.destination.droppableId &&
			result.source.index === result.destination.index
		) {
			return;
		}
		// get the new order of stories
		const newOrder = reorderList(
			this.userStoryOrder,
			result.source.index,
			result.destination.index
		);

		this.userStoryOrder = newOrder;
		this.onStoryOrderEdit(this.userStoryOrder);
	};

	rearrangeStoryIds() {
		var storyIds = [];
		var userStoryIds = [];
		var hasStoryIds = [];

		Object.keys(this.stories).forEach(id => {
			if (!this.userStoryOrder.has(id)) {
				storyIds.push(id);
			}
		});

		userStoryIds = Array.from(this.userStoryOrder);
		this.userStoryOrder = storyIds.concat(userStoryIds);

		// checks if a story is deleted and still is there in userStoryOrder, removes that deleted story from user's preferred order as well.
		this.userStoryOrder.forEach((id) => {
			if (id in this.stories) {
				hasStoryIds.push(id);
			}
		})
		this.userStoryOrder = hasStoryIds;
	};

	getDoneStateStoryIds() {
		var completedStories = [];
		var notDoneStories = [];
		this.userStoryOrder.forEach((id, index) => {
			if (this.stories[id].State !== 'Done') {
				notDoneStories.push(id);
			} else {
				completedStories.push(id);
			}
		})
		this.userStoryOrder = notDoneStories;
		this.doneStories = completedStories;
	}

	handleTasksCollapseOnClick = (storyId) => {
		if (this.state.selectedStoryId !== storyId) {
			this.setState({
				selectedStoryId: storyId
			})
		} else {
			this.setState({
				selectedStoryId: ''
			})
		}
	};

	componentDidMount() {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get(this.props.api_url + `/projects/${this.props.epic.Project.Id}/epics/${this.props.epic.Id}/stories`)
			.then((response) => {
				this.stories = convertArrayToObject(response.data, 'StoryId');
				this.setState({
					storiesLoading: false,
				});
			})
			.catch((error) => {
				console.error(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login')
				}
				this.setState({ errorMsg: 'Error in retrieving the data' });
			});

		axios
			.get(this.props.api_url + `/users/${localStorage.getItem('UserId')}/user_epics/${this.props.epic.Id}`)
			.then((response) => {
				this.userStoryOrder = new Set(response.data.Stories);
				this.userRole = response.data.Role;
				this.setState({
					storiesOrderLoading: false,
				});
			})
			.catch((error) => {
				console.error(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login')
				}
			});
	};

	componentDidUpdate() {
		if (!this.state.storiesLoading && !this.state.storiesOrderLoading && this.state.uiLoading) {
			// re-arrange storyIds
			this.rearrangeStoryIds();
			// filter 'Done' stories from the whole list
			this.getDoneStateStoryIds();
			this.setState({
				uiLoading: false
			});
		}
	};

	recurringStoryListComponent = (story, storyId, ref, draggableProps, dragHandleProps, index) => {
		const { classes } = this.props;
		let numberOfTasks = (story.Tasks) ? story.Tasks.length : 0;
		return (
			<React.Fragment key={storyId}>
				<ListItem dense className={classes.storyName}
					divider
					ref={ref}
					{...draggableProps}
					{...dragHandleProps}>
					<Tooltip title={(story.Type) ? story.Type : "No Story Type"} placement="bottom" >
						<ListItemIcon>
							{storyTypeToIcon(story.Type)}
						</ListItemIcon>
					</Tooltip>
					<Grid container>
						<Grid item xs={12} md={6}>
							<ListItemText onClick={() => this.props.handleViewOpen({ story })} align="justify">
								{story.Name}
							</ListItemText>
						</Grid>
						<Grid container item xs={2} md={1} className={classes.display} justify="center">
							<Typography variant={"caption"}>{numberOfTasks}</Typography>
							<Tooltip arrow title="Add Tasks to break up the story into smaller parts / View Tasks" placement="bottom" classes={{ tooltip: classes.customWidth }}>
								<img
									width={25}
									heigth={21}
									src={taskIcon}
									alt="task"
									onClick={() => { this.handleTasksCollapseOnClick(storyId) }}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={2} md={1}>
							<ListItemIcon >
								<Chip size="small" label={story.Credits} />
							</ListItemIcon>
						</Grid>
						<Grid item xs={4} md={2}>
							<ListItemIcon >
								<Chip size="small" label={story.State} />
							</ListItemIcon>
						</Grid>
						<Grid item xs={4} md={2}>
							<ListItemAvatar >
								{story.Contributors ?
									<Grid container direction="row" justify="center" alignItems="center" className={classes.viewContributors}>
										{story.Contributors.map((contributor) =>
											<Tooltip title={contributor.Name} key={contributor.Id} placement="bottom" className={classes.customWidth}>
												<Avatar className={classes.profilePicture} alt={contributor.Id} src={contributor.PhotoURL} />
											</Tooltip>
										)}
									</Grid>
									: null}
							</ListItemAvatar>
						</Grid>
					</Grid>
					<ListItemSecondaryAction>
						<IconButton onClick={this.props.handleMenuOpen(story)} aria-haspopup="true" analyticsObj={this.analyticsObj}>
							<Hidden xsDown>
								<MoreHorizIcon fontSize="small" />
							</Hidden>
							<Hidden smUp>
								<MoreVertIcon fontSize="small" />
							</Hidden>
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
				<Collapse in={storyId === this.state.selectedStoryId} timeout="auto" unmountOnExit key={index}>
					<StoryTasksList story={story} epic={this.props.epic} allUsers={this.props.allUsers} pageName={this.props.pageName} api_url={this.props.api_url} history={this.props.history} />
				</Collapse>
			</React.Fragment>
		)
	};
	render() {
		const { classes } = this.props;

		if (this.state.uiLoading) {
			return (
				<div className={classes.loadingRoot}>
					{this.state.uiLoading && <CircularProgress size={150} />}
				</div>
			)
		} else {
			return (
				<section className={classes.root}>
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Container className={classes.container}>
							<Droppable droppableId="droppable">
								{(provided) => (
									<List className={classes.list} {...provided.droppableProps} ref={provided.innerRef}>
										{this.userStoryOrder.map((storyId, index) => {
											const story = this.stories[storyId];
											return (
												<Draggable key={storyId} draggableId={storyId} index={index}>
													{(provided) => {
														const ref = provided.innerRef;
														const draggableProps = { ...provided.draggableProps };
														const dragHandleProps = { ...provided.dragHandleProps };
														return (
															this.recurringStoryListComponent(story, storyId, ref, draggableProps, dragHandleProps, index)
														)
													}}
												</Draggable>
											)
										})}
										{provided.placeholder}
									</List>
								)}
							</Droppable>
							<div>
								<List className={classes.list}>
									<ListItem className={classes.listHeader} button onClick={this.props.handleCollapseOnClick}>
										<ListItemIcon className={classes.listItemIcon}>
											{this.props.open ? <ExpandLess /> : <ExpandMore />}
										</ListItemIcon>
										<ListItemText primary="Completed Stories" />
									</ListItem>
									<Collapse in={this.props.open} timeout="auto" unmountOnExit>
										{this.doneStories.map((storyId) => {
											const story = this.stories[storyId];
											return (
												this.recurringStoryListComponent(story, storyId)
											)
										})}
									</Collapse>
								</List>
							</div>
						</Container>
					</DragDropContext>
				</section>
			)
		}
	}
}

export default withStyles(styles)(StoryList);