import React from 'react';
import axios from 'axios';
import { authMiddleWare } from '../../../util/auth';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { withStyles, Container, List, ListItem, Grid, ListItemText, CircularProgress, Link, Hidden } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import EpicStateIndicator from '../../../mui_modules/components/EpicStateIndicator';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import { withSnackbar } from 'notistack';
import Button from '../../../mui_modules/components/Button';

const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: '50vh',
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.default,
		overflow: 'hidden',
	},
	container: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(10),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
		},
		[theme.breakpoints.up('md')]: {
			width: '70% !important',
		},
		[theme.breakpoints.up('lg')]: {
			width: '50% !important',
		},
	},
	tableContainer: {
		margin: theme.spacing(2),
	},
	table: {
		minWidth: 450,
		width: '100%',
	},
	list: {
		width: '100%',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(-1),
	},
	epicName: {
		'&:hover': {
			background: "#bbdefb",
		},
		background: "#ffffff",
		margin: theme.spacing(0),

	},
	epicNameGridItems: {
		display: 'flex',
		alignContent: 'center',
		flexDirection: 'row'
	},
	epicStateOuterdiv: {
		lineHeight: 2
	},
});

class MentorEpics extends React.Component {
	constructor(props) {
		super(props);

		this.userId = localStorage.getItem('UserId');

		this.state = {
			uiLoading: true,
			mentor_epics: null,
			dialogOpen: false,
			epicId: '',
		}

		this.analyticsObj = {
			source: "MentorEpics",
			page: this.props.pageName,
		};
	}

	componentDidMount() {
		dayjs.extend(relativeTime);
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get(this.props.api_url + `/users/${this.userId}/role_epics_with_details/Mentor`)
			.then((response) => {
				console.log(response.data)
				this.setState({
					uiLoading: false,
					mentor_epics: response.data.sort((epic1, epic2) => (epic2.CreatedAt._seconds - epic1.CreatedAt._seconds))
				});
			})
			.catch((error) => {
				console.log(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				if (error.response != null && error.response.data != null && error.response.data.message != null) {
					for (let msg of error.response.data.message) {
						this.props.enqueueSnackbar(msg, { variant: "error" })
					}
				}
				this.setState({
					uiLoading: false,
					errorMsg: 'Error in retrieving the data',
				});
			});
	}

	render() {
		const { classes } = this.props;

		if (this.state.uiLoading) {
			return (
				<div className={classes.loadingRoot}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</div>
			)
		} else if (this.state.errorMsg) {
			return (
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					{this.state.errorMsg}
				</Alert>
			)
		} else {
			return (
				<section className={classes.root}>
					<Container className={classes.container}>
						<List className={classes.list}>
							{(this.state.mentor_epics && this.state.mentor_epics.length > 0) ? this.state.mentor_epics.map((epic) => (
								<ListItem dense className={classes.epicName} key={epic.Epic.Id} divider >
									<Grid container justify="flex-start" alignItems='center'>
										<Grid item xs={12} sm={10} className={classes.epicNameGridItems}>
											<EpicStateIndicator
												epicState={epic.State}
												epicId={epic.Epic.Id}
												api_url={this.props.api_url}
												history={this.props.history}
												className={classes.epicStateOuterdiv}
											/>
											<ListItemText primary={<Link href={`/learn/projects/${epic.Project.Id}/epics/${epic.Epic.Id}`} color="inherit">
												{epic.Epic.Name}
											</Link>}
												secondary={<Link href={`/learn/projects/${epic.Project.Id}`} color="inherit">
													{epic.Project.Name}
												</Link>}
											/>
										</Grid>
										<Hidden xsDown>
											<Grid item sm={2} align='right'>
												<Button
													variant="contained"
													color="secondary"
													href={`/learn/projects/${epic.Project.Id}/epics/${epic.Epic.Id}`}
													analyticsObj={this.analyticsObj}
													size="small"
												>
													View
												</Button>
											</Grid>
										</Hidden>
									</Grid>
								</ListItem>
							)) : (
								<Alert severity='info'>
									No epics found
								</Alert>
							)}
						</List>
					</Container>
				</section>
			);
		}
	}
}

export default withStyles(styles)(withSnackbar(MentorEpics));