import React from 'react';
import PropTypes from 'prop-types';
import MuiLink from '@material-ui/core/Link';
import hrefAnalytics from "../../util/hrefAnalytics";

class Link extends React.Component {
	render() {
		const { children, href, target, onClick, analyticsObj, ...otherProps } = this.props;

		analyticsObj.method = "Link";
		const newOnClick = hrefAnalytics(href, onClick, analyticsObj, target);

		return (
			<MuiLink color="textSecondary" href={href} onClick={ newOnClick } {...otherProps} >
				{children}
			</MuiLink>
		);
	}
}

Link.propTypes = {
	children: PropTypes.node,
	href: PropTypes.string,
	target: PropTypes.string,
	onClick: PropTypes.func,
	analyticsObj: PropTypes.object,
};

export default Link;