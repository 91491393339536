import React from 'react';
import PropTypes from 'prop-types';
import { Container, withStyles } from "@material-ui/core";
import Typography from '../components/Typography';
import { tradeMarkedAlokit } from '../../util/uiUtil';

const style = (theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.secondary,
		overflow: 'hidden',
	},
	container: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(10),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
		},
		[theme.breakpoints.up('md')]: {
			width: theme.breakpoints.values.md,
		},
	},
	title: {
		marginBottom: theme.spacing(3),
	}
})

function ProductWhatWeDo(props) {
	const { classes } = props;

	return (
		<section className={classes.root}>
			<Container className={classes.container}>
				<Typography variant="h4" marked="center" className={classes.title} component="h2">
					What we do
				</Typography>
				<div>
					<Typography marked="center" variant="subtitle1" >
						{tradeMarkedAlokit()} helps organisations combine <b>software development</b> with <b>hiring</b>.
					</Typography>

					<br />
					<Typography variant="body1" >
						An <b>Audition Project</b> on {tradeMarkedAlokit()} helps you "audition" potential hires by giving them real software
						development tasks. Auditioners are compensated for their work and organisations can evaluate candidates
						based on actual, real world performance. No need to use proxies like coding puzzles or technical interviews.
						This also ends up saving valuable developer time.
					</Typography>
					<br />

				</div>
			</Container>
		</section>
	)
}

ProductWhatWeDo.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ProductWhatWeDo);