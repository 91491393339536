import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import backgroundImage from '../../resources/images/mentee_bg_img.jpg'
import { Grid, Container, Hidden } from '@material-ui/core';
import LoginCard from './LoginCard';

const styles = (theme) => ({
	background: {
		backgroundImage: `url(${backgroundImage})`,
		backgroundColor: '#141415', // Average color of the background image.
		backgroundPosition: 'center',
	},
	button: {
		minWidth: 200,
	},
	h5: {
		marginBottom: theme.spacing(4),
		marginTop: theme.spacing(4),
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(10),
		},
	},
	more: {
		marginTop: theme.spacing(2),
	},
	container: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(14),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
});

function LoginHero(props) {
	const { classes, pageName, ...otherProps } = props;

	return (
		<ProductHeroLayout backgroundClassName={classes.background} sectionType="landing">
			{/* Increase the network loading priority of the background image. */}
			<img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
			<Grid container>
				<Hidden smDown>
					<Grid item xs={false} md={7}>
						<Container className={classes.container}>
							<div style={{ height: 100 }} />
							<Typography color="inherit" align="center" variant="h2" marked="center">
								Great teams build great products
							</Typography>
							<Typography color="inherit" align="center" variant="h5" className={classes.h5}>
								<b>Test-drive</b> your contributors to build <b>long-lasting teams</b>
							</Typography>
							<Typography variant="body2" color="inherit" className={classes.more}>
								Your <b>final step</b> for hiring <b>software developers</b>
							</Typography>
						</Container>
					</Grid>
				</Hidden>
				<Grid item xs={12} md={5}>
					<LoginCard {...otherProps} api_url={props.api_url} show_email_login={props.show_email_login} pageName={pageName} />
				</Grid>
			</Grid>
		</ProductHeroLayout>
	);
}

LoginHero.propTypes = {
	classes: PropTypes.object.isRequired,
	api_url: PropTypes.string,
	show_email_login: PropTypes.bool,
	pageName: PropTypes.string,
};

export default withStyles(styles)(LoginHero);