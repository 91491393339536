import withRoot from '../mui_modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Markdown from '../mui_modules/components/Markdown';
import Typography from '../mui_modules/components/Typography';
import AppAppBar from '../mui_modules/views/AppAppBar';
import terms from '../mui_modules/views/terms.md';
import AppFooter from '../mui_modules/views/AppFooter';

class Terms extends React.Component {
	constructor(props) {
		super(props);
		this.state = { markdown: '' };
	}

	componentDidMount() {
		// Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
		fetch(terms).then(res => res.text()).then(text => this.setState({ markdown: text }));
	}

	render() {
		return (
			<React.Fragment>
				<AppAppBar pageName={ "Terms" } />
				<Container>
					<Box mt={7} mb={12}>
						<Typography variant="h3" gutterBottom marked="center" align="center">
							Terms and Conditions
						</Typography>
						<Markdown>{this.state.markdown}</Markdown>
					</Box>
				</Container>
				<AppFooter pageName={ "Terms" } />
			</React.Fragment>
		);
	}
}

export default withRoot(Terms);