import React from 'react';
import axios from 'axios';
import { authMiddleWare } from '../../util/auth';

import { CssBaseline, withStyles, Container } from '@material-ui/core';
import LearnAppBar from './views/LearnAppBar';
import AppFooter from '../../mui_modules/views/AppFooter';
import Button from '../../mui_modules/components/Button';
import RecordBuyInAndCashout from './admin_views/recordBuyInAndCashout';
import UserRoles from './admin_views/userRoles';
import { removeAllSavedCookies } from '../../util/firebaseAuth';

const styles = (theme) => ({
	root: {
		minHeight: '60vh',
	},
	adminActionsSection: {
		padding: theme.spacing(2),
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
})

class AdminView extends React.Component {
	constructor(props) {
		super(props);

		/* Features to add:
			1. Record transactions for any user of any type
			2. Assign roles to users: Mentor, Mentee and Admin
			3. Record buy-in and cashout information for any user and epic
		*/
		this.adminActions = [
			"user roles",
			"buy-in & cashout",
		];

		this.state = {
			currentScreen: null,
			allUsers: null,
			allUsersLoading: true,
		}

		this.analyticsObj = {
			source: "adminPage",
			page: "AdminView",
		};
	}

	componentDidMount() {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };

		axios
			.get(this.props.api_url + `/admin/userRoles`)
			.then((response) => {
				// console.log(response.data);
				this.setState({
					allUsers: response.data,
					allUsersLoading: false
				});
			})
			.catch((error) => {
				console.error(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				this.setState({ errorMsg: 'Error in retrieving the data' });
			})
	}

	handleClick = (action) => (event) => {
		event.preventDefault();
		this.setState({ currentScreen: action });
	}

	render() {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<CssBaseline />
				<LearnAppBar pageName="AdminView" history={this.props.history} />
				<Container className={classes.root}>
					<section className={classes.adminActionsSection}>
						{this.adminActions.map((action) => (
							<Button
								key={action}
								variant="outlined"
								disabled={this.state.allUsersLoading}
								onClick={this.handleClick(action)}
								analyticsObj={this.analyticsObj}
							>
								{action}
							</Button>
						))}
					</section>
					{
						(this.state.currentScreen === "buy-in & cashout") ? <RecordBuyInAndCashout all_users={this.state.allUsers} pageName="AdminView" api_url={this.props.api_url} /> :
							(this.state.currentScreen === "user roles") ? <UserRoles all_users={this.state.allUsers} pageName="AdminView" history={this.props.history} api_url={this.props.api_url} /> :
								null
					}
				</Container>
				<AppFooter pageName="AdminView" />
			</React.Fragment>
		)
	}
}

export default withStyles(styles)(AdminView);