import React from 'react';
import withRoot from "../mui_modules/withRoot";
import AppAppBar from "../mui_modules/views/AppAppBar";
import AppFooter from '../mui_modules/views/AppFooter';
import ProjectJourney from "../mui_modules/views/ProjectJourney";
import { CssBaseline } from '@material-ui/core';
import ProgramHero from '../mui_modules/views/ProgramHero';
import ProgramTimeline from '../mui_modules/views/ProgramTimeline';
import ProductCTA from '../mui_modules/views/ProductCTA';
import LinkToFAQ from '../mui_modules/views/LinkToFAQ';

import { gAnalytics } from "../util/analytics";

class Program extends React.Component {
	constructor(props) {
		super(props);
		gAnalytics.logEvent('screen_view', {
			firebase_screen: 'program',
		});
	}

	render() {
		return (
			<React.Fragment>
				<CssBaseline />
				<AppAppBar pageName={ "Program" } />
				<ProgramHero pageName={ "Program" } />
				<ProjectJourney titleText="Project Format" pageName={ "Program" } />
				<ProgramTimeline titleText="Timeline" pageName={ "Program" } />
				<LinkToFAQ pageName={ "Program" } />
				<ProductCTA titleText="Enroll Now..." pageName={ "Program" } />
				<AppFooter pageName={ "Program" } />
			</React.Fragment>
		)
	}
}

export default withRoot(Program);