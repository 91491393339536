import React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { withStyles, Card, CardContent, Grid, CardActions, CircularProgress } from '@material-ui/core';
import Typography from '../../../mui_modules/components/Typography';
import Button from '../../../mui_modules/components/Button';
import { authMiddleWare } from '../../../util/auth';
import { isProjectOwner } from '../../../util/uiUtil';
import AddProjectDialog from './AddProjectDialog';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';

const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	root: {
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(4),
		},
		marginTop: theme.spacing(2),
	},
	nested: {
		paddingLeft: theme.spacing(3),
	},
	projectCards: {
		width: '100%',
		'& > *': {
			padding: theme.spacing(1.5),
		},
	},
	pos: {
		marginBottom: 12
	},
	card: {
		backgroundColor: "#fff"
	}
})

class ProjectList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			uiLoading: true,
			addProjectDialogProps: {
				open: false,
				buttonType: '',
				projectId: '',
				handleClose: (event) => {
					let addProjectDialogProps = this.state.addProjectDialogProps;
					addProjectDialogProps.open = false;
					this.setState({
						addProjectDialogProps: addProjectDialogProps
					});
				},
				handleSubmit: this.handleAddEditProjectSubmit,
				pageName: this.props.pageName,
				errors: [],
				updateDialogProps: false,
				setUpdateDialogProps: (flag) => {
					let addProjectDialogProps = this.state.addProjectDialogProps;
					addProjectDialogProps.updateDialogProps = flag;
					this.setState({
						addProjectDialogProps: addProjectDialogProps
					});
				},
			},
		}

		this.projects = null;

		this.analyticsObj = {
			source: "ProjectList",
			page: this.props.pageName,
		};
	}

	componentDidMount() {
		dayjs.extend(relativeTime);
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get(this.props.api_url + '/projects/' + this.props.userId)
			.then((response) => {
				this.projects = response.data
				this.setState({ uiLoading: false });
			})
			.catch((error) => {
				console.log(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				this.setState({ errorMsg: 'Error in retrieving the data' });
			});
	}

	handleAddProjectClick = (event) => {
		let addProjectDialogProps = this.state.addProjectDialogProps;
		addProjectDialogProps.open = true;
		addProjectDialogProps.buttonType = 'Add';
		addProjectDialogProps.updateDialogProps = true;
		this.setState({
			addProjectDialogProps: addProjectDialogProps
		});
	}

	handleEditClickOpen = (data) => {
		let addProjectDialogProps = this.state.addProjectDialogProps;
		addProjectDialogProps.open = true;
		addProjectDialogProps.buttonType = 'Edit';
		addProjectDialogProps.project = data.project;
		addProjectDialogProps.projectId = data.project.ProjectId;
		addProjectDialogProps.updateDialogProps = true;
		this.setState({
			addProjectDialogProps: addProjectDialogProps,
		});
	}

	handleAddEditProjectSubmit = (newProject) => {
		authMiddleWare(this.props.history);
		let options = {};
		if (this.state.addProjectDialogProps.buttonType === 'Edit') {
			options = {
				url: this.props.api_url + `/project/${this.state.addProjectDialogProps.projectId}`,
				method: 'put',
				data: newProject
			};
		} else {
			options = {
				url: this.props.api_url + `/project`,
				method: 'post',
				data: newProject
			};
		}
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios(options)
			.then(() => {
				this.state.addProjectDialogProps.handleClose();
				window.location.reload();
			})
			.catch((error) => {
				if (error.response.data.code === "auth/id-token-expired") {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				let addProjectDialogProps = this.state.addProjectDialogProps;
				addProjectDialogProps.open = true;
				addProjectDialogProps.errors = error.response.data;
				console.error(error);
				this.setState({
					addProjectDialogProps: addProjectDialogProps
				});
			});
	}

	render() {
		const { classes } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<main className={classes.loadingRoot}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
				<section className={classes.root}>
					<Grid container spacing={2}>
						{(this.projects) ? this.projects.map((project) => (
							<Grid item xs={12} key={project.ProjectId.toString()} className={classes.projectCards}>
								<Card variant="outlined" className={classes.card}>
									<CardContent>
										<Typography variant="h5" component="h2">
											{project.Name}
										</Typography>
										<Typography className={classes.pos} color="textSecondary">
											{dayjs.unix(project.CreatedAt._seconds).fromNow()}
										</Typography>
										<Typography variant="body2" component="p">
											{`${project.Description.substring(0, 300)}`}
										</Typography>
									</CardContent>
									<CardActions>
										<Button size="small" variant="contained" color="secondary" href={`/learn/projects/${project.ProjectId}`} analyticsObj={this.analyticsObj}>
											{' '}
											View{' '}
										</Button>
										{(isProjectOwner(project)) ?
											<Button size="small" variant="outlined" color="secondary" onClick={() => this.handleEditClickOpen({ project })} analyticsObj={this.analyticsObj}>
												Edit
											</Button>
											: null}
									</CardActions>
								</Card>
							</Grid>
						)) : null}

						{((this.props.pageName === "Profile") && (this.props.userId !== localStorage.getItem('UserId'))) ? null :
							<div style={{ width: '100%' }}>
								<Button
									fullWidth
									variant="outlined"
									color="secondary"
									onClick={this.handleAddProjectClick}
									analyticsObj={this.analyticsObj}
								>
									Add Project
								</Button>
								<AddProjectDialog pageName={this.props.pageName} {...this.state.addProjectDialogProps} />
							</div>
						}
					</Grid>
				</section>
			)
		}
	}
}

export default withStyles(styles)(ProjectList);