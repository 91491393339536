import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    receiptNoContainer: {
        flexDirection: 'row-reverse',
    },
    companyName: {
        fontSize: 15
    },
    receiptDateContainer: {
        flexDirection: 'row-reverse',
    },
});


const ReceiptNumber = (props) => (
    <>
        <View style={styles.companyName}>
            <Text>ALOKIT INNOVATIONS PVT LTD</Text>
        </View>
        <View style={styles.receiptNoContainer}>
            <Text>{props.referenceId}</Text>
            <Text>Reference Id: </Text>
        </View>
        <View style={styles.receiptDateContainer}>
            <Text>{props.time.toLocaleDateString()}</Text>
            <Text>Date: </Text>
        </View>
        <View style={styles.receiptDateContainer}>
            <Text>{props.time.toLocaleTimeString()}</Text>
            <Text>Time: </Text>
        </View>
    </>
);

export default ReceiptNumber