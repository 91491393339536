import React from 'react';
import { Backdrop, Chip, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, withStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import { authMiddleWare } from '../../../util/auth';
import { getCurrencySymbol, userChipRender } from '../../../util/uiUtil';

const styles = (theme) => ({ //TODO: use colours from theme object only.
	listItem: {
		textAlign: 'center',
		paddingLeft: '16px',
		paddingRight: '16px',
		'&:hover': {
			background: "#d3d3d3",
		},
	},
	credits: {
		color: "#000000",
		backgroundColor: "#c0c0c0",
	},
	creditChip: {
		paddingLeft: theme.spacing(1.5),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(-1)
	},
	currency: {
		fontSize: '0.9rem',
		color: "#171717",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(-1)
	},
	overlay: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: '#0009',
		color: '#fff',
	},
	role: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(-1)
	},
})

class EarningsOfUsersInEpic extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allUsersCreditsAndEarnings: null,
			metaInfo: null,
			uiLoading: true,
		}
	}

	componentDidMount() {
		authMiddleWare(this.props.userEpic);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios.get(this.props.api_url + `/projects/${this.props.ProjectId}/epics/${this.props.EpicId}/creditsAndEarnings`)
			.then(res => {
				const { _meta, ...creditsAndEarnings } = res.data;
				this.setState({
					allUsersCreditsAndEarnings: Object.values(creditsAndEarnings),
					metaInfo: _meta,
					uiLoading: false,
				});
			})
			.catch(err => {
				console.error(err);
				if (err.response != null && err.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login')
				}
				if (err.response != null && err.response.data != null && err.response.data.message != null) {
					for (let msg of err.response.data.message) {
						this.props.enqueueSnackbar(msg, { variant: "error" })
					}
				} else {
					this.props.enqueueSnackbar('Error in retrieving credits and earnings data', { variant: "error" });
				}
				this.setState({
					uiLoading: false,
				});
			});
	}

	render() {
		const { classes } = this.props;
		if ((this.state.uiLoading)) {
			return (
				<Backdrop className={classes.overlay} open={this.state.uiLoading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			)
		} else {
			return (
				<List dense>
					<ListItem dense divider alignItems='center' className={classes.listItem} >
						<Grid container>
							<Grid item xs={12} md={3}>
								<ListItemText align="justify">
									Contributor
								</ListItemText>
							</Grid>
							<Grid item xs={3} md={3}>
								<ListItemText className={classes.role}>
									Role
								</ListItemText>
							</Grid>
							<Grid item xs={3} md={3}>
								<ListItemText className={classes.creditChip}>
									Credits earned
								</ListItemText>
							</Grid>
							<Grid item xs={3} md={3}>
								<ListItemText>
									<Typography className={classes.currency} variant='h5' >
										Winnings
									</Typography>
								</ListItemText>
							</Grid>
						</Grid>
					</ListItem>
					{(this.state.allUsersCreditsAndEarnings) ? this.state.allUsersCreditsAndEarnings.map((user, index) => (
						<ListItem dense divider alignItems='center' className={classes.listItem} key={index}>
							<Grid container>
								<Grid item xs={12} md={3}>
									<ListItemText align="justify">
										{userChipRender(user, this.props.analyticsObj)}
									</ListItemText>
								</Grid>
								<Grid item xs={3} md={3}>
									<ListItemIcon className={classes.role}>
										<Chip size='small' label={user.Role} className={classes.credits} />
									</ListItemIcon>
								</Grid>
								<Grid item xs={3} md={3}>
									<ListItemText className={classes.creditChip}>
										<Chip size="small" className={classes.credits} label={(user.Credits) ? user.Credits : 0} />
									</ListItemText>
								</Grid>
								<Grid item xs={3} md={3}>
									<ListItemText>
										<Typography className={classes.currency} variant='h5' >
											{user.Earnings.map((earningCurrency) => {
												return (<span key={earningCurrency.Currency}>
													{getCurrencySymbol(earningCurrency.Currency)}{earningCurrency.Amount}
												</span>)
											})}
										</Typography>
									</ListItemText>
								</Grid>
							</Grid>
						</ListItem>
					))
						: (
							<Alert severity="error">
								<AlertTitle>Error</AlertTitle>
								{"Could not get credits and earnings data"}
							</Alert>
						)}
					{(this.state.metaInfo) ? (
						<ListItem dense>
							<Grid container>
								{(this.state.metaInfo.unassignedCredits) ? (
									<Grid item xs={12} md={parseInt(12 / Object.keys(this.state.metaInfo).length)}>
										<ListItemText align="justify" className={classes.listItem}>
											Unassigned Completed Credits {" "}
											<Chip size="small" className={classes.credits} label={this.state.metaInfo.unassignedCredits} />
										</ListItemText>
									</Grid>
								) : null}
								{(this.state.metaInfo.invalidCredits) ? (
									<Grid item xs={12} md={parseInt(12 / Object.keys(this.state.metaInfo).length)}>
										<ListItemText align="justify">
											Unattributed Credits {" "}
											<Chip size="small" className={classes.credits} label={this.state.metaInfo.invalidCredits} />
										</ListItemText>
									</Grid>
								) : null}

								{(this.state.metaInfo.totalCredits) ? (
									<Grid item xs={12} md={parseInt(12 / Object.keys(this.state.metaInfo).length)}>
										<ListItemText align="justify" className={classes.listItem}>
											Total Credits in Epic {" "}
											<Chip size="small" className={classes.credits} label={this.state.metaInfo.totalCredits} />
										</ListItemText>
									</Grid>
								) : null}

							</Grid>
						</ListItem>
					) : null}
				</List>
			)
		}
	}
}

export default withStyles(styles)(withSnackbar(EarningsOfUsersInEpic));