import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { gAnalytics } from '../../util/analytics';

const styles = (theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center'
	},
	iframe: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
		},
		[theme.breakpoints.up('md')]: {
			width: theme.breakpoints.values.md,
		},
	}
});

function GoogleForm(props) {
	const { title, src, height, classes, analyticsObj } = props;
	var formRef;
	var firstView = true;

	analyticsObj.method = "GoogleForm";

	// FIXME: Huge assumption here that user will only choose the option to "edit form" from thank you page, and will not look at any other link

	const formLoad = () => {
		if (firstView) {
			formRef.height = height;

			firstView = false;
		}
		else {
			formRef.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
			formRef.height = 500;
			gAnalytics.logEvent('application_submitted', analyticsObj);

			firstView = true;
		}
	}

	return (
		<div className={classes.root}>
			<iframe 
				title={ title }
				src={ src }
				height={ height }
				frameBorder="0"
				marginHeight="0"
				marginWidth="0"
				scrolling="auto"
				className={ classes.iframe }
				ref={(el) => formRef = el}
				onLoad= { formLoad }
			>
					Loading…
			</iframe>
		</div>
	);
}

GoogleForm.propTypes = {
	title: PropTypes.string,
	src: PropTypes.string,
	height: PropTypes.string,
	classes: PropTypes.object,
	analyticsObj: PropTypes.object,
};

export default withStyles(styles)(GoogleForm);