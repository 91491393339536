import React from 'react';
import PropTypes from 'prop-types';
import Button from '../components/Button';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../components/Typography';
import { Grid, Divider } from '@material-ui/core';

import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

const styles = (theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	container: {
		marginTop: theme.spacing(9),
		marginBottom: theme.spacing(15),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
	},
	button: {
		borderBottom: '2px solid currentColor',
		height: 'auto',
		padding: theme.spacing(2, 0, 3, 2),
		textTransform: 'initial',
		marginTop: theme.spacing(2),
		textAlign: "left"
	},
	title: {
		fontWeight: 'bold',
		fontSize: theme.typography.h4.fontSize,
		marginBottom: theme.spacing(4),
	},
	buttonPrompt: {
		marginTop: theme.spacing(3),
	},
	divider: {
		marginLeft: 'auto',
		marginRight: 'auto',
	}
});

function ProductCTA(props) {
	const { classes, pageName } = props;
	const analyticsObj = {
		source: "ProductCTA",
		page: pageName,
	};

	return (
		<section className={classes.root}>
			<Container className={classes.container} component="section">
				<Grid container>
					<Grid item xs={12} sm={12}>
						<Typography variant="h5" className={classes.title} >
							{props.titleText}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={5}>
						<Typography variant="h5" className={classes.buttonPrompt}>
							Start your journey to building your dream team...
						</Typography>
						<Button
							color="inherit"
							variant="text"
							className={classes.button}
							endIcon={<ArrowForwardOutlinedIcon style={{ fontSize: 30 }} />}
							href="/login"
							analyticsObj={analyticsObj}
							fullWidth>
							<Typography color="inherit" variant="h6" component="span" >
								Create your free account
							</Typography>
							<span></span>
						</Button>
					</Grid>
					<Grid item xs={false} sm={2}>
						<Divider orientation="vertical" className={classes.divider} />
					</Grid>
					<Grid item xs={12} sm={5}>
						<Typography variant="h5" className={classes.buttonPrompt}>
							Still have doubts?
						</Typography>
						<Button color="inherit"
							variant="text"
							className={classes.button}
							endIcon={<ArrowForwardOutlinedIcon style={{ fontSize: 30 }} />}
							href="/contactUs"
							analyticsObj={analyticsObj}
							fullWidth>
							<Typography color="inherit" variant="h6" component="span" >
								Get in touch with us
							</Typography>
						</Button>
					</Grid>
				</Grid>
			</Container>
		</section>
	);
}

ProductCTA.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCTA);