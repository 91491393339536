import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
	dot: {
        height: '12px',
        width: '12px',
        borderRadius: '50%',
        display: 'inline-block',
        backgroundColor: theme.palette.secondary.main
    },
});

function Dot(props) {
    const { classes, className } = props;
    
    return (
        <span className={clsx(classes.dot, className)}/>
    );
}

Dot.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(styles)(Dot);