import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Typography, CircularProgress } from '@material-ui/core';

const useStylesEpicProgress = makeStyles((theme) =>
	createStyles({
		root: {
			position: 'relative',
		},
		overall: {
			color: "#e0e0e0",
		},
		inProgress: {
			color: "#ffab00",
			position: 'absolute',
			left: 0,
		},
		completed: {
			color: "#4caf50",
			position: 'absolute',
			left: 0,
		},
		box: {
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			position: "absolute",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			width: "100%",
		},
	}),
);

function calcEpicProgress(stories) {
	let creditCompleted = 0;
	let creditInProgress = 0;
	let creditBacklog = 0;
	let totalCredits = 0;

	for (let story in stories) {
		if ((stories[story].State === "In Review") || (stories[story].State === "Done")) {
			creditCompleted += parseInt(stories[story].Credits);
		} else if (stories[story].State === "In Progress") {
			creditInProgress += parseInt(stories[story].Credits);
		}
		totalCredits += parseInt(stories[story].Credits);
	}

	creditBacklog = totalCredits - creditInProgress - creditCompleted;
	let completedProgressValue = 0;
	let inProgressProgressValue = 0;
	let backlogProgressValue = 0;

	if (totalCredits > 0) {
		inProgressProgressValue = Math.floor((creditInProgress * 100) / totalCredits);
		backlogProgressValue = Math.floor((creditBacklog * 100) / totalCredits);
		completedProgressValue = 100 - inProgressProgressValue - backlogProgressValue
	}

	return { completedProgressValue: completedProgressValue, inProgressProgressValue: inProgressProgressValue, backlogProgressValue: backlogProgressValue };
}

function EpicProgress(props) {
	const classes = useStylesEpicProgress();

	return (
		<div className={classes.root}>
			<CircularProgress
				variant="determinate"
				className={classes.overall}
				thickness={props.thickness}
				size={props.size}
				value={100}
			/>
			<CircularProgress
				variant="determinate"
				className={classes.inProgress}
				thickness={props.thickness}
				size={props.size}
				value={props.epicProgressList.inProgressProgressValue + props.epicProgressList.completedProgressValue}
			/>
			<CircularProgress
				variant="determinate"
				className={classes.completed}
				thickness={props.thickness}
				size={props.size}
				value={props.epicProgressList.completedProgressValue}
			/>
			<Box className={classes.box} component="div">
				<Typography variant={(props.width === "xs" || props.width === "sm") ? "h6" : "h4"} color="textSecondary" align="center">
					{props.epicProgressList.completedProgressValue}{"%"}
				</Typography>
				<Typography variant="caption" color="textSecondary" align="center">
					Progress
				</Typography>
			</Box>
		</div>
	);
}

function EpicProgressBar(props) {
	const epicProgressList = calcEpicProgress(props.stories);
	return (
		<EpicProgress epicProgressList={epicProgressList} size={props.size} thickness={props.thickness} width={props.width} />
	)
}

export default EpicProgressBar;