import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Button, IconButton } from '../../../mui_modules/components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Hidden, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const styles = (theme) => ({
	cancelIconButton: {
		padding: theme.spacing(0),
		margin: 'auto',
		height: '100%',
		width: '100%',
	},
	form: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(3),
		width: '100%',
	},
})

function AddMeetingForm(props) {
	const { classes, epic } = props;
	const [startTime, setStartTime] = useState(undefined);
	const [attendees, setAttendees] = useState([]);
	const [notes, setNotes] = useState("");
	const [helperTexts, setHelperTexts] = useState({});

	const members = epic.Mentees.Curr.concat(epic.Mentors)
	if (!members.some(member => member.Id === epic.Project.Owner.Id)) {
		members.push(epic.Project.Owner);
	}

	useEffect(() => {
		setStartTime(new Date());
	}, [])

	const handleAttendeeListChange = (event, value, reason) => {
		setAttendees(value);
		if ('attendees' in helperTexts) {
			const { attendees, ...others } = helperTexts;
			setHelperTexts({ ...others })
		}
	}

	const handleNotesChange = (event) => {
		setNotes(event.target.value);
		if ('meetingNotes' in helperTexts) {
			const { meetingNotes, ...others } = helperTexts;
			setHelperTexts({ ...others })
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		const newHelperTexts = Object.create(helperTexts);
		if (attendees.length === 0) {
			newHelperTexts['attendees'] = "Specify at least one attendee";
		}
		if (notes.length === 0) {
			newHelperTexts['meetingNotes'] = "You cannot record empty meeting notes";
		}
		if (Object.keys(newHelperTexts).length !== 0) {
			setHelperTexts(newHelperTexts);
			return;
		}
		const newMeeting = {
			StartTime: startTime.valueOf(),
			EndTime: (new Date()).valueOf(),
			Attendees: attendees,
			Notes: notes,
		};

		props.handleMeetingEnd(newMeeting);
	}

	return (
		<form className={classes.form}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Autocomplete
						multiple
						fullWidth
						id="attendees"
						options={members}
						getOptionLabel={(option) => option.Name + " (" + option.GitHubId + ")"}
						value={attendees}
						filterSelectedOptions
						onChange={handleAttendeeListChange}
						getOptionSelected={(option, value) => (option.Id === value.Id)}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Attendees"
								placeholder="John Doe"
								required
								error={'attendees' in helperTexts}
								helperText={helperTexts.attendees}
							/>
						)}
						disabled={props.isFormDisabled}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						required
						fullWidth
						id="meetingNotes"
						label="Meeting Notes"
						name="meetingNotes"
						autoComplete="meetingNotes"
						multiline
						rows={8}
						rowsMax={16}
						onChange={handleNotesChange}
						value={notes}
						disabled={props.isFormDisabled}
						error={'meetingNotes' in helperTexts}
						helperText={helperTexts.meetingNotes}
					/>
				</Grid>
				<Grid item xs={10}>
					<Button
						fullWidth
						variant="contained"
						color="secondary"
						onClick={handleSubmit}
						analyticsObj={props.analyticsObj}
						disabled={props.isFormDisabled}
					>
						End Meeting
					</Button>
				</Grid>
				<Grid item xs={2}>
					<Hidden xsDown>
						<Button
							fullWidth
							variant="outlined"
							color="secondary"
							onClick={props.handleMeetingCancel}
							analyticsObj={props.analyticsObj}
							disabled={props.isFormDisabled}
						>
							Cancel
						</Button>
					</Hidden>
					<Hidden smUp>
						<IconButton
							color="secondary"
							edge="start"
							className={classes.cancelIconButton}
							onClick={props.handleMeetingCancel}
							analyticsObj={props.analyticsObj}
							disabled={props.isFormDisabled}
						>
							<Close />
						</IconButton>

					</Hidden>
				</Grid>
			</Grid>
		</form>
	)
}

export default withStyles(styles)(AddMeetingForm);