import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '../mui_modules/components/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '../mui_modules/components/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';
import AppFooter from '../mui_modules/views/AppFooter';
import AppAppBar from '../mui_modules/views/AppAppBar';
import { saveAuthToken, saveUserId } from "../util/firebaseAuth";
import { tradeMarkedAlokit } from "../util/uiUtil"

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: theme.spacing(8),
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	progess: {
		position: 'absolute'
	}
});

class Signup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			phoneNumber: '',
			email: '',
			password: '',
			confirmPassword: '',
			errors: [],
			loading: false
		};

		this.analyticsObj = {
			source: "Signup",
			page: "Signup",
		};
	}

	static getDerivedStateFromProps(nextProps) {
		if (nextProps.UI) {
			if (nextProps.UI.errors) {
				return { errors: nextProps.UI.errors };
			}
			else return null;
		}
		else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.UI) {
			if (this.props.UI.errors) {
				//Perform some operation here
				this.setState({ errors: this.props.UI.errors });
				this.classMethod();
			}
		}
	}


	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ loading: true });
		const anonymousId = localStorage.getItem('AnonymousId');
		const newUserData = {
			name: this.state.name,
			phoneNumber: this.state.phoneNumber,
			email: this.state.email,
			password: this.state.password,
			confirmPassword: this.state.confirmPassword,
			anonymousId: anonymousId
		};
		axios
			.post(this.props.api_url + '/signup', newUserData)
			.then((response) => {
				saveAuthToken(response.data.token);
				saveUserId(response.data.userId);
				this.setState({
					loading: false,
				});
				this.props.history.push('/learn');
			})
			.catch((error) => {
				this.setState({
					errors: error.response.data,
					loading: false
				});
			});
	};

	render() {
		const { classes } = this.props;
		const { errors, loading } = this.state;
		return (
			<React.Fragment>
				<CssBaseline />
				<AppAppBar />
				<Container component="main" maxWidth="xs">
					<div className={classes.paper}>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign up to {tradeMarkedAlokit}
						</Typography>
						<form className={classes.form} noValidate>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										autoFocus
										id="name"
										label="Name"
										name="name"
										autoComplete="name"
										helperText={errors.name}
										error={errors.name ? true : false}
										onChange={this.handleChange}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="phoneNumber"
										label="Phone Number"
										name="phoneNumber"
										autoComplete="phoneNumber"
										pattern="[6-9]{1}[0-9]{9}"
										helperText={errors.phoneNumber}
										error={errors.phoneNumber ? true : false}
										onChange={this.handleChange}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										autoComplete="email"
										helperText={errors.email}
										error={errors.email ? true : false}
										onChange={this.handleChange}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="password"
										label="Password"
										name="password"
										type="password"
										autoComplete="current-password"
										helperText={errors.password}
										error={errors.password ? true : false}
										onChange={this.handleChange}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										name="confirmPassword"
										label="Confirm Password"
										type="password"
										id="confirmPassword"
										autoComplete="current-password"
										helperText={errors.confirmPassword}
										error={errors.confirmPassword ? true : false}
										onChange={this.handleChange}
									/>
								</Grid>
							</Grid>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								onClick={this.handleSubmit}
								analyticsObj={this.analyticsObj}
								paddedButton={false}
								disabled={loading ||
									!this.state.email ||
									!this.state.password ||
									!this.state.name ||
									!this.state.phoneNumber}
							>
								Sign Up
								{loading && <CircularProgress size={30} className={classes.progess} />}
							</Button>
							<Grid container justify="flex-end">
								<Grid item>
									<Link href="login" variant="body2" analyticsObj={this.analyticsObj}>
										Already have an account? Sign in
									</Link>
								</Grid>
							</Grid>
						</form>
					</div>
				</Container>
				<AppFooter pageName="SignUp" />
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(Signup);

