import React from 'react';
import axios from 'axios';

import { CssBaseline, withStyles, Grid, Container, Divider } from '@material-ui/core';
import LearnAppBar from './views/LearnAppBar';
import AppFooter from '../../mui_modules/views/AppFooter';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import { gAnalytics } from '../../util/analytics';
import { authMiddleWare } from '../../util/auth';
import ProjectProfileDrawer from './views/ProjectProfileDrawer';
import Typography from '../../mui_modules/components/Typography';
import githubIcon from "../../resources/images/github.png";
import Avatar from '@material-ui/core/Avatar';
import { Alert, AlertTitle } from '@material-ui/lab';
import Link from '../../mui_modules/components/Link'
import EpicList from './views/EpicList';
import { removeAllSavedCookies } from '../../util/firebaseAuth';

const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		top: '35%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	container: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	name: {
		marginTop: theme.spacing(2),
	},
	githubLink: {
		display: 'flex',
		'& > *': {
			margin: theme.spacing(0.5),
		},
		marginBottom: theme.spacing(2),
	},
	githubIcon: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
});

class ProjectPage extends React.Component {
	constructor(props) {
		super(props);

		this.projectId = this.props.match.params.projectId;
		this.project = null;
		this.epics = null;

		var perfEntries = performance.getEntriesByType("navigation");
		if (perfEntries[0].type === "back_forward") {
			window.location.reload(true);
		}

		this.state = {
			uiLoading: true,
		};

		gAnalytics.logEvent('screen_view', {
			firebase_screen: 'ProjectPage',
		});
	}

	componentDidMount = () => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		// FIXME: We know that this information about the project was already fetched in the previous page
		// We do not have to fetch this information again. We only have to fetch all the epics of this project
		axios
			.get(this.props.api_url + '/project/' + this.projectId)
			.then((response) => {
				console.log(response.data);
				this.project = response.data;
				this.project.Id = this.projectId;
				this.setState({
					uiLoading: false,
				});
			})
			.catch((error) => {
				console.log(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				this.setState({
					errorMsg: 'Error in retrieving the profile data for user ' + this.userId,
					uiLoading: false,
				});
			});
	};

	render() {
		const { classes, history } = this.props;
		const analyticsObj = {
			source: "ProjectPage",
			page: "ProjectPage",
		};
		if (this.state.uiLoading === true) {
			return (
				<div className={classes.loadingRoot}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</div>
			);
		} else if (this.state.errorMsg) {
			return (
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					{this.state.errorMsg}
				</Alert>
			)
		} else {
			return (
				<section>
					<CssBaseline />
					<LearnAppBar pageName={"Project"} history={history} />
					<Container className={classes.container}>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="h5" className={classes.name}>
									&emsp;<ClassOutlinedIcon /> {this.project.Name}
								</Typography>
								{(this.project.GitHubRepo) ?
									<Link
										href={this.project.GitHubRepo}
										className={classes.githubLink}
										analyticsObj={analyticsObj}
									>
										<Avatar src={githubIcon} className={classes.githubIcon} />
										<Typography variant="body1" color="textSecondary">{this.project.GitHubRepo.split("/").pop()}</Typography>
									</Link>
									: null
								}
								<Divider variant="middle" />
							</Grid>

							<Grid item xs={12} sm={4} >
								<ProjectProfileDrawer pageName={"Project"} {...this.project} />
							</Grid>
							<Grid item xs={12} sm={8} >
								<EpicList project={this.project} api_url={this.props.api_url} pageName="Project" history={this.props.history} />
							</Grid>
						</Grid>
					</Container>
					<AppFooter pageName="Project" />
				</section>
			)
		}
	}
}

export default withStyles(styles)(ProjectPage);