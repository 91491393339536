import React from 'react';
import PropTypes from 'prop-types';
import GoogleForm from '../components/GoogleForm';
import { withStyles, Container } from '@material-ui/core';

const styles = (theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.default,
	},
	container: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
		},
		[theme.breakpoints.up('md')]: {
			width: theme.breakpoints.values.md,
		},
	},
})

function MentorForm(props) {
	const { classes, pageName } = props;
	const analyticsObj = {
		source: "MenteeForm",
		page: pageName,
	};

	return (
		<section className={ classes.root } >
			<Container className={ classes.container } >
				<GoogleForm
					title="Become a mentor: Application Form"
					src="https://docs.google.com/forms/d/e/1FAIpQLSf4a7w6XUPOHW6VOmLmVgyNBu3glI9xlLfm8Xn4dgc76NvD4Q/viewform?embedded=true"
					height="3100"
					analyticsObj={ analyticsObj }
				/>
			</Container>
		</section>
	)
}

MentorForm.propTypes = {
	classes: PropTypes.object.isRequired,
	pageName: PropTypes.string,
};

export default withStyles(styles)(MentorForm);