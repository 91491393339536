import React from 'react';
import AppAppBar from "../mui_modules/views/AppAppBar";
import withRoot from "../mui_modules/withRoot";
import { CssBaseline } from '@material-ui/core';
import AppFooter from '../mui_modules/views/AppFooter';

class People extends React.Component {
	render() {
		return (
			<React.Fragment>
				<CssBaseline />
				<AppAppBar pageName={ "People" } />
				{/* <StarMentees pageName={ "People" } />
				<StarMentors pageName={ "People" } />
				<JoinOurCommunity pageName={ "People" } />
				<PlatformTeam pageName={ "People" } /> */}
				<AppFooter pageName={ "People" } />
			</React.Fragment>
		)
	}
}

export default withRoot(People);