import React from 'react';
import { withStyles, Container, Divider } from '@material-ui/core';
import Button from '../components/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Captcha from './Captcha';

import axios from 'axios';

import facebookIcon from '../../resources/images/facebook.png';
import linkedinIcon from '../../resources/images/linkedin.png';
import instagramIcon from '../../resources/images/instagram.svg';

import { gAnalytics } from "../../util/analytics";

const styles = (theme) => ({
	root: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: theme.spacing(8),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	progess: {
		position: 'absolute'
	},
	section: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		width: '100%',
	},
	socialMediaTitle: {
		marginBottom: theme.spacing(2),
	},
})

class ContactForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			email: '',
			message: '',
			errors: [],
			loading: false,
			alertOpen: false,
			alertSeverity: '',
			alertMessage: '',
			isCaptchaValid: false,
		}

		this.handleCaptchaValid = this.handleCaptchaValid.bind(this);
		this.handleExpired = this.handleExpired.bind(this);

		this.analyticsObj = {
			source: "ContactForm",
			page: this.props.pageName,
		};
	}

	handleAlertOpen = (severity, message) => {
		this.setState({ ...this.state, alertOpen: true, alertSeverity: severity, alertMessage: message });
	};

	handleAlertClose = () => {
		this.setState({ ...this.state, alertOpen: false });
	};

	static getDerivedStateFromProps(nextProps) {
		if (nextProps.UI) {
			if (nextProps.UI.errors) {
				return { errors: nextProps.UI.errors };
			}
			else return null;
		}
		else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.UI) {
			if (this.props.UI.errors) {
				//Perform some operation here
				this.setState({ errors: this.props.UI.errors });
				this.classMethod();
			}
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	resetForm = () => {
		this.contactFormRef.reset();
	}

	handleCaptchaValid(value) {
		const grecaptchaObject = window.grecaptcha;
		return new Promise(function (resolve, reject) {
			if (value) {
				resolve();
			}
			else {
				reject();
			}
		}).then(() => {
			this.setState({ isCaptchaValid: true });
		}).catch((err) => {
			this.handleAlertOpen("error", "Captcha not validated! Please try Again.");
			this.resetForm();
			grecaptchaObject.reset();
			this.setState({ isCaptchaValid: false });
			console.error("error:", err);
		});
	}

	handleExpired() {
		return new Promise(function (resolve, reject) {
			resolve();
		}).then(() => {
			this.setState({ isCaptchaValid: false });
		}).catch((err) => {
			this.handleAlertOpen("error", "Captcha not validated! Please try Again.");
			this.setState({ isCaptchaValid: false });
			console.error("error:", err);
		});
	}

	handleSubmit = (event) => {
		const grecaptchaObject = window.grecaptcha;
		event.preventDefault();
		this.setState({ loading: true });
		const anonymousId = localStorage.getItem('AnonymousId');
		const contactUsData = {
			name: this.state.name,
			email: this.state.email,
			message: this.state.message,
			anonymousId: anonymousId
		};
		axios
			.post(this.props.api_url + '/contact', contactUsData)
			.then((response) => {
				this.setState({
					loading: false,
				});
				if (response.data.status === 'success') {
					this.handleAlertOpen("success", "Message Sent");
					gAnalytics.logEvent('contact_form_success', contactUsData);
					this.resetForm();
				}
				else if (response.data.status === 'fail') {
					this.handleAlertOpen("error", "Failed to send message");
					gAnalytics.logEvent('contact_form_failure', contactUsData);
				}
			})
			.catch((error) => {
				this.setState({
					errors: error.response.data,
					loading: false
				});
			});
		grecaptchaObject.reset();
		this.setState({ isCaptchaValid: false });
	};

	render() {
		const { classes } = this.props;
		const { errors, loading } = this.state;

		return (
			<Container component="main" maxWidth="sm">
				<div className={classes.root}>
					<div className={classes.section}>
						<Typography component="h1" variant="h5">
							Get in touch with us on email
						</Typography>
						<form className={classes.form} noValidate ref={(el) => this.contactFormRef = el} >
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="name"
										label="Name"
										name="name"
										autoComplete="name"
										helperText={errors.name}
										error={errors.name ? true : false}
										onChange={this.handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										autoComplete="email"
										helperText={errors.email}
										error={errors.email ? true : false}
										onChange={this.handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										multiline
										rows={4}
										rowsMax={8}
										id="message"
										label="Your Message"
										name="message"
										helperText={errors.message}
										error={errors.message ? true : false}
										onChange={this.handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={12}>
									<Captcha handleCaptchaValid={this.handleCaptchaValid} handleExpired={this.handleExpired} />
								</Grid>
							</Grid>

							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								onClick={this.handleSubmit}
								paddedButton={true}
								analyticsObj={this.analyticsObj}
								disabled={loading ||
									!this.state.email ||
									!this.state.name ||
									!this.state.message ||
									!this.state.isCaptchaValid}
							>
								Send Message
								{loading && <CircularProgress size={30} className={classes.progess} />}
							</Button>
						</form>
						<Snackbar open={this.state.alertOpen} autoHideDuration={6000} onClose={this.handleAlertClose}>
							<Alert onClose={this.handleAlertClose} severity={this.state.alertSeverity}>
								{this.state.alertMessage}
							</Alert>
						</Snackbar>
					</div>

					<Grid className={classes.section}>
						<Divider variant="fullWidth" orientation="horizontal" />
					</Grid>

					<div className={classes.section}>
						<Typography component="h1" variant="h5" className={classes.socialMediaTitle}>
							Or drop us a message on social media
						</Typography>
						<Grid container alignItems="center">
							<Grid item xs={12} sm={4}>
								<Button
									variant="text"
									className={classes.icon}
									href="https://www.linkedin.com/company/alokit-innovations"
									target="_blank"
									paddedButton={false}
									startIcon={<img src={linkedinIcon} alt="LinkedIn" width="32" height="32" />}
									analyticsObj={this.analyticsObj}
								>
									LinkedIn
								</Button>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Button
									variant="text"
									className={classes.icon}
									href="https://www.facebook.com/Alokit-Innovations-109094627516849"
									target="_blank"
									paddedButton={false}
									startIcon={<img src={facebookIcon} alt="Facebook" width="32" height="32" />}
									analyticsObj={this.analyticsObj}
								>
									Facebook
								</Button>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Button
									variant="text"
									className={classes.icon}
									href="https://www.instagram.com/alokit.in/"
									target="_blank"
									paddedButton={false}
									startIcon={<img src={instagramIcon} alt="Instagram" width="32" height="32" />}
									analyticsObj={this.analyticsObj}
								>
									Instagram
								</Button>
							</Grid>
						</Grid>
					</div>
				</div>
			</Container>
		)
	}
}

export default withStyles(styles)(ContactForm);