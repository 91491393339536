import React from 'react';
import PropTypes from 'prop-types';
import WifiOutlinedIcon from '@material-ui/icons/WifiOutlined';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import githubIcon from '../../resources/images/github.png'
import Typography from '../components/Typography';
import { withStyles, Container, List, ListItem, Link, ListItemText, ListItemIcon, ListItemAvatar, Avatar } from '@material-ui/core';

const styles = (theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.default,
		overflow: 'hidden',
	},
	container: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(10),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
		},
		[theme.breakpoints.up('md')]: {
			width: theme.breakpoints.values.md,
		},
	},
	title: {
		marginBottom: theme.spacing(3),
	}
})

function MenteeEligibility(props) {
	const { classes } = props;

	return (
		<section className={classes.root}>
			<Container className={classes.container}>
				<Typography variant="h4" marked="center" className={classes.title} component="h2">
					Eligibility
				</Typography>
				{/* <Typography variant="body1"></Typography> */}
				<List >
					<ListItem alignItems="flex-start">
						<ListItemIcon>
							<WifiOutlinedIcon color="secondary" fontSize="large" />
						</ListItemIcon>
						<ListItemText
							primary="Computer and Internet"
							secondary="You must have a computer (desktop or laptop) with a decent internet connection; good enough for video meetings"
						/>
					</ListItem>
					<ListItem alignItems="flex-start">
						<ListItemIcon>
							<CodeOutlinedIcon color="secondary" fontSize="large" />
						</ListItemIcon>
						<ListItemText
							primary="Coding Skills"
							secondary="We expect you to have decent coding skills (it can be in any programming language)"
						/>
					</ListItem>
					<ListItem alignItems="flex-start">
						<ListItemIcon>
							<ScheduleOutlinedIcon color="secondary" fontSize="large" />
						</ListItemIcon>
						<ListItemText
							primary="Time"
							secondary="You must be able to dedicate at least 15 hours per week throughout the duration of the project"
						/>
					</ListItem>
					<ListItem alignItems="flex-start">
						<ListItemAvatar>
							<Avatar alt="Github Icon" src={githubIcon} color="secondary" /> 
						</ListItemAvatar>
						<ListItemText
							primary="Github account"
							secondary={
								<React.Fragment>
								{"All the projects will be hosted on Github. So if you don't have one, create a "}
								<Link href='https://github.com/' target='_blank'>GitHub</Link> 
								{" account."}
								</React.Fragment>
								}
						/>
					</ListItem>
				</List>
			</Container>
		</section>
	)
}

MenteeEligibility.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenteeEligibility);