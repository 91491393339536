import React from 'react';
import axios from 'axios';
import { userChipRender, EpicStates } from '../../../util/uiUtil';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Backdrop, CircularProgress } from '@material-ui/core';
import Button from '../../../mui_modules/components/Button';
import Typography from '../../../mui_modules/components/Typography';
import { withStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import DiscreteSliderMarks from '../../../mui_modules/components/Slider';

const styles = (theme) => ({
	mentors: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
		},
	},
	alertMessage: {
		fontSize: '0.8rem',
		color: "#dd0011",
	},
	mentees: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	menteesDiv: {
		marginTop: theme.spacing(0.5)
	},
	slider: {
		paddingLeft: '6px',
		paddingRight: '48px'
	},
	overlay: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: '#0009',
		color: '#fff',
	},
	depositedChip: {
		color: "#000000",
		backgroundColor: "#9e9e9e",
	},
	notDepositedChip: {
		color: "#000000",
		backgroundColor: "#bdbdbd"
	}
})

class LaunchEpicDialog extends React.Component {
	constructor(props) {
		super(props);

		this.usersDataOfEpic = null;
		this.usersWithMentorRole = [];
		this.usersWithMenteeRole = [];
		this.numberOfMenteesDeposited = 0;

		this.state = {
			allUsersOfEpicLoading: true,
		}
	}

	componentDidMount() {
		axios
			.get(this.props.api_url + `/users/projects/` + this.props.Project.Id + `/epics/` + this.props.Id)
			.then((allUsersOfEpic) => {
				this.usersDataOfEpic = allUsersOfEpic.data;
				this.usersWithMentorRole = this.usersDataOfEpic.filter((userData) => (userData ? userData.Role === "Mentor" : null));
				this.usersWithMenteeRole = this.usersDataOfEpic.filter((userData) => (userData ? userData.Role === "Mentee" : null));
				this.usersWithMenteeRole.forEach((user) => {
					if (user.Deposited) {
						this.numberOfMenteesDeposited += 1;
					}
				})
				this.setState({
					allUsersOfEpicLoading: false,
				});
			})
			.catch((error) => {
				console.error(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login')
				} else {
					if (error.response != null && error.response.data != null && error.response.data.message != null) {
						for (let msg of error.response.data.message) {
							this.props.enqueueSnackbar(msg, { variant: "error" })
						}
					} else {
						this.props.enqueueSnackbar('Error in retrieving the data', { variant: "error" });
					}
					this.setState({
						allUsersOfEpicLoading: false,
					});
				}
			})
	}

	contributorChipInfo = (contributor, analyticsObj, Id, depositedChipClasses, notDepositedChipClasses) => {
		return (
			<Tooltip title={(contributor.Deposited) ? "Deposited" : " Not deposited"} arrow placement='bottom' key={Id}>
				{userChipRender(contributor, analyticsObj, Id, (contributor.Deposited ? true : false), (contributor.Deposited ? depositedChipClasses : notDepositedChipClasses))}
			</Tooltip>
		)
	}

	render() {
		const { classes } = this.props;
		if (this.state.allUsersOfEpicLoading === true) {
			return (
				<Backdrop className={classes.overlay} open={this.state.allUsersOfEpicLoading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			);
		} else {
			return (
				<Dialog maxWidth="sm" open={this.props.launchEpicDialogOpen} onClose={this.props.handleLaunchEpicDialogClose} scroll="body">
					<DialogTitle id="responsive-dialog-title">
						{"Launch Epic Confirmation"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							All contributors who haven't bought in yet, will not be able to see the stories of this epic until they buy-in. Confirm, if you wish to launch the epic anyway.
						</DialogContentText>
						<DialogContentText>
							<div>
								<b><Typography variant="h6" className={classes.subHeadings}>Mentors</Typography></b>
							</div>
							<div className={classes.mentors}>
								{(this.usersWithMentorRole.length) ? (this.usersWithMentorRole.map((mentor) => this.contributorChipInfo(mentor, this.props.analyticsObj, mentor.Id, classes.depositedChip, classes.notDepositedChip)))
									: <Typography variant="h5" className={classes.alertMessage}> There should be atleast one mentor in the epic.</Typography>
								}
							</div>
							<div className={classes.menteesDiv}>
								<b><Typography variant="h6" className={classes.subHeadings}>Mentees</Typography></b>
							</div>
							<div className={classes.mentees}>
								{(this.usersWithMenteeRole.length) ? (this.usersWithMenteeRole.map((mentee) => this.contributorChipInfo(mentee, this.props.analyticsObj, mentee.Id, classes.depositedChip, classes.notDepositedChip)))
									: <Typography variant="h5" className={classes.alertMessage}> There should be atleast one mentee in the epic.</Typography>
								}
							</div>
							<div className={classes.slider}>
								<DiscreteSliderMarks maxVal={this.props.Mentees.Max} marksVal={(this.props.Mentees.Curr.length < this.props.Mentees.Max ? this.props.Mentees.Curr.length : this.props.Mentees.Max)} progressVal={(this.numberOfMenteesDeposited < this.props.Mentees.Max ? this.numberOfMenteesDeposited : this.props.Mentees.Max)} minVal={this.props.Mentees.Min} />
							</div>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.handleLaunchEpicDialogClose} analyticsObj={this.props.analyticsObj} color="secondary">
							Cancel
						</Button>
						<Button onClick={() => this.props.handleSetEpicState(EpicStates.ONGOING)} analyticsObj={this.props.analyticsObj} color="secondary" variant="contained">
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)
		}
	}
}

export default withStyles(styles)(withSnackbar(LaunchEpicDialog));