import React from 'react';
import { withStyles, Grid, Dialog, TextField, Backdrop, CircularProgress, Divider } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography, IconButton, Button, AlertDialog, Markdown } from '../../../mui_modules/components';
import { authMiddleWare } from '../../../util/auth';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import { isProjectOwner, userChipRender, isMentor, isMentee, EpicStates, ifValueExists } from '../../../util/uiUtil';

import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { withSnackbar } from 'notistack';
import WithdrawalDialog from './WithdrawalDialog';
import UserActionButtonOnEpic from './UserActionButtonOnEpic';
import EndEpicConfirmationDialog from './EndEpicConfirmationDialog';
import LaunchEpicDialog from './LaunchEpicDialog';
import InviteUserView from './InviteUserView';

const styles = (theme) => ({
	contents: {
		flexGrow: 1,
		flexShrink: 0,
		padding: theme.spacing(3),
	},
	sectionHeading: {
		marginTop: theme.spacing(2),
	},
	divider: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	mentors: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	overlay: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: '#0009',
		color: '#fff',
	},
	mentees: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	toolbar: theme.mixins.toolbar,
	dialogSubmit: {
		height: '100%',
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
})

class EpicProfileDrawer extends React.Component {

	constructor(props) {
		super(props);

		this.userId = localStorage.getItem('UserId');

		this.state = {
			editMentor: false,
			editMentee: false,
			allUsersLoading: true,
			allUsers: null,
			// state variables used for capturing input
			mentors: this.props.Mentors,
			mentees: this.props.Mentees.Curr,
			// state variables used for reflecting the true values
			Mentors: this.props.Mentors,
			Mentees: this.props.Mentees.Curr,
			apiLoading: false,
			// status:null,
			dialogOpen: false,
			projectOwnerBuyInDeposit: false,
			endEpicDialogOpen: false,
			finalConfirmationDialogOpen: false,
			launchEpicDialogOpen: false,
		}

		this.analyticsObj = {
			source: "ProjectProfileDrawer",
			page: this.props.pageName,
		};
	}

	handlePaymentOpen = (data) => {
		this.setState({
			dialogOpen: true,
		});
	}

	handleDialogClose = (event) => {
		this.setState({
			dialogOpen: false
		});
	}

	handleMentorEditClick = () => {
		this.setState({ editMentor: true });
	}

	handleMentorEditClose = () => {
		this.setState({ editMentor: false });
	}

	handleMentorChange = (event, value, reason) => {
		this.setState({
			mentors: value
		});
	};

	handleMentorSubmit = (event) => {
		event.preventDefault();
		this.setState({ apiLoading: true });
		this.handleMentorEditClose();
		axios
			.post(
				this.props.api_url + `/projects/${this.props.projectId}/epics/${this.props.epicId}/mentors`,
				{
					Mentors: this.state.mentors
				}
			)
			.then((response) => {
				for (const message of response.data.messages) {
					this.props.enqueueSnackbar(message.text, { variant: message.severity });
				}
				this.setState({
					Mentors: response.data.mentors,
					apiLoading: false,
				})
			})
			.catch((error) => {
				console.error(error);
				this.props.enqueueSnackbar('Error in updating this epic\'s mentors', { variant: "error" });
				this.setState({ apiLoading: false });
			})
	};

	handleMenteeEditClick = () => {
		this.setState({ editMentee: true });
	}

	handleMenteeEditClose = () => {
		this.setState({ editMentee: false });
	}

	handleMenteeChange = (event, value, reason) => {
		this.setState({
			mentees: value
		});
	};

	handleMenteeSubmit = (event) => {
		event.preventDefault();
		this.setState({ apiLoading: true });
		this.handleMenteeEditClose();
		axios
			.post(
				this.props.api_url + `/projects/${this.props.projectId}/epics/${this.props.epicId}/mentees`,
				{
					Mentees: this.state.mentees
				}
			)
			.then((response) => {
				for (const message of response.data.messages) {
					this.props.enqueueSnackbar(message.text, { variant: message.severity });
				}
				this.setState({
					Mentees: response.data.mentees,
					apiLoading: false,
				})
			})
			.catch((error) => {
				console.error(error);
				this.props.enqueueSnackbar('Error in updating this epic\'s mentees', { variant: "error" });
				this.setState({ apiLoading: false });
			})
	};

	handleSetEpicState = (epicState) => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.put(this.props.api_url + `/projects/${this.props.projectId}/epics/${this.props.epicId}`, { State: epicState })
			.then((response) => {
				console.log(response.data.message);
				window.location.reload();
			})
			.catch((error) => {
				console.error(error);
				this.props.enqueueSnackbar('Error in updating this epic\'s state', { variant: "error" });
			})
	}

	decideEpicState = (userEpic) => {
		if (this.props.State && ifValueExists(EpicStates, this.props.State)) {
			if (this.props.State === EpicStates.PENDING && isProjectOwner(this.props.Project)) {
				if (userEpic.Deposited) {
					this.handleSetEpicState(EpicStates.OPEN);
				};
			}
		} else {
			this.handleSetEpicState(EpicStates.PENDING);
		}
	}

	handleEndEpicDialogClose = (event) => {
		this.setState({
			endEpicDialogOpen: false
		});
	}

	handleEndEpicDialogOpen = (event) => {
		this.setState({
			endEpicDialogOpen: true
		});
	}

	handleFinalDialogClose = (event) => {
		this.setState({
			finalConfirmationDialogOpen: false
		});
	}

	handleFinalDialogOpen = (event) => {
		this.setState({
			finalConfirmationDialogOpen: true,
			endEpicDialogOpen: false
		})
	}

	handleLaunchEpicDialogClose = (event) => {
		this.setState({
			launchEpicDialogOpen: false
		});
	}

	handleLaunchEpicDialogOpen = (event) => {
		this.setState({
			launchEpicDialogOpen: true
		});
	}

	componentDidMount() {
		this.decideEpicState(this.props.userEpic);
		axios
			.get(this.props.api_url + `/users`)
			.then((userDocs) => {
				this.setState({
					allUsers: userDocs.data,
					allUsersLoading: false,
				});
			})
			.catch((error) => {
				console.error(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login')
				}
				if (error.response != null && error.response.data != null && error.response.data.message != null) {
					for (let msg of error.response.data.message) {
						this.props.enqueueSnackbar(msg, { variant: "error" })
					}
				} else {
					this.props.enqueueSnackbar('Error in retrieving the user data', { variant: "error" });
				}
			})
	}

	render() {
		const { classes, ...otherProps } = this.props;

		dayjs.extend(relativeTime);
		return (
			<section className={classes.contents} >
				<Backdrop className={classes.overlay} open={this.state.apiLoading}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<div>
					{(isMentee(this.props) || isMentor(this.props) || (isProjectOwner(this.props.Project))) ?
						(!this.state.allUsersLoading) ?
							<UserActionButtonOnEpic
								source={this.analyticsObj.source}
								pageName={this.analyticsObj.page}
								api_url={this.props.api_url}
								handlePaymentOpen={this.handlePaymentOpen}
								userEpic={this.props.userEpic}
								epic={this.props}
								handleSetEpicState={this.handleSetEpicState}
								handleEndEpicDialogOpen={this.handleEndEpicDialogOpen}
								handleLaunchEpicDialogOpen={this.handleLaunchEpicDialogOpen}
							/>
							:
							<Button variant="contained" fullWidth analyticsObj={this.analyticsObj} style={{ maxHeight: '40px', minHeight: '40px' }}>
								<CircularProgress size={20} />
							</Button>
						: null}
				</div>
				<b><Typography variant="h6" className={classes.sectionHeading}>Description</Typography></b>
				<Markdown variant="body1" align="justify">
					{this.props.Description}
				</Markdown>

				<Divider className={classes.divider} />

				<b><Typography variant="h6" className={classes.sectionHeading}>Duration</Typography></b>
				<Typography variant="body1" align="justify" >
					{this.props.DurationInWeeks} Weeks
				</Typography>

				<Divider className={classes.divider} />

				<Grid container className={classes.sectionHeading} alignItems="center">
					<Grid item xs={10}>
						<b><Typography variant="h6">Mentors</Typography></b>
					</Grid>
					<Grid item xs={2}>
						{(isProjectOwner(this.props.Project)) ?
							<IconButton
								aria-label="edit"
								onClick={this.handleMentorEditClick}
								disabled={this.allUsersLoading}
								analyticsObj={this.analyticsObj}
							>
								<EditIcon />
							</IconButton>
							: null}
					</Grid>
				</Grid>
				<div className={classes.mentors} >
					{this.state.Mentors.map((mentor) => userChipRender(mentor, this.analyticsObj, mentor.Id))}
				</div>

				{(isProjectOwner(this.props.Project)) ?
					<Dialog open={this.state.editMentor} onClose={this.handleMentorEditClose} fullWidth >
						<form className={classes.form} noValidate>
							<Grid container>
								<Grid item xs={10}>
									{/* TODO: Show DP avatar on options and values */}
									<Autocomplete
										multiple
										fullWidth
										options={this.state.allUsers}
										getOptionLabel={(option) => option.Name + " (" + option.GitHubId + ")"}
										// defaultValue={[allUsers[13]]}
										value={this.state.mentors}
										filterSelectedOptions
										id="mentors"
										name="mentors"
										onChange={this.handleMentorChange}
										getOptionSelected={(option, value) => {
											if (option.Id === value.Id) {
												return true;
											} else {
												return false;
											}
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Mentors"
												placeholder="John Doe"
											/>
										)}
									/>
								</Grid>
								<Grid item xs={2}>
									<Button
										fullWidth
										color="primary"
										variant="contained"
										onClick={this.handleMentorSubmit}
										analyticsObj={this.analyticsObj}
										className={classes.dialogSubmit}
									>
										{this.props.buttonType === 'Edit' ? 'Save' : 'Submit'}
									</Button>
								</Grid>
							</Grid>
						</form>
					</Dialog>
					: null
				}

				<Divider className={classes.divider} />

				<Grid container className={classes.sectionHeading} alignItems="center">
					<Grid item xs={10}>
						<b><Typography variant="h6">Mentees</Typography></b>
					</Grid>
					<Grid item xs={2}>
						{((isProjectOwner(this.props.Project)) || (isMentor(this.props))) ?
							<IconButton
								aria-label="edit"
								onClick={this.handleMenteeEditClick}
								disabled={this.allUsersLoading}
								analyticsObj={this.analyticsObj}
							>
								<EditIcon />
							</IconButton>
							: null
						}
					</Grid>
				</Grid>
				<div className={classes.mentees} >
					{this.state.Mentees.map((mentee) => userChipRender(mentee, this.analyticsObj, mentee.Id))}
				</div>
				<Typography paragraph variant="body2" align="justify" color="textSecondary" >
					Capacity: {this.props.Mentees.Min} {(this.props.Mentees.Min !== this.props.Mentees.Max) ? `- ${this.props.Mentees.Max}` : null}
				</Typography>

				{((isProjectOwner(this.props.Project)) || (isMentor(this.props))) ?
					<Dialog open={this.state.editMentee} onClose={this.handleMenteeEditClose} fullWidth >
						<form className={classes.form} noValidate>
							<Grid container>
								<Grid item xs={10}>
									{/* TODO: It might be better to implement this with ChipInput library */}
									<Autocomplete
										multiple
										fullWidth
										options={this.state.allUsers}
										getOptionLabel={(option) => option.Name + " (" + option.GitHubId + ")"}
										// defaultValue={[allUsers[13]]}
										value={this.state.mentees}
										filterSelectedOptions
										name="mentees"
										onChange={this.handleMenteeChange}
										getOptionSelected={(option, value) => {
											if (option.Id === value.Id) {
												return true;
											} else {
												return false;
											}
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Mentees"
												placeholder="John Doe"
											/>
										)}
									/>
								</Grid>
								<Grid item xs={2}>
									<Button
										fullWidth
										color="primary"
										variant="contained"
										onClick={this.handleMenteeSubmit}
										analyticsObj={this.analyticsObj}
										className={classes.dialogSubmit}
									>
										{this.props.buttonType === 'Edit' ? 'Save' : 'Submit'}
									</Button>
								</Grid>
							</Grid>
						</form>
					</Dialog>
					: null
				}
				{
					this.props.State === EpicStates.FINISHED ?
						<WithdrawalDialog projectId={this.props.Project.Id} epicId={this.props.Id} api_url={this.props.api_url} userId={this.userId} analyticsObj={this.analyticsObj} history={this.props.history} enqueueSnackbar={this.props.enqueueSnackbar} openDialog={this.state.dialogOpen} handleDialogClose={this.handleDialogClose} />
						: null
				}
				<LaunchEpicDialog analyticsObj={this.analyticsObj} launchEpicDialogOpen={this.state.launchEpicDialogOpen} handleSetEpicState={this.handleSetEpicState} handleLaunchEpicDialogClose={this.handleLaunchEpicDialogClose} {...otherProps} />
				<EndEpicConfirmationDialog api_url={this.props.api_url} userEpic={this.props.userEpic} ProjectId={this.props.Project.Id} EpicId={this.props.Id} analyticsObj={this.analyticsObj} history={this.props.history} endEpicDialogOpen={this.state.endEpicDialogOpen} handleFinalDialogOpen={this.handleFinalDialogOpen} handleEndEpicDialogClose={this.handleEndEpicDialogClose} buyInValue={this.props.BuyIn.Amount} epicValue={this.props.Value.Amount} totalCredits={this.totalCredits} />
				<AlertDialog pageName={this.props.pageName} open={this.state.finalConfirmationDialogOpen} handleClose={this.handleFinalDialogClose}
					dialogTitle="End Epic Confirmation"
					dialogContent="Are you sure? Once you end the epic, you will not be able to go back to the previous state."
					cancelText="Cancel"
					confirmText="Confirm"
					handleConfirm={() => this.handleSetEpicState(EpicStates.FINISHED)}
				/>
				<Divider className={classes.divider} />

				{(isProjectOwner(this.props.Project) || isMentor(this.props)) ?
					<>
						<InviteUserView
							projectId={this.props.Project.Id}
							epicId={this.props.Id}
							api_url={this.props.api_url}
							history={this.props.history}
							analyticsObj={this.analyticsObj}
						/>
						<Divider className={classes.divider} />
					</>
					: null
				}

				<b><Typography variant="h6" className={classes.sectionHeading}>Created</Typography></b>
				<Typography variant="body1" align="justify" >
					{dayjs.unix(this.props.CreatedAt._seconds).fromNow()}
				</Typography>
			</section>

		)
	}
}

export default withStyles(styles)(withSnackbar(EpicProfileDrawer));
