import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { authMiddleWare } from '../../../util/auth';
import { CircularProgress, Collapse, Container, Divider, List, ListItem, ListItemText, withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button, Markdown } from '../../../mui_modules/components';
import { EpicStates, userChipRender } from '../../../util/uiUtil';
import { Add, ExpandLess, ExpandMore } from '@material-ui/icons';
import AddMeetingForm from './AddMeetingForm';
import { withSnackbar } from 'notistack';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import { getTimeDifferenceText, getCalendarTextForTime } from '../../../util/dateUtil';

const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	addButton: {
		marginTop: theme.spacing(1),
	},
	meetingNotes: {
		backgroundColor: theme.palette.background.paper,
		width: '90%',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(1),
		'& > *': {
			margin: theme.spacing(0.5),
			marginTop: theme.spacing(1),
		}
	}
});

function MeetingNotes(props) {
	const { classes, epic } = props;
	const [uiLoading, setUiLoading] = useState(true);
	const [meetings, setMeetings] = useState([]);
	const [meetingStarted, setMeetingStarted] = useState(false);
	const [meetingFormDisabled, setMeetingFormDisabled] = useState(false);
	const analyticsObj = {
		source: "MeetingNotes",
		page: props.pageName,
	};

	const toggleExpandMeeting = (meetingId) => () => {
		const meetingIndex = meetings.findIndex((value) => value.MeetingId === meetingId);
		const newMeetings = meetings.slice(0, meetingIndex).concat(
			[{ ...meetings[meetingIndex], expanded: !meetings[meetingIndex].expanded }],
			meetings.slice(meetingIndex + 1)
		);
		setMeetings(newMeetings);
	}

	const handleMeetingEnd = (newMeetingObj) => {
		setMeetingFormDisabled(true);
		authMiddleWare(props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios.post(props.api_url + `/projects/${epic.Project.Id}/epics/${epic.Id}/meeting`, newMeetingObj)
			.then((res) => {
				setMeetings([...meetings, {
					...res.data,
					expanded: false,
					StartTime: new Date(res.data.StartTime._seconds * 1000),
					EndTime: new Date(res.data.EndTime._seconds * 1000),
				}])
				setMeetingStarted(false);
			})
			.catch((err) => {
				if (err.response != null && err.response.status === 403) {
					removeAllSavedCookies();
					props.history.push('/login');
				}
				if (err.response != null && err.response.data != null & err.response.data.message != null) {
					for (let msg of err.response.data.message) {
						props.enqueueSnackbar(msg, { variant: "error" })
					}
				}
			})
			.finally(() => {
				setMeetingFormDisabled(false);
			})
	}

	useEffect(() => {
		authMiddleWare(props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get(props.api_url + `/projects/${epic.Project.Id}/epics/${epic.Id}/meetings`)
			.then((response) => {
				setMeetings(response.data.map(meeting => ({
					...meeting,
					expanded: false,
					StartTime: new Date(meeting.StartTime._seconds * 1000),
					EndTime: new Date(meeting.EndTime._seconds * 1000),
				})));
				setUiLoading(false);
			})
	}, [props.history, props.api_url, epic])

	if (uiLoading) {
		return (
			<div className={classes.loadingRoot}>
				{uiLoading && <CircularProgress size={150} />}
			</div>
		)
	} else {
		return (
			<Container>
				{(meetingStarted) ?
					<AddMeetingForm
						epic={epic}
						isFormDisabled={meetingFormDisabled}
						handleMeetingEnd={handleMeetingEnd}
						handleMeetingCancel={() => setMeetingStarted(false)}
						analyticsObj={analyticsObj}
					/>
					:
					<Button
						fullWidth
						variant="contained"
						color="primary"
						paddedButton={true}
						className={classes.addButton}
						onClick={() => setMeetingStarted(true)}
						analyticsObj={analyticsObj}
						disabled={epic.State === EpicStates.FINISHED}
						startIcon={<Add />}
					>
						Start Meeting
					</Button>
				}
				<List>
					{(meetings.length > 0) ?
						meetings.map(meeting => (
							<React.Fragment key={meeting.MeetingId}>
								<ListItem button onClick={toggleExpandMeeting(meeting.MeetingId)}>
									<ListItemText primary={`${getCalendarTextForTime(meeting.StartTime)}`} secondary={`(${getTimeDifferenceText(meeting.StartTime, meeting.EndTime)} long)`} />
									{meeting.expanded ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								<Collapse in={meeting.expanded} timeout="auto">
									<Container className={classes.meetingNotes}>
										Attendees: {meeting.Attendees.map(attendee => userChipRender(attendee, analyticsObj, attendee.Id, true))}
										<Divider />
										<Markdown>
											{meeting.Notes}
										</Markdown>
									</Container>
								</Collapse>
							</React.Fragment>
						))
						:
						<Alert severity='info' >No Meetings found</Alert>
					}
				</List>
			</Container>
		);
	}
}

export default withStyles(styles)(withSnackbar(MeetingNotes));