import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = (props) => {
    const recaptchaRef = React.useRef();
    const grecaptchaObject = window.grecaptcha;

    function onChange(value) {
        props.handleCaptchaValid(value);
    }

  	function onExpired(){
    	props.handleExpired();
  	}

  	function onErrored(){
    	grecaptchaObject.reset();
  	}

  	return (
		<ReCAPTCHA
        	ref={recaptchaRef}
			size="normal"
			sitekey="6LeIndAZAAAAAMlXI0GaOBrUaTZrXBcAIA1FpJLM"
			onChange={onChange}
			onExpired={onExpired}
			onErrored={onErrored}
			grecaptcha={grecaptchaObject}
    	/>
  	)

}

export default Captcha;