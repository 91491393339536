import React, { Component } from 'react';

import LearnAppBar from './views/LearnAppBar';
import ProjectList from './views/ProjectList';
import AppFooter from '../../mui_modules/views/AppFooter'

import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';

import axios from 'axios';
import { authMiddleWare } from '../../util/auth';
import { removeAllSavedCookies, saveUserDetails } from '../../util/firebaseAuth';
import { Accordion, AccordionDetails, AccordionSummary, Container } from '@material-ui/core';
import ActiveEpicList from './views/ActiveEpicList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '../../mui_modules/components';


const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		top: '35%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	container: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	toolbar: theme.mixins.toolbar,
	card: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
});

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			uiLoading: true,
		};
	}

	getRoles = (userCredentials) => {
		var roles = [];
		if (userCredentials.IsMentor) {
			roles.push("Mentor");
		}
		if (userCredentials.IsMentee) {
			roles.push("Mentee");
		}
		if (userCredentials.IsAdmin) {
			roles.push("Admin");
		}
		return roles;
	}

	componentDidMount = () => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get(this.props.api_url + '/user/' + localStorage.getItem('UserId'))
			.then((response) => {
				console.log(response.data);
				const roles = this.getRoles(response.data.userCredentials);
				saveUserDetails(response.data.userCredentials.Name, response.data.userCredentials.ImageUrl, roles, response.data.userCredentials.Email, response.data.userCredentials.PhoneNumber);
				this.setState({
					uiLoading: false,
				});
			})
			.catch((error) => {
				console.log(error);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					this.props.history.push('/login');
				}
				this.setState({
					errorMsg: 'Error in retrieving the data',
					uiLoading: false
				});
			});
	};

	render() {
		const { classes, history } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<div className={classes.loadingRoot}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</div>
			);
		} else if (this.state.errorMsg) {
			return (
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					{this.state.errorMsg}
				</Alert>
			)
		} else {
			return (
				<div>
					<CssBaseline />
					<LearnAppBar pageName={"Home"} history={history} />
					<Container className={classes.container}>
						<ActiveEpicList
							api_url={this.props.api_url}
							pageName={"Home"}
							history={history}
							userId={localStorage.getItem('UserId')}
							enqueueSnackbar={this.props.enqueueSnackbar}
						/>
						<Accordion className={classes.card}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
							>
								<Typography variant="body1">Projects</Typography>
							</AccordionSummary>
							<AccordionDetails className={classes.card}>
								<ProjectList
									api_url={this.props.api_url}
									pageName={"Home"}
									userId={localStorage.getItem('UserId')}
								/>
							</AccordionDetails>
						</Accordion>
					</Container>
					<AppFooter pageName={"Home"} />
				</div>
			);
		}
	}
}

export default withStyles(styles)(Home);
