import React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { CircularProgress, Grid } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import { authMiddleWare } from '../../../util/auth';
import TimeProgressBar from '../../../mui_modules/components/TimeProgressBar';
import EpicProgressBar from '../../../mui_modules/components/EpicProgressBar';
import classNames from 'classnames';
import { EpicStates } from '../../../util/uiUtil';

const styles = (theme) => ({
    itemStyles: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    epicProgressBar: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    },
    epicProgressBarToBeShownInCenter: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    timeProgressBar: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
    },
    uiProgess: {
        position: 'relative',
        marginLeft: '25%',
        marginTop: '50%',
    },
});

const getEpicProgressSize = (width) => {
    if (isWidthUp('md', width)) {
        return 110;
    }
    return 90;
};

const getTimeProgressSize = (width) => {
    if (isWidthUp('md', width)) {
        return 90;
    }
    if (isWidthUp('sm', width)) {
        return 70;
    }
    return 90;
};

// const getProgressThickness = (width) => {
//     if (isWidthUp('md', width)) {
//       return 8;
//     }
//     if (isWidthUp('sm', width)) {
//       return 6;
//     }
//     return 6;
// }

class EpicTimelineDisplay extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            errorMsg: "",
            uiLoading: true,
            stories: null,
        }
    }

    componentDidMount() {
        dayjs.extend(relativeTime);
        authMiddleWare(this.props.history);
        const authToken = localStorage.getItem('AuthToken');
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        axios
            .get(this.props.api_url + `/projects/${this.props.projectId}/epics/${this.props.epic.EpicId}/stories`)
            .then((response) => {
                this.setState({
                    uiLoading: false,
                    stories: response.data,
                })
            })
            .catch((error) => {
                console.log(error);
                if (error.response != null && error.response.status === 403) {
                    this.props.history.push('/login')
                }
                this.setState({ errorMsg: 'Error in retrieving the data' });
            });
    }

    render() {
        const { classes } = this.props;
        const { width } = this.props;
        if (this.state.uiLoading) {
            return (
                <center>
                    <CircularProgress size={80} className={classes.uiProgess} />
                </center>
            );
        } else {
            return (
                <Grid container spacing={1}>
                    {(this.props.epic.State === EpicStates.ONGOING) ?
                        <>
                            <Grid item xs={6} sm={12} className={classNames(classes.itemStyles, classes.epicProgressBar)}>
                                <EpicProgressBar stories={this.state.stories} size={getEpicProgressSize(width)} thickness={4} width={width} />
                            </Grid>
                            <Grid item xs={6} sm={12} className={classNames(classes.itemStyles, classes.timeProgressBar)}>
                                <TimeProgressBar epic={this.props.epic} size={getTimeProgressSize(width)} thickness={4} width={width} />
                            </Grid>
                        </>
                        :
                        <Grid item xs={12} sm={12} className={classNames(classes.itemStyles, classes.epicProgressBarToBeShownInCenter)}>
                            <EpicProgressBar stories={this.state.stories} size={getEpicProgressSize(width)} thickness={4} width={width} />
                        </Grid>
                    }
                </Grid>
            );
        }

    }
}

export default withStyles(styles)(withWidth()(EpicTimelineDisplay));

