import React from 'react';
import { withStyles, AppBar, Toolbar, IconButton, Grid, TextField, Switch, FormControlLabel } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../../mui_modules/components/Button';
import Typography from '../../../mui_modules/components/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ChipInput from 'material-ui-chip-input';

const styles = ((theme) => ({
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: 'inherit',
	},
	form: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(3),
		width: '100%',
	},
}))

class AddProjectDialog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			Name: "",
			ProjectCode: "",
			Description: "",
			GitHubRepo: "",
			Tags: [],
			Private: false,
		}

		this.transition = React.forwardRef(function Transition(props, ref) {
			return <Slide direction="up" ref={ref} {...props} />;
		});

		this.analyticsObj = {
			source: "AddProjectDialog",
			page: this.props.pageName,
		};
	}

	updateProps = () => {
		if (this.props.buttonType === 'Edit') {
			this.setState({
				Name: this.props.project.Name,
				ProjectCode: this.props.project.ProjectCode,
				GitHubRepo: this.props.project.GitHubRepo,
				Description: this.props.project.Description,
				Tags: this.props.project.Tags,
				Private: this.props.project.Private,
			});
		} else if (this.props.buttonType === 'Add') {
			this.setState({
				Name: "",
				ProjectCode: "",
				Description: "",
				GitHubRepo: "",
				Tags: [],
				Private: false,
			});
		}

		this.props.setUpdateDialogProps(false);
	}

	handleProjectCodeChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value.toUpperCase()
		})
	}

	handleChange = (event) => {
		if (event.target.type === "checkbox") {
			this.setState({
				[event.target.name]: event.target.checked
			})
		} else {
			this.setState({
				[event.target.name]: event.target.value
			});
		}
	};

	handleAddTag = (tag) => {
		let newTags = [...this.state.Tags, tag];
		this.setState({
			Tags: newTags
		});
	}

	handleDeleteTag = (tag, index) => {
		let newTags = [...this.state.Tags.slice(0, index), ...this.state.Tags.slice(index + 1)];
		this.setState({
			Tags: newTags
		});
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const userDetails = JSON.parse(localStorage.getItem('UserDetails'));
		const userProject = {
			Name: this.state.Name,
			ProjectCode: this.state.ProjectCode,
			GitHubRepo: this.state.GitHubRepo,
			Description: this.state.Description,
			Owner: {
				Id: localStorage.getItem('UserId'),
				Name: userDetails.Name,
				PhotoURL: userDetails.PhotoURL
			},
			Tags: this.state.Tags,
			Private: this.state.Private,
		};
		this.props.handleSubmit(userProject);
	};

	render() {
		const { classes } = this.props;

		if (this.props.updateDialogProps) {
			this.updateProps();
		}

		return (
			<Dialog fullWidth open={this.props.open} onClose={this.props.handleClose} TransitionComponent={this.transition}>
				<AppBar position="relative" className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close">
							<CloseIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{this.props.buttonType === 'Edit' ? 'Edit Project' : 'Create a new Project'}
						</Typography>
					</Toolbar>
				</AppBar>

				<form className={classes.form} noValidate>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="projectName"
								label="Project Name"
								name="Name"
								autoComplete="projectName"
								helperText={this.props.errors.Name}
								value={this.state.Name}
								error={this.props.errors.Name ? true : false}
								onChange={this.handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="projectDescription"
								label="Project Description"
								name="Description"
								autoComplete="projectDescription"
								multiline
								rows={3}
								rowsMax={10}
								helperText={this.props.errors.Description}
								error={this.props.errors.Description ? true : false}
								onChange={this.handleChange}
								value={this.state.Description}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="projectCode"
								label="Project Code"
								name="ProjectCode"
								autoComplete="projectCode"
								helperText={this.props.errors.ProjectCode}
								error={this.props.errors.ProjectCode ? true : false}
								onChange={this.handleProjectCodeChange}
								value={this.state.ProjectCode}
								inputProps={{ maxLength: 3 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								fullWidth
								id="gitHubRepo"
								label="GitHub Repository Link"
								name="GitHubRepo"
								autoComplete="gitHubRepo"
								type="url"
								helperText={this.props.errors.GitHubRepo}
								error={this.props.errors.GitHubRepo ? true : false}
								onChange={this.handleChange}
								value={this.state.GitHubRepo}
							/>
						</Grid>
						<Grid item xs={12}>
							<ChipInput
								variant="outlined"
								fullWidth
								id="projectTags"
								label='Tags'
								placeholder='Type and press enter to add chips'
								name="Tags"
								value={this.state.Tags}
								onAdd={(tag) => this.handleAddTag(tag)}
								onDelete={(tag, index) => this.handleDeleteTag(tag, index)}
								helperText="Press enter to add tag"
							/>
						</Grid>

						<Grid item >
							<FormControlLabel
								control={
									<Switch
										id="isPrivate"
										name="Private"
										checked={this.state.Private}
										onChange={this.handleChange}
										inputProps={{ 'aria-label': 'private switch' }}
									/>
								}
								label="Private"
							/>
						</Grid>

						<Grid item xs={12}>
							<Button
								fullWidth
								color="primary"
								size="large"
								variant="contained"
								onClick={this.handleSubmit}
								analyticsObj={this.analyticsObj}
							>
								{this.props.buttonType === 'Edit' ? 'Save' : 'Submit'}
							</Button>
						</Grid>
					</Grid>
				</form>
			</Dialog>
		)
	}
}

export default withStyles(styles)(AddProjectDialog);