import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import expertIcon from '../../resources/images/expert.png'
import project from '../../resources/images/project.png'
import payment from '../../resources/images/payment.png'
import ImageBlackWithCredits from '../components/ImageBlackWithCredits';

const styles = (theme) => ({
	root: {
		display: 'flex',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.secondary,
	},
	container: {
		marginTop: theme.spacing(15),
		marginBottom: theme.spacing(15),
		display: 'flex',
		position: 'relative',
	},
	item: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(0, 5),
	},
	title: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
	},
	description: {
		textAlign: "center"
	},
});

function ProductValues(props) {
	const { classes } = props;

	return (
		<section className={classes.root}>
			<Container className={classes.container}>
				<Grid container spacing={5}>
					<Grid item xs={12} md={4}>
						<div className={classes.item}>
							<ImageBlackWithCredits
								icon={expertIcon}
								height={75}
								altText="expert"
								authorName="Eucalyp"
								authorLink="https://www.flaticon.com/authors/eucalyp"
								sourceName="Flaticon"
								sourceLink="https://www.flaticon.com/"
							/>
							<Typography variant="h6" className={classes.title}>
								Zero-attrition teams
							</Typography>
							<Typography variant="h5" className={classes.description}>
								{'Evaluate talent and team-work'}
								{' to maintain culture & reduce attrition'}
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} md={4}>
						<div className={classes.item}>
							<ImageBlackWithCredits
								icon={payment}
								height={75}
								altText="money"
								authorName="Kiranshastry"
								authorLink="https://www.flaticon.com/authors/kiranshastry"
								sourceName="Flaticon"
								sourceLink="https://www.flaticon.com/"
							/>
							<Typography variant="h6" className={classes.title}>
								Reduced cost
							</Typography>
							<Typography variant="h5" className={classes.description}>
								{'Save your engineers from wasting precious hours on interviews and dummy assignments'}
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} md={4}>
						<div className={classes.item}>
							<ImageBlackWithCredits
								icon={project}
								height={75}
								altText="project"
								authorName="Freepik"
								authorLink="https://www.flaticon.com/authors/freepik"
								sourceName="Flaticon"
								sourceLink="https://www.flaticon.com/"
							/>
							<Typography variant="h6" className={classes.title}>
								Product Increments
							</Typography>
							<Typography variant="h5" className={classes.description}>
								{'Your candidates make valuable contributions to your codebase'}
							</Typography>
						</div>
					</Grid>
				</Grid>
			</Container>
		</section>
	);
}

ProductValues.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);