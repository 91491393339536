import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';
import hrefAnalytics from "../../util/hrefAnalytics";
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
	root: {
		fontWeight: theme.typography.fontWeightMedium,
		fontFamily: theme.typography.fontFamilySecondary,
		padding: props => (props.paddedButton) ? (theme.spacing(2, 4)) : null,
		fontSize: theme.typography.pxToRem(14),
		boxShadow: 'none',
		'&:active, &:focus': {
			boxShadow: 'none',
		},
	},
	sizeSmall: {
		padding: theme.spacing(1, 3),
		fontSize: theme.typography.pxToRem(13),
	},
	sizeLarge: {
		padding: theme.spacing(2, 5),
		fontSize: theme.typography.pxToRem(16),
	},
});

function Button(props) {
	const { children, href, target, onClick, analyticsObj, paddedButton, ...other } = props;
	// paddedButton is extracted only to remove it from ...other props

	analyticsObj.method = "Button";
	const newOnClick = hrefAnalytics(href, onClick, analyticsObj, target);

	return (
		<MuiButton href={href} onClick={ newOnClick } {...other} >
			{children}
		</MuiButton>
	);
}

Button.propTypes = {
	paddedButton: PropTypes.bool,
	children: PropTypes.node,
	classes: PropTypes.object,
	href: PropTypes.string,
	target: PropTypes.string,
	onClick: PropTypes.func,
	analyticsObj: PropTypes.object,
};

export default withStyles(styles)(Button);