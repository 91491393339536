import React from 'react';
import { withStyles, Container, Divider, Grid, Hidden } from '@material-ui/core';
import MetricDisplay from '../../../mui_modules/components/MetricDisplay';

const styles = (theme) => ({
	root: {
		display: 'flex',
		overflow: 'hidden',
	},
	container: {
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
		},
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
		},
		[theme.breakpoints.up('md')]: {
			width: theme.breakpoints.values.md,
		},
	},
	divider: {
		marginLeft: 'auto',
		marginRight: 'auto',
	}
});

class ProfileMetrics extends React.Component {
	// EXAMPLES FOR this.props.left and this.props.right
	// left: {
	// 	displayType: "money",
	// 	metricValue: {
	// 		Currency: "INR",
	// 		Amount: 1200
	// 	},
	// 	metricColor: '#dd0011',
	// 	text: "Total Diposit",
	// },
	// right: {
	// 	displayType: "percent",
	// 	metricValue: 30,
	// 	metricColor: '#00dd11',
	// 	text: "Current Progress",
	// 	textColor: '#000000'
	// },

	render() {
		const { classes } = this.props;
		const { left, right } = this.props;
		return (
			<section className={classes.root}>
				<Container className={classes.container}>
					<Grid container>
						<Grid item xs={12} sm={5}>
							<MetricDisplay
								displayType={left.displayType}
								metricValue={left.metricValue}
								text={left.text}
								metricColor={left.metricColor}
								className={classes.metric}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Hidden xsDown>
								<Divider variant="middle" orientation="vertical" className={classes.divider} />
							</Hidden>
							<Hidden smUp>
								<Divider variant="middle" orientation="horizontal" className={classes.divider} />
							</Hidden>
						</Grid>
						<Grid item xs={12} sm={5}>
							<MetricDisplay
								displayType={right.displayType}
								metricValue={right.metricValue}
								text={right.text}
								metricColor={right.metricColor}
								className={classes.metric}
							/>
						</Grid>
					</Grid>
				</Container>
			</section>
		)
	}
}

export default withStyles(styles)(ProfileMetrics);