import React from 'react';
import withRoot from '../mui_modules/withRoot';
import AppAppBar from '../mui_modules/views/AppAppBar';
import AppFooter from '../mui_modules/views/AppFooter';
import ProductWhatWeDo from '../mui_modules/views/ProductWhatWeDo';
import ProductPhilosophy from '../mui_modules/views/ProductPhilosophy';
import ProductHowItWorks from '../mui_modules/views/ProductHowItWorks';
import AboutHero from '../mui_modules/views/AboutHero';

import { gAnalytics } from "../util/analytics";

class About extends React.Component {
	constructor(props) {
		super(props);
		gAnalytics.logEvent('screen_view', {
			firebase_screen: 'about',
		});
	}

	render() {
		return (
			<React.Fragment>
				<AppAppBar pageName={ "About" } />
				<AboutHero pageName={ "About" } />
				<ProductPhilosophy pageName={ "About" } />
				<ProductWhatWeDo pageName={ "About" } />
				{/* <WinWinSituation pageName={ "About" } /> */}
				<ProductHowItWorks titleText="How we do it" programButton={ true } pageName={ "About" } />
				{/* <ProductFormat /> */}
				<AppFooter pageName={ "About" } />
			</React.Fragment>
		)
	}
}

export default withRoot(About);

