import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';

import trainingIcon from '../../resources/images/school.svg'
import availableIcon from '../../resources/images/phone.svg'
import qualityIcon from '../../resources/images/high-quality.svg'
import leaderIcon from '../../resources/images/lead.svg'
import ImageBlackWithCredits from '../components/ImageBlackWithCredits';

const styles = (theme) => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.background.secondary,
		overflow: 'hidden',
	},
	container: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(15),
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	item: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(0, 5),
	},
	title: {
		marginBottom: theme.spacing(8),
	},
	image: {
		height: 75,
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
});

function MentorResponsibilities(props) {
	const { classes } = props;

	return (
		<section className={classes.root}>
			<Container className={classes.container}>
				{(props.titleText.trim() && props.titleText.trim().length !== 0) ?
					<Typography variant="h4" marked="center" className={classes.title} component="h2">
						{props.titleText}
					</Typography>
					: null
				}
				<div>
					<Grid container spacing={5}>
						<Grid item xs={12} md={3}>
							<div className={classes.item}>
								<ImageBlackWithCredits
									icon={trainingIcon}
									altText="School"
									authorName="geotatah"
									authorLink="https://www.flaticon.com/authors/geotatah"
									sourceName="Flaticon"
									sourceLink="https://www.flaticon.com/"
									className={classes.image}
								/>
								<Typography variant="h5" align="center">
									Train your mentees
								</Typography>
								<Typography variant="inherit" align="center">
									Guide them to the right resources for learning, clear their doubts and prepare them for the project.
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} md={3}>
							<div className={classes.item}>
								<ImageBlackWithCredits
									icon={availableIcon}
									altText="Phone"
									authorName="Freepik"
									authorLink="https://www.flaticon.com/authors/freepik"
									sourceName="Flaticon"
									sourceLink="https://www.flaticon.com/"
									className={classes.image}
								/>
								<Typography variant="h5" align="center">
									Be Available
								</Typography>
								<Typography variant="inherit" align="center">
									Allocate office hours, have daily stand-up calls or use any other means to be in regular touch with your mentees.
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} md={3}>
							<div className={classes.item}>
								<ImageBlackWithCredits
									icon={qualityIcon}
									altText="high-quality"
									authorName="Kiranshastry"
									authorLink="https://www.flaticon.com/authors/kiranshastry"
									sourceName="Flaticon"
									sourceLink="https://www.flaticon.com/"
									className={classes.image}
								/>
								<Typography variant="h5" align="center">
									Quality Check
								</Typography>
								<Typography variant="inherit" align="center">
									Ensure that only high quality work makes it to the master branch of your project.
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} md={3}>
							<div className={classes.item}>
								<ImageBlackWithCredits
									icon={leaderIcon}
									altText="lead"
									authorName="Freepik"
									authorLink="https://www.flaticon.com/authors/freepik"
									sourceName="Flaticon"
									sourceLink="https://www.flaticon.com/"
									className={classes.image}
								/>
								<Typography variant="h5" align="center">
									Lead by Example
								</Typography>
								<Typography variant="inherit" align="center">
									Work on your own project and show them how it is done. Provide your mentees with an ideal collaborator.
								</Typography>
							</div>
						</Grid>
					</Grid>
				</div>
			</Container>
		</section>
	);
}

MentorResponsibilities.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MentorResponsibilities);