import React from 'react';
import axios from 'axios';

import { Avatar, Checkbox, Chip, Grid, ListItemAvatar, ListItemIcon, ListItemText, TextField, Tooltip, Typography, withStyles } from '@material-ui/core';
import IconButton from '../../../mui_modules/components/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { isMentee, isMentor, isProjectOwner } from '../../../util/uiUtil';
import { authMiddleWare } from '../../../util/auth';
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = (theme) => ({
	profilePicture: {
		height: theme.spacing(3),
		width: theme.spacing(3),
	},
	listItemIcon: {
		marginRight: theme.spacing(-2),
	},
	display: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1)
	},
	taskName: {
		marginLeft: theme.spacing(1),
	},
	checkbox: {
		padding: 0,
	},
})

class TaskItem extends React.Component {
	constructor(props) {
		super(props);
		this.contributorOptionsList = [];
		if(this.props.isAddTask){
			this.state = {
				Name: "",
				Credits: null,
				Contributor: null,
				Index: "",
				isDone: false,
				errors: {},
				editMode: true,
				disableButton: false
			}
		} else {
			this.state = {
				Name: this.props.task.Name,
				Credits: this.props.task.Credits,
				Contributor: this.props.task.Contributor,
				Index: this.props.index,
				isDone: this.props.task.isDone,
				errors: {},
				editMode: false,
				disableButton: false
			};
		}

		this.analyticsObj = {
			source: "TaskItem",
			page: this.props.pageName,
		};
	}

	setContributorOptionList = () => {
		const contributorsInStory = {};
		if (this.props.allUsers) {
			const contributors = this.props.epic.Mentees.Curr.concat(this.props.epic.Mentors);
			for (const data of contributors) {
				if (data.Id in contributorsInStory) {
					continue;
				}
				const contributor = this.props.allUsers.find(x => x.Id === data.Id);
				if (contributor) {
					contributorsInStory[contributor.Id] = contributor;
				}
			}
		}
		return Object.values(contributorsInStory);
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handleContributorChange = (event, value, reason) => {
		this.setState({
			Contributor: value
		});
	};

	handleCheckboxChange = (event) => {
		this.setState({
			isDone: event.target.checked
		});
	}

	handleEditModeChange = (event) => {
		this.setState({
			editMode: true
		})
	}

	taskFieldValidation = (storyTask) => {
		let errors = {};
		let errorMsg = "Must not be empty";
		let totalSumOfTaskCredits = this.sumOfTaskCredits(storyTask.Index, storyTask.Credits);
		let storyCredits = parseInt(this.props.story.Credits);
		if(storyTask.Name === ""){
			errors.Name = errorMsg;
		}
		if(storyTask.Credits === null){
			errors.Credits = errorMsg;
		} else if (typeof(storyTask.Credits) !== "number" || storyTask.Credits <= 0) {
			errors.Credits = "Must be a positve integer";
		}
		if(typeof storyTask.isDone !== "boolean"){
			errors.isDone = "Must be a boolean";
		}
		if(totalSumOfTaskCredits > storyCredits){
			errors.Credits = "Sum of Tasks Credits should be less than or equal to Story Credits";
		}
		return {
			errors,
			valid: Object.keys(errors).length === 0 ? true : false
		};
	};

	sumOfTaskCredits = (index, newTaskCredits) => {
		var storyTasks = this.props.story.Tasks;
		var sumOfTasksCredits = 0;
		var totalSumOfTaskCredits = 0;
		if(this.props.story.Tasks) {
			for(const task of storyTasks){
				sumOfTasksCredits += task.Credits;
			}
			if(this.props.isAddTask) {
				totalSumOfTaskCredits = sumOfTasksCredits + newTaskCredits;
			} else {
				let taskCreditBeforeEditing = storyTasks[index].Credits;
				let sumOfTaskCreditsWithoutIndexCredits = 0;
				sumOfTaskCreditsWithoutIndexCredits = sumOfTasksCredits - taskCreditBeforeEditing;
				totalSumOfTaskCredits = sumOfTaskCreditsWithoutIndexCredits + newTaskCredits;
			}
		} else {
			totalSumOfTaskCredits = newTaskCredits;
		}
		return totalSumOfTaskCredits;
	};



	handleTaskSubmit = (event) => {
		this.setState({ disableButton: true })
		const newTask = {
			Name: this.state.Name,
			Credits: parseInt(this.state.Credits),
			Contributor: this.state.Contributor,
			isDone: this.state.isDone,
			Index: this.state.Index
		};
		authMiddleWare(this.props.history)
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` }
		let options = {};
		if(this.props.isAddTask){
			options = {
				url: this.props.api_url + `/projects/${this.props.epic.Project.Id}/epics/${this.props.epic.Id}/stories/${this.props.story.StoryId}/tasks`,
				method: 'post',
				data: newTask
			};
		} else {
			options = {
				url: this.props.api_url + `/projects/${this.props.epic.Project.Id}/epics/${this.props.epic.Id}/stories/${this.props.story.StoryId}/tasks`,
				method: 'put',
				data: newTask
			};
		}
		const { valid, errors } = this.taskFieldValidation(newTask);
		if(valid){
			axios(options)
				.then(() => {
					this.setState({
						editMode: false,
						disableButton: false
					})
					this.props.editTaskInProps(this.props.index, newTask);
				})
				.catch((err) => {
					if(this.props.isAddTask){
						this.props.editAddTaskClickState();
						this.setState({
							Name: "",
							Credits: null,
							Contributor: null,
							Index: "",
							isDone: false,
							editMode: false,
							disableButton: false
						})
					} else {
						this.setState({
							Name: this.props.task.Name,
							Credits: this.props.task.Credits,
							Contributor: this.props.task.Contributor,
							Index: this.props.index,
							isDone: this.props.task.isDone,
							editMode: false,
							disableButton: false
						})
					}
					console.error(err);
				});
		} else {
			this.setState({
				errors: errors,
				disableButton: false
			})
		}
	}

	handleTaskCancel = (event) => {
		if(this.props.isAddTask){
			this.props.editAddTaskClickState();
		} else {
			this.setState({
				Name: this.props.task.Name,
				Credits: this.props.task.Credits,
				Contributor: this.props.task.Contributor,
				Index: this.props.index,
				isDone: this.props.task.isDone,
			})
		}
		this.setState({ editMode: false })
	}

	deleteTaskHandler() {
		this.setState({ disableButton: true });
		const taskIndex = {
			Index: +this.props.index //'+' is used to convert taskIndex string to a number type.
		}
		authMiddleWare(this.props.history)
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.post(this.props.api_url + `/projects/${this.props.epic.Project.Id}/epics/${this.props.epic.Id}/stories/${this.props.story.StoryId}/deleteTasks`, taskIndex)
			.then(() => {
				window.location.reload();
			})
			.catch((err) => {
				console.error(err);
				this.setState({ disableButton: false });
			});
	};

	render() {
		const { classes } = this.props;
		this.contributorOptionsList = this.setContributorOptionList();
		return (
			(!this.state.editMode) ? (
				<Grid container className={classes.display}>
					<Grid item xs={12} sm={12} md={9} className={classes.display}>
						<Checkbox
							checked={this.state.isDone}
							onChange={() => this.handleEditModeChange()}
							size='small'
							className={classes.checkbox}
						/>
						<ListItemText onClick={() => this.handleEditModeChange()} align="justify" className={classes.taskName}>
							{this.state.Name}
						</ListItemText>
					</Grid>
					<Grid container item xs={3} sm={3} md={1} justify="center">
						<ListItemIcon onClick={() => this.handleEditModeChange()}>
							<Chip size="small" label={this.state.Credits} />
						</ListItemIcon>
					</Grid>
					<Grid item xs={7} sm={7} md={1}>
						<ListItemAvatar onClick={() => this.handleEditModeChange()}>
							{this.state.Contributor ?
							<Grid container direction="row" justify="center" alignItems="center">
								<Tooltip title={this.state.Contributor.Name} key={this.state.Contributor.Id} placement="bottom">
									<Avatar className={classes.profilePicture} key={this.state.Contributor.Id} alt={this.state.Contributor.Id} src={ this.state.Contributor.PhotoURL }/>
								</Tooltip>
							</Grid>
							: null }
						</ListItemAvatar>
					</Grid>
					<Grid item xs={2} sm={2} md={1}>
						<IconButton
							onClick={() => this.deleteTaskHandler()}
							disabled={!((isMentee(this.props.epic)) || (isProjectOwner(this.props.epic.Project)) || (isMentor(this.props.epic))) || this.state.disableButton}
							analyticsObj={this.analyticsObj}
						>
							<DeleteIcon fontSize="small" />
						</IconButton>
					</Grid>
				</Grid>
			) : (
				<Grid container className={classes.display}>
					<Grid item xs={2} sm={2} md={1} className={classes.display}>
						<Checkbox
							checked={this.state.isDone}
							onChange={this.handleCheckboxChange}
							disabled={ !( isProjectOwner(this.props.epic.Project) || isMentor(this.props.epic) ) }
							size='small'
							className={classes.checkbox}
						/>
					</Grid>
					<Grid item xs={10} sm={10} md={5} className={classes.display}>
						<TextField
							fullWidth
							required
							variant='outlined'
							id="taskName"
							name="Name"
							value={this.state.Name}
							error={this.state.errors.Name ? true : false}
							onChange={this.handleChange}
							disabled={ !( isProjectOwner(this.props.epic.Project) || isMentor(this.props.epic) ) }
						/>
					</Grid>
					<Grid container item xs={3} sm={3} md={1} className={classes.display}>
						<TextField
							fullWidth
							required
							variant='outlined'
							id="credtis"
							name="Credits"
							type="number"
							value={this.state.Credits}
							error={this.state.errors.Credits ? true : false}
							onChange={this.handleChange}
							disabled={ !( isProjectOwner(this.props.epic.Project) || isMentor(this.props.epic) ) }
						/>
					</Grid>
					<Grid item xs={7} sm={7} md={4} className={classes.display}>
						<Autocomplete
							id="contributor"
							fullWidth
							options={this.contributorOptionsList}
							getOptionLabel={(option) => option.Name}
							value={this.state.Contributor}
							filterSelectedOptions
							onChange={this.handleContributorChange}
							getOptionSelected={(option, value) => {
								if (option.Id === value.Id) {
									return true;
								} else {
									return false;
								}
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={2} sm={2} md={1} className={classes.display}>
						<IconButton
							onClick={this.handleTaskSubmit}
							analyticsObj={this.analyticsObj}
							disabled={this.state.disableButton}
						>
							<CheckCircleIcon fontSize="small" />
						</IconButton>
						<IconButton
							onClick={this.handleTaskCancel}
							analyticsObj={this.analyticsObj}
							disabled={this.state.disableButton}
						>
							<CancelIcon fontSize="small" />
						</IconButton>
					</Grid>
					{(this.state.errors) ?
						<Grid item xs={12}>
							<Typography variant="caption" color='error'>
								{(this.state.errors.Credits) ? this.state.errors.Credits : this.state.errors.Name}
							</Typography>
						</Grid>
						: null}
				</Grid>
			)
		)
	}
}

export default  withStyles(styles)(TaskItem);