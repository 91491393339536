import React from "react";
import PropTypes from "prop-types";
import { withStyles, Container, Grid } from "@material-ui/core";
import Typography from "../components/Typography";

import rewardIcon from "../../resources/images/reward.svg";
import trainingIcon from "../../resources/images/school.svg";
import projectIcon from "../../resources/images/project.png";
import friendsIcon from "../../resources/images/friends.svg";
import { tradeMarkedAlokit } from "../../util/uiUtil";
import ImageBlackWithCredits from "../components/ImageBlackWithCredits";

const styles = (theme) => ({
    root: {
        display: "flex",
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            width: "100% !important",
        },
        [theme.breakpoints.up("md")]: {
            width: theme.breakpoints.values.md,
        },
    },
    title: {
        marginBottom: theme.spacing(3),
        color: theme.palette.background.paper,
    },
    gridContainer: {
        marginBottom: theme.spacing(2),
    },
    image: {
        [theme.breakpoints.up("sm")]: {
            width: "70%",
            height: "auto",
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            width: "auto",
            height: "15vh",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    text: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
});

function ProjectJourney(props) {
    const { classes } = props;

    const ratios = {
        xs: 12,
        sm: 3,
        md: 3,
    };

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                {props.titleText.trim() &&
                    props.titleText.trim().length !== 0 ? (
                    <Typography
                        variant="h4"
                        marked="center"
                        className={classes.title}
                        component="h2"
                    >
                        {props.titleText}
                    </Typography>
                ) : null}
                <React.Fragment>
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={ratios.xs} sm={ratios.sm} md={ratios.md}>
                            <ImageBlackWithCredits
                                icon={projectIcon}
                                altText="project"
                                authorName="dDara"
                                authorLink="https://www.flaticon.com/free-icons/project"
                                sourceName="Flaticon"
                                sourceLink="https://www.flaticon.com/"
                                className={classes.image}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={ratios.xs}
                            sm={12 - ratios.sm}
                            md={12 - ratios.md}
                            className={classes.text}
                        >
                            <Typography variant="body1">
                                You will need to select a task for the project.
                                It must be a scoped piece of work that can be
                                completed in limited time. The value of the
                                project should be assigned keeping in mind the
                                role you hire for and the value you will
                                generate from the work done in the project.
                                Buy-in from candidates should be assigned to
                                lower the risk of candidates leaving before the
                                project finishes.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        className={classes.gridContainer}
                        direction="row-reverse"
                    >
                        <Grid item xs={ratios.xs} sm={ratios.sm} md={ratios.md}>
                            <ImageBlackWithCredits
                                icon={friendsIcon}
                                altText="friends"
                                authorName="Freepik"
                                authorLink="https://www.flaticon.com/authors/freepik"
                                sourceName="Flaticon"
                                sourceLink="https://www.flaticon.com/"
                                className={classes.image}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={ratios.xs}
                            sm={12 - ratios.sm}
                            md={12 - ratios.md}
                            className={classes.text}
                        >
                            <Typography variant="body1">
                                Invite mentors and candidates to the project and
                                launch it. Each {tradeMarkedAlokit()}
                                project has milestones with some value attached
                                with them. Candidates can start working on these
                                milestones with regular guidance from mentors
                                through daily interactions.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={ratios.xs} sm={ratios.sm} md={ratios.md}>
                            <ImageBlackWithCredits
                                icon={trainingIcon}
                                altText="School"
                                authorName="geotatah"
                                authorLink="https://www.flaticon.com/authors/geotatah"
                                sourceName="Flaticon"
                                sourceLink="https://www.flaticon.com/"
                                className={classes.image}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={ratios.xs}
                            sm={12 - ratios.sm}
                            md={12 - ratios.md}
                            className={classes.text}
                        >
                            <Typography variant="body1">
                                Working through the project, the mentors get a
                                chance to evaluate the candidates while working
                                with them. They can be judged on technical
                                factors like code cleanliness, systems
                                knowledge, ability to learn a new tech stack
                                quickly etc. They can also be judged on their
                                interactions with the team, cultural fit and
                                end-to-end delivery mindset. At the same time,
                                candidates also get a feel of how it would be
                                like to work with the team full time.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        className={classes.gridContainer}
                        direction="row-reverse"
                    >
                        <Grid item xs={ratios.xs} sm={ratios.sm} md={ratios.md}>
                            <ImageBlackWithCredits
                                icon={rewardIcon}
                                altText="rewards"
                                authorName="geotatah"
                                authorLink="https://www.flaticon.com/free-icon/incentive_992591?term=reward&page=1&position=32"
                                sourceName="Flaticon"
                                sourceLink="https://www.flaticon.com/"
                                className={classes.image}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={ratios.xs}
                            sm={12 - ratios.sm}
                            md={12 - ratios.md}
                            className={classes.text}
                        >
                            <Typography variant="body1">
                                Once the duration of the project ends, you can
                                choose to end the project. Value will be
                                distributed among the candidates according to
                                the milestones they have completed.
                            </Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </Container>
        </section>
    );
}

ProjectJourney.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProjectJourney);
