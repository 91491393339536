import React from 'react';
import { CssBaseline } from '@material-ui/core';
import LearnAppBar from './views/LearnAppBar';
import AppFooter from '../../mui_modules/views/AppFooter';
import MenteeEpics from './views/MenteeEpics';

class MenteeView extends React.Component {
    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <LearnAppBar pageName="MenteeView" history={ this.props.history } />
                <MenteeEpics api_url={ this.props.api_url } history={ this.props.history } pageName="MenteeView" />
                <AppFooter pageName="MenteeView" />
            </React.Fragment>
        )
    }
}

export default MenteeView;