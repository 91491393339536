import React from 'react';
import withRoot from "../mui_modules/withRoot";
import AppAppBar from "../mui_modules/views/AppAppBar";
import AppFooter from "../mui_modules/views/AppFooter";
import ContactUsHero from "../mui_modules/views/ContactUsHero";
import ContactForm from "../mui_modules/views/ContactForm";
import ProductCTA from '../mui_modules/views/ProductCTA';
import FAQ from '../mui_modules/views/FAQ';

import { CssBaseline } from '@material-ui/core';
import { gAnalytics } from "../util/analytics";

class ContactUs extends React.Component {
	constructor(props) {
		super(props);
		gAnalytics.logEvent('screen_view', {
			firebase_screen: 'contactUs',
		});
	}

	render() {
		return (
			<React.Fragment>
				<CssBaseline />
				<AppAppBar pageName={ "ContactUs" } />
				<ContactUsHero pageName={ "ContactUs" } />
				<FAQ pageName={ "ContactUs" } />
				<ContactForm api_url={this.props.api_url} pageName={ "ContactUs" } />
				<ProductCTA titleText="Get associated with us!" pageName={ "ContactUs" } />
				<AppFooter pageName={ "ContactUs" } />
			</React.Fragment>
		)
	}
}

export default withRoot(ContactUs);