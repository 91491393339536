import { gAnalytics } from "./analytics";

function hrefAnalytics(href, onClick, analyticsObj, target="_self") {
	if(href && onClick) {
		console.error("ERROR: something went wrong. OnClick and HREF are both defined!");
		return
	}

	const newOnClickFun = (event) => {
		if (href) {
			analyticsObj.target = href;
			gAnalytics.logEvent('user_click', analyticsObj);
			if (href.includes("apply") || href.includes("mentorApply")) {
				gAnalytics.logEvent('application_opened', analyticsObj);
			}
			if (href.includes("linkedin.com") || href.includes("facebook.com") || href.includes("instagram.com")) {
				gAnalytics.logEvent('contact_social_media', analyticsObj);
			}
			window.open(href, target);
		}
		else if (onClick) {
			console.debug("onClick click: no analytics logged from hrefAnalytics");
			analyticsObj.target = "onClick function provided";
			gAnalytics.logEvent('user_click', analyticsObj);
			onClick(event);
		}
	}
	return newOnClickFun;
}

export default hrefAnalytics;