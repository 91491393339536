import React from 'react';
import { withStyles, Button, Container } from '@material-ui/core';
import { isMentor, isProjectOwner} from '../../../util/uiUtil';
import { ListItem } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add';
import TaskItem from './TaskItem';

const styles = (theme) => ({
	task: {
		'&:hover': {
			background: "#bbdefb",
		 },
		 background: "#ffffff",
	},
	addTaskButton: {
		marginLeft: theme.spacing(5),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	}
})

class StoryTasksList extends React.Component {
	constructor(props){
		super(props);
		this.tasks = [];
		this.state = {
			addTaskClick: false,
		}
		this.analyticsObj = {
			source: "StoryList",
			page: this.props.pageName,
		};

		this.editTaskInProps = this.editTaskInProps.bind(this);
		this.editAddTaskClickState = this.editAddTaskClickState.bind(this);
	}

	editTaskInProps = (index, newTask) => {
		this.props.story.Tasks[index] = newTask;
	}

	newTaskIndex() {
		let indexOfTask = 0
		if(this.props.story.Tasks){
			indexOfTask = (this.props.story.Tasks.length);
		}
		return indexOfTask;
	}

	handleAddTaskClick = () => {
		this.setState({
			addTaskClick: true
		})
	}

	editAddTaskClickState = () => {
		this.setState({
			addTaskClick: false
		})
	}

	render(){
		const { classes } = this.props;
		return(
			<Container>
			{ (this.props.story.Tasks ?
				this.props.story.Tasks.map((task, index) => {
					return(
						<ListItem
							dense
							className={classes.task}
							key={index}
							divider
						>
							<TaskItem
								task={task}
								index={index}
								editTaskInProps={this.editTaskInProps}
								editAddTaskClickState={this.editAddTaskClickState}
								epic={this.props.epic}
								story={this.props.story}
								allUsers={this.props.allUsers}
								history={this.props.history}
								api_url={this.props.api_url}
								isAddTask={false}
							/>
						</ListItem>
					)
				})
				: null )
			}
			{(this.state.addTaskClick) ? (
				<ListItem
					dense
					className={classes.task}
					divider
					key={this.newTaskIndex()}
				>
					<TaskItem
						index={this.newTaskIndex()}
						editTaskInProps={this.editTaskInProps}
						editAddTaskClickState={this.editAddTaskClickState}
						epic={this.props.epic}
						story={this.props.story}
						allUsers={this.props.allUsers}
						history={this.props.history}
						api_url={this.props.api_url}
						isAddTask={true}
					/>
				</ListItem>
			) : null}
				<div>
					{ ( (isProjectOwner(this.props.epic.Project)) || (isMentor(this.props.epic)) ) ?
					<Button
						size="small"
						variant="text"
						color="inherit"
						className={classes.addTaskButton}
						onClick = {()=> {this.handleAddTaskClick()}}
						analyticsObj={ this.analyticsObj }
						startIcon={<AddIcon/>}
						disabled={this.props.story.State === 'Done'}
					>
						Add Task
					</Button>
				: null }
				</div>
			</Container>
		)
	}
}

export default withStyles(styles)(StoryTasksList);