import axios from 'axios';
import React, { useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { removeAllSavedCookies } from '../../../util/firebaseAuth';
import { withStyles, CircularProgress, Dialog, List, ListItem, TextField, Grid, Select, MenuItem, FormHelperText, InputLabel, FormControl, Tooltip } from '@material-ui/core';
import { Button, IconButton, Typography } from '../../../mui_modules/components';
import { Done, Error, HourglassFull, Refresh, HighlightOff } from '@material-ui/icons';
import { AccentColors } from '../../../resources/Colors';
import clsx from 'clsx';
import { Chip } from "@material-ui/core"

const styles = (theme) => ({
	loadingRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	sectionHeading: {
		marginTop: theme.spacing(2),
	},
	listItem: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	statusIcon: {
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
	},
	accepted: {
		color: AccentColors.PositiveGreen,
	},
	pending: {
		color: AccentColors.ProgressiveYellow,
	},
	error: {
		color: AccentColors.IndicatorRed,
	},
	sendButtonGrid: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
	inviteSend: {
		height: '100%',
		marginLeft: theme.spacing(1),
	},
	form: {
		width: '100%',
		padding: '5px',
	},
	dropdown: {
		paddingLeft: theme.spacing(1),
		paddingTop: theme.spacing(2),
		height: '100%',
	}
});

function InviteUserView(props) {
	const [uiLoading, setUiLoading] = React.useState(true);
	const [shouldRefreshInvitees, setShouldRefreshInvitees] = React.useState(true);
	const [openDialog, setOpenDialog] = React.useState(false);
	const [referrals, setReferrals] = React.useState([]);
	const [referred, setReferred] = React.useState({ email: '' });
	const [role, setRole] = React.useState('')
	const [sendButtonDisabled, setSendButtonDisabled] = React.useState(false);
	const [deleteStatus, setDeleteStatus] = React.useState(false)
	const { classes, analyticsObj, enqueueSnackbar, history } = props;
	const referralsEndpoint = `${props.api_url}/projects/${props.projectId}/epics/${props.epicId}/referrals`;

	useEffect(() => {
		if (!shouldRefreshInvitees) {
			return;
		}
		axios.get(referralsEndpoint)
			.then(response => {
				setUiLoading(false);
				setReferrals(response.data.referrals);
				setShouldRefreshInvitees(false);
				setOpenDialog(false);
			}
			).catch(error => {
				setUiLoading(false);
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					enqueueSnackbar("Session timeout: Please login again", { variant: "info" });
					history.push('/login');
				}
				if (error.response != null && error.response.data != null & error.response.data.error != null) {
					enqueueSnackbar(error.response.data.error, { variant: "error" });
				} else {
					enqueueSnackbar("Could not fetch invitations", { variant: "error" });
				}
			});
	}, [shouldRefreshInvitees, referralsEndpoint, enqueueSnackbar, history]);

	const handleInviteClick = () => {
		setOpenDialog(true);
	};

	const checkDuplicateReferal = () => {
		return referrals.some(referral => referral.Email === referred.email)
	}

	const refer = () => {
		setSendButtonDisabled(true);
		if (checkDuplicateReferal()) {
			props.enqueueSnackbar("Invitation already exists", { variant: "warning" });
			setReferred({ email: '' });
			setRole({ role: '' })
			setSendButtonDisabled(false);
		}
		else {
			const requestBody = {
				email: referred.email,
				role: role,
			}
			axios.post(`${props.api_url}/projects/${props.projectId}/epics/${props.epicId}/refer`, requestBody)
				.then((response) => {
					if (response.data.Exists) {
						// TODO - add directly to mentor/contributor list
						// TODO - add user name in snackbar?
						props.enqueueSnackbar(
							"User already exists, can be added directly as a " + role,
							{ variant: "info" }
						);
					}
					else {
						props.enqueueSnackbar("Sending Invitation", { variant: "success" });
					}
					setReferred({ email: '' });
					setRole({ role: '' })
					setSendButtonDisabled(false);
					setShouldRefreshInvitees(true);
				})
				.catch(error => {
					if (error.response != null && error.response.status === 403) {
						removeAllSavedCookies();
						props.enqueueSnackbar("Session timeout: Please login again", { variant: "info" });
						props.history.push('/login');
					}
					if (error.response != null && error.response.data != null & error.response.data.error != null) {
						props.enqueueSnackbar(error.response.data.error, { variant: "error" });
					} else {
						props.enqueueSnackbar("Could not send invitation", { variant: "error" });
					}
					setSendButtonDisabled(false);
				});
		}
	}

	const getInvitationStatusIcon = (status) => {
		switch (status) {
			case 'Accepted':
				return (<Done className={clsx(classes.statusIcon, classes.accepted)} />);
			case 'Pending':
				return <HourglassFull className={clsx(classes.statusIcon, classes.pending)} />;
			case 'Failed':
				return <Error className={clsx(classes.statusIcon, classes.error)} />;
			default:
				return <CircularProgress className={classes.statusIcon} size={20} />;
		}
	}

	const deleteInvitationChip = (referralToDelete) => () => {
		setDeleteStatus(true)
		axios.delete(`${props.api_url}/projects/${props.projectId}/epics/${props.epicId}/referrals/${referralToDelete.ReferralId}`)
			.then(() => {
				setReferrals((referrals) => referrals.filter(
					(referral) => referral.Email !== referralToDelete.Email)
				)
			})
			.catch(error => {
				if (error.response != null && error.response.status === 403) {
					removeAllSavedCookies();
					props.enqueueSnackbar("Session timeout: Please login again", { variant: "info" });
					props.history.push('/login');
				}
				if (error.response != null && error.response.data != null & error.response.data.error != null) {
					setDeleteStatus(false)
					props.enqueueSnackbar(error.response.data.error, { variant: "error" });
				} else {
					setDeleteStatus(false)
					props.enqueueSnackbar("Could not delete invitation", { variant: "error" });
				}
			});

	};

	if (uiLoading) {
		return (
			<div className={classes.loadingRoot}>
				{uiLoading && <CircularProgress />}
			</div>
		)
	} else {
		return (
			<>
				<Grid container className={classes.sectionHeading} alignItems="center">
					<Grid item xs={10}>
						<b><Typography variant="h6">Invitees</Typography></b>
					</Grid>
					<Grid item xs={2}>
						<IconButton
							aria-label="refresh invitees' list"
							onClick={() => {
								setShouldRefreshInvitees(true);
								setUiLoading(true);
							}}
							disabled={uiLoading || shouldRefreshInvitees}
							analyticsObj={analyticsObj}
						>
							<Refresh />
						</IconButton>
					</Grid>
				</Grid>

				<List dense>
					{referrals.map((referral, index) => {
						return (
							<ListItem key={index} className={classes.listItem}>
								<Tooltip title={referral.Status} arrow>
									<Chip
										icon={getInvitationStatusIcon(referral.Status)}
										label={referral.Email}
										clickable={true}
										variant="outlined"
										onDelete={deleteInvitationChip(referral)}
										deleteIcon={deleteStatus ? <CircularProgress size='rem' /> : <HighlightOff />}
									/>
								</Tooltip>
							</ListItem>
						);
					})}
				</List>
				<Button
					variant="outlined"
					color="primary"
					fullWidth
					onClick={handleInviteClick}
					analyticsObj={analyticsObj}
				>
					Invite
				</Button>
				<Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
					<form noValidate className={classes.form}>
						<Grid container>
							<Grid item xs={12} md={6}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoFocus
									value={referred.email}
									onChange={(e) => setReferred({ email: e.target.value })}
								/>
							</Grid>
							<Grid item xs={6} md={4} className={classes.dropdown}>
								<FormControl fullWidth variant='outlined'>
									<InputLabel required htmlFor='role-label' variant='outlined'>Role</InputLabel>
									<Select
										fullWidth
										required
										id="role-dropdown"
										value={role}
										label="Role"
										onChange={(r) => setRole(r.target.value)}
										inputProps={{
											name: 'role',
											id: 'role-label'
										}}
									>
										<MenuItem aria-label='None' value=""></MenuItem>
										<MenuItem value={'Mentee'}>Contributor</MenuItem>
										<MenuItem value={'Mentor'}>Mentor</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6} md={2} className={classes.sendButtonGrid}>
								<Button
									fullWidth
									variant="contained"
									color="primary"
									onClick={refer}
									analyticsObj={analyticsObj}
									className={classes.inviteSend}
									disabled={sendButtonDisabled || referred.email === ''}
								>
									Send
								</Button>
							</Grid>
						</Grid>
						<FormHelperText >
							Email linked with github account is recommended
						</FormHelperText>
					</form>
				</Dialog>
			</>
		);
	}
}

export default withStyles(styles)(withSnackbar(InviteUserView));