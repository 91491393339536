import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip } from "@material-ui/core";

function ImageBlackWithCredits(props) {
	const { icon, height, altText, authorName, authorLink, sourceName, sourceLink, ...other } = props;
	const useStyles = makeStyles(theme => ({
		image: {
			height: height,
			filter: (theme.palette.type === "dark") ? "invert(66%) sepia(38%) saturate(5950%) hue-rotate(188deg) brightness(108%) contrast(98%)" : "",
		},
		tooltip: {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.primary,
			fontSize: theme.typography.fontSize,
			fontWeight: theme.typography.fontWeightLight,
		},
	}))
	const classes = useStyles(props);
	return (
		<Tooltip
			interactive
			enterDelay={2000}
			enterNextDelay={2000}
			enterTouchDelay={3000}
			classes={{ tooltip: classes.tooltip }}
			title={<>Icon made by <a href={authorLink} title={authorName}>{authorName}</a> from <a href={sourceLink} title={sourceName}> {sourceName}</a></>}
			{...other}
		>
			<img
				className={classes.image}
				src={icon}
				alt={altText}
			/>
		</Tooltip>
	)
}

ImageBlackWithCredits.propTypes = {
	icon: PropTypes.string.isRequired,
	height: PropTypes.number,
	altText: PropTypes.string,
	authorName: PropTypes.string,
	authorLink: PropTypes.string,
	sourceName: PropTypes.string,
	sourceLink: PropTypes.string,
};

export default ImageBlackWithCredits;