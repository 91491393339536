import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Button from './Button';

const styles = (theme) => ({
	errorMessage: {
		marginTop: theme.spacing(4),
	}
})

class AlertDialog extends React.Component {
	constructor(props) {
		super(props);

		// props required: pageName, open, handleClose, handleConfirm, dialogTitle, dialogContent, cancelText, confirmText

		this.analyticsObj = {
			source: "AlertDialog",
			page: this.props.pageName,
		};
	}

	render() {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.props.open}
				onClose={this.props.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{this.props.dialogTitle}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{this.props.dialogContent}
					</DialogContentText>
					{(this.props.errorMessage !== undefined) ?
						<Alert severity="error" className={classes.errorMessage}>{this.props.errorMessage}</Alert> : null
					}
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.handleClose} color="secondary" analyticsObj={this.analyticsObj} disabled={this.props.loading}>
						{this.props.cancelText}
					</Button>
					<Button autoFocus onClick={this.props.handleConfirm} color="secondary" analyticsObj={this.analyticsObj} variant="contained" disabled={this.props.loading}>
						{this.props.confirmText}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default withStyles(styles)(AlertDialog);