import React from 'react';
import { Document, Page, StyleSheet } from "@react-pdf/renderer";
import ReceiptNumber from './ReceiptNumber';
import ReceiptBilledTo from './ReceiptBilledTo';
import ReceiptItemsTable from './ReceiptItemsTable';

const styles = StyleSheet.create({
	section: {
		flexGrow: 1
	},
	page: {
		fontFamily: 'Helvetica',
		fontSize: 11,
		paddingTop: 30,
		paddingLeft: 60,
		paddingRight: 60,
		lineHeight: 1.5,
		flexDirection: 'column',
	},
	logo: {
		// margin: '10px',
		// height: 'px',
		// width: '128px',
		width: 148,
		height: 132,
		marginLeft: 'auto',
		marginRight: 'auto'
	},
});

const Receipt = (props) => (
	// TODO: Include company logo in the receipt
	<Document>
		<Page size="A4" style={styles.page}>
			{/* <Image style={styles.logo} src={testLogo} /> */}
			<ReceiptNumber time={props.transaction.txTime} referenceId={props.transaction.referenceId ? props.transaction.referenceId :  props.transaction.transferId} />
			<ReceiptBilledTo userDetails={props.userDetails} />
			<ReceiptItemsTable epicName={props.epicName} amount={props.transaction.orderAmount} currency={props.transaction.orderCurrency} />
		</Page>
	</Document>
);


export default Receipt;