import React, { Component } from 'react';
import withRoot from '../mui_modules/withRoot';

import AppAppBar from '../mui_modules/views/AppAppBar'
import ProductHero from '../mui_modules/views/ProductHero'
import AppFooter from '../mui_modules/views/AppFooter'
import ProductValues from '../mui_modules/views/ProductValues'
import ProductHowItWorks from '../mui_modules/views/ProductHowItWorks'
import ProductCTA from '../mui_modules/views/ProductCTA'
import { gAnalytics } from "../util/analytics";
import { tradeMarkedAlokit } from '../util/uiUtil';

class Landing extends Component {
	constructor(props) {
		super(props);
		gAnalytics.logEvent('screen_view', {
			firebase_screen: 'landing',
		});
	}

	render() {
		return (
			<React.Fragment>
				<AppAppBar pageName={"Landing"} />
				<ProductHero pageName={"Landing"} />
				<ProductValues pageName={"Landing"} />
				<ProductHowItWorks titleText="How it works" programButton={true} pageName={"Landing"} />
				<ProductCTA titleText={`Get started with ${tradeMarkedAlokit()}`} pageName={"Landing"} />
				<AppFooter pageName={"Landing"} />
			</React.Fragment>
		)
	}
}

export default withRoot(Landing);
