import React from 'react';
import { withStyles, Chip } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '../../../mui_modules/components/Typography'

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { userChipRender } from '../../../util/uiUtil';

const styles = (theme) => ({
	contents: {
		flexGrow: 1,
		flexShrink: 0,
		padding: theme.spacing(3),
	},
	sectionHeading: {
		marginTop: theme.spacing(2),
	},
	divider: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	tags: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	toolbar: theme.mixins.toolbar,
})

class ProjectProfileDrawer extends React.Component {
	render() {
		const { classes } = this.props;
		const analyticsObj = {
			source: "ProjectProfileDrawer",
			page: this.props.pageName,
		};
		dayjs.extend(relativeTime);
		return (
			<section className={classes.contents} >
				<b><Typography variant="h6" className={classes.sectionHeading}>Description</Typography></b>
				<Typography paragraph variant="body1" align="justify" >
					{this.props.Description}
				</Typography>
				<Divider className={classes.divider} />
				<b><Typography variant="h6" className={classes.sectionHeading}>Owner</Typography></b>
				{ userChipRender(this.props.Owner, analyticsObj)}
				<Divider className={classes.divider} />
				<b><Typography variant="h6" className={classes.sectionHeading}>Created</Typography></b>
				<Typography paragraph variant="body1" align="justify" >
					{dayjs.unix(this.props.CreatedAt._seconds).fromNow()}
				</Typography>

				<b><Typography variant="h6" className={classes.sectionHeading}>Tags</Typography></b>
				<div className={classes.tags} >
					{this.props.Tags.map((tag) => (
						<Chip key={tag} variant="default" color="secondary" label={tag} />
					))}
				</div>
			</section>
		)
	}
}

export default withStyles(styles)(ProjectProfileDrawer);